import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  {
    id: 'home-new',
    title: 'Home',
    translate: 'MENU.HOME',
    type: 'item',
    icon: 'home',
    url: 'home-new'
  },

  { 
    id: 'forms-table',
    type: 'section',
    title: 'Store Section',
    icon: 'file-text',
    children: [
      {
        id: 'form-elements',
        title: 'Store Requests',
        type: 'collapsible',
        icon: 'shopping-bag',
        children: [
          {
            id: 'all-stores',
            title: 'View All Stores',
            type: 'item',
            icon: 'list',
            url: 'view-all-stores'
          },
          {
            id: 'New Stores Requests',
            title: 'New Stores Requests',
            type: 'item',
            icon: 'user-plus',
            url: 'new-stores-request'
          }
        ]
      },
    ]
  },
  { 
    id: 'forms-table',
    type: 'section',
    title: 'User Section',
    icon: 'file-text',
    children: [
      {
        id: 'form-elements',
        title: 'User',
        type: 'collapsible',
        icon: 'user',
        children: [
          {
            id: ' All User',
            title: ' All User',
            type: 'item',
            icon: 'user',
            url: 'view-all-user'
          },
          
        ]
      },
    ]
  },
  { 
    id: 'forms-table',
    type: 'section',
    title: 'Product ',
    icon: 'box',
    children: [
      {
        id: 'form-elements',
        title: 'Product',
        type: 'collapsible',
        icon: 'box',
        children: [
          {
            id: ' All Product',
            title: ' All Products',
            type: 'item',
            icon: 'package',
            url: 'view-all-products'
          },
        ]
      },
    ]
  },
  { 
    id: 'forms-table',
    type: 'section',
    title: 'Orders ',
    icon: 'shopping-cart',
    children: [
      {
        id: 'form-elements',
        title: 'Orders',
        type: 'collapsible',
        icon: 'shopping-cart',
        children: [
          {
            id: ' All Orders',
            title: ' All Orders',
            type: 'item',
            icon: 'package',
            url: 'view-all-orders'
          },
        ]
      },
    ]
  },
  { 
    id: 'forms-table',
    type: 'section',
    title: 'category & subcategory ',
    icon: 'file-text',
    children: [
      {
        id: 'form-elements',
        title: 'All',
        type: 'collapsible',
        icon: 'airplay',
        children: [
          {
            id: 'CATEGORY',
            title: 'CATEGORY',
            type: 'item',
            icon: 'columns',
            url: 'view-all-category'
          },
          {
            id: ' SUBCATEGORY',
            title: 'SUBCATEGORY',
            type: 'item',
            icon: 'columns',
            url: 'view-all-subcategory'
          },
          {
            id: ' TAGS',
            title: 'TAGS',
            type: 'item',
            icon: 'tag',
            url: 'view-all-tags'
          },
          {
            id: ' Feature',
            title: 'Feature',
            type: 'item',
            icon: 'feather',
            url: 'view-all-feature'
          },
        ]
      },
    ]
  },
  { 
    id: 'forms-table',
    type: 'section',
    title: 'Problems ',
    icon: 'shopping-cart',
    children: [
      {
        id: 'form-elements',
        title: 'Problem',
        type: 'collapsible',
        icon: 'shopping-cart',
        children: [
          {
            id: ' All Problems',
            title: ' All Problems',
            type: 'item',
            icon: 'package',
            url: 'view-all-problems'
          },
        ]
      },
    ]
  },

]
