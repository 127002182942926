import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { ShipmentservService } from "../shipmentserv.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-shipment-details",
  templateUrl: "./shipment-details.component.html",
  styleUrls: ["./shipment-details.component.scss"],
})
export class ShipmentDetailsComponent implements OnInit {
  public shipmentData: any;
  public shipmentDetails: any;
  public product_shipment_id: any;

  constructor(
    private _shipmentService: ShipmentservService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.product_shipment_id = this.route.snapshot.paramMap.get("id")!;
    // console.log(this.product_shipment_id);

    this.getShipmentDetails();
  }

  getShipmentDetails() {
    this._shipmentService.ShipmentDetails(this.product_shipment_id).subscribe(
      (response) => {
        this.shipmentData = response;
        this.shipmentDetails = this.shipmentData.data;
        console.log("Shipment Details:", this.shipmentDetails);
      },
      (error) => {
        console.error("Error fetching shipment details:", error);
      }
    );
  }

  // status methods
  // On The Way
  onTheWay(status_id: number): void {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to update the order status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(this.product_shipment_id);
        console.log(status_id);

        this._shipmentService
          .ChangeStatus(this.product_shipment_id, status_id)
          .subscribe({
            next: (response) => {
              console.log("Status updated successfully:", response);
              Swal.fire({
                icon: "success",
                title: "Status Updated",
                text: "The order status has been updated successfully!",
                confirmButtonText: "OK",
              });
            },
            error: (error) => {
              console.error("Error updating status:", error);
              Swal.fire({
                icon: "error",
                title: "Update Failed",
                text: "An error occurred while updating the order status. Please try again.",
                confirmButtonText: "OK",
              });
            },
          });
      }
    });
  }

  Delivered(status_id: number): void {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to mark this order as Delivered?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, mark as Delivered!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(this.product_shipment_id);
        console.log(status_id);

        this._shipmentService
          .ChangeStatus(status_id, this.product_shipment_id)
          .subscribe({
            next: (response) => {
              console.log("Status updated successfully:", response);
              Swal.fire({
                icon: "success",
                title: "Status Updated",
                text: "The order has been marked as Delivered!",
                confirmButtonText: "OK",
              });
            },
            error: (error) => {
              console.error("Error updating status:", error);
              Swal.fire({
                icon: "error",
                title: "Update Failed",
                text: "An error occurred while updating the order status. Please try again.",
                confirmButtonText: "OK",
              });
            },
          });
      }
    });
  }

  // cancelOrder
  cancelOrder(status_id: number): void {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to cancel this order?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, cancel it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(status_id);

        this._shipmentService
          .ChangeStatus(this.product_shipment_id, status_id)
          .subscribe({
            next: (response) => {
              console.log("Order cancelled successfully:", response);
              Swal.fire({
                icon: "success",
                title: "Order Cancelled",
                text: "The order has been cancelled successfully!",
                confirmButtonText: "OK",
              });
            },
            error: (error) => {
              console.error("Error cancelling order:", error);
              Swal.fire({
                icon: "error",
                title: "Cancellation Failed",
                text: "An error occurred while cancelling the order. Please try again.",
                confirmButtonText: "OK",
              });
            },
          });
      }
    });
  }
}
