import { Product } from './../../../main/sample/modules/store-profile';
import { ProductsService } from "./../products.service";
import Swal from "sweetalert2";
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: "app-product-list",
  templateUrl: "./product-list.component.html",
  styleUrls: ["./product-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ecommerce-application' }
})
export class ProductListComponent implements OnInit {
  public contentHeader: object;
  public products: Product;
  public shopSidebarToggle = false;
  public shopSidebarReset = false;
  public gridViewRef = true;
  public wishlist;
  public cartList;
  public page = 1;
  public pageSize = 9;
  public searchText = '';
  public loader:boolean = true;




  constructor(private _productServices: ProductsService) {}

  ngOnInit(): void {

    this.getAllProduct();


  }


  /**
   * Update to List View
   */
  listView() {
    this.gridViewRef = false;
  }

  /**
   * Update to Grid View
   */
  gridView() {
    this.gridViewRef = true;
  }


///////////  Upload Excl//////////////////////
onFileSelected(event: any) {
  const file: File = event.target.files[0];
  if (file) {
    console.log('Selected file:', file);
    this._productServices.uploadFile(file).subscribe(
      (response) => {
        if (response) {
          console.log('File successfully uploaded!', response);
          Swal.fire('Success', 'File successfully uploaded!', 'success');
        }
      },
      (error)=>{
        console.log("File doesnot upload!" , error)  ;
        Swal.fire('success', 'File doesnot upload!', 'success');
      }
    );
  }
}


  /**
   * Sort Product
   */



  getAllProduct(){
    this._productServices.GetAllProducts().subscribe(
      (res: any) => {
        this.products = res;
        this.loader=false;
      },
      (er: any) => {
        Swal.fire({
          position: "center",
          icon: "info",
          title: "There Are No Products Added Yet  ",
          showConfirmButton: true,
        });
        console.log(er);
      }
    );
  }
}
