import { Ng2FlatpickrModule } from "ng2-flatpickr";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HomeCategoriesService } from "./home-categories.service";
import { CategoryProductListComponent } from "./Category-Product-list/category-product-list.component";
import { SubCategoryProductListComponent } from "./subCategory-Product-list/sub-category-product-list.component";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "app/auth/helpers";
import {
  SWIPER_CONFIG,
  SwiperConfigInterface,
  SwiperModule,
} from "ngx-swiper-wrapper";
import { CoreCommonModule } from "@core/common.module";
import { FormsModule } from "@angular/forms";
import { AvatarModule } from "ngx-avatar";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { CorePipesModule } from "@core/pipes/pipes.module";
import { CoreDirectivesModule } from "@core/directives/directives";
import { CoreSidebarModule } from "@core/components";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { HomeBannersComponent } from './Home Banners/home-banners.component';
 



const routes: Routes = [
  {
    path: "all-categories",
    component: CategoryProductListComponent,
    canActivate: [AuthGuard],
    resolve: {
      uls: HomeCategoriesService,
    },
  },
  {
    path: "all-subCategories",
    component: SubCategoryProductListComponent,
    canActivate: [AuthGuard],
    resolve: {
      uls: HomeCategoriesService,
    },
  },
  {
    path: "all-banners",
    component: HomeBannersComponent,
    canActivate: [AuthGuard],
    resolve: {
      uls: HomeCategoriesService,
    },
  },
  
];

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: "horizontal",
  slidesPerView: "auto",
};

@NgModule({
  declarations: [
    CategoryProductListComponent,
    SubCategoryProductListComponent,
    HomeBannersComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreCommonModule,
    SwiperModule,
    FormsModule,
    NgbModule,
    AvatarModule,
    NgSelectModule,
    Ng2FlatpickrModule,
    NgxDatatableModule,
    CorePipesModule,
    CoreDirectivesModule,
    CoreSidebarModule,
    SweetAlert2Module.forRoot(),
  ],
  providers: [
    HomeCategoriesService,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
  ],
})
export class HomeCategoriesModule {}
