<!-- Ecommerce Item -->
<div class="card card-published product-card shadow border-0 rounded rounded-5">
  <!-- Product Image Container with Fixed Dimensions -->
  <div class="product-image-container">
    <a [routerLink]="'/product-details/' + product?.product_id">
      <img
        class="product-image"
        [src]="product?.product_image || 'assets/placeholder.jpg'"
        alt="{{ product?.product_name || 'Product Image' }}"
      />
    </a>
  </div>

  <!-- Card Body -->
  <div class="card-body text-center">
    <!-- Product Price -->
    <div class="item-cost mb-2">
      <h6 class="item-price text-success font-weight-bold">
        YER {{ product?.product_price }}
        <span class="badge badge-warning ml-2" *ngIf="product?.has_discount">Discount</span>
      </h6>
    </div>

    <!-- Product Name -->
    <h5 class="item-name font-weight-bold text-dark">
      <a
        [routerLink]="'/product-details/' + product?.product_id"
        class="text-decoration-none"
      >
        {{ product?.product_name || "No Name" }}
      </a>
    </h5>

    <!-- Category -->
    <p class="card-text text-muted small">
      <strong>By:</strong>
      <a href="javascript:void(0)" class="text-primary">
        {{ product?.category_name || "Unknown Category" }}
      </a>
    </p>

    <!-- Description -->
    <p class="card-text item-description text-secondary small">
      {{ product?.overview || "No description available." }}
    </p>

    <!-- Ratings -->
    <div class="item-rating d-flex justify-content-center mb-2">
      <ngb-rating
        [rate]="product?.rate"
        [readonly]="true"
        [max]="5"
        class="outline-none"
      >
        <ng-template let-fill="fill">
          <span
            class="fa fa-star mx-1"
            [class.text-warning]="fill === 100"
            [class.text-muted]="fill !== 100"
          ></span>
        </ng-template>
      </ngb-rating>
    </div>
  </div>

  <!-- Buttons -->
  <div class="card-btn border-0 text-center d-flex justify-content-around py-3">
    <button
      class="btn btn-danger btn-sm d-flex align-items-center px-3"
      (click)="removeProduct(product?.product_id, product?.product_name)"
    >
      <i class="fa fa-trash text-light mr-2"></i> Remove
    </button>

    <a
      class="btn btn-primary btn-sm d-flex align-items-center px-3"
      [routerLink]="'/product-details/' + product?.product_id"
    >
      <i class="fa fa-eye mr-2"></i> View
    </a>

    <button
      class="btn btn-success btn-sm d-flex align-items-center px-3"
      (click)="modelAddTo(addToHome, product?.product_id)"
    >
      <i class="fa fa-info-circle mr-2"></i> Action
    </button>
  </div>
</div>

<!--/ Ecommerce Item -->

<ng-template #addToHome let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalCenterTitle">Select Page</h5>
    <button
      id="clo"
      type="button"
      class="close"
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" tabindex="0" ngbAutofocus>
    <form id="fo" [formGroup]="AddToHomeForm" (ngSubmit)="addToPage()">
      <form [formGroup]="AddToHomeForm" (ngSubmit)="addToPage()">
        <div class="form-group">
          <label>Select Page: </label>
          <select
            aria-label="Select Page"
            formControlName="selectedPage"
            [ngClass]="{
              'is-invalid':
                AddToHomeFormSubmitted && AddToHomeForm.selectedPage.errors
            }"
            class="form-control"
          >
            <option value="" disabled selected>Select Page</option>
            <option value="new_arrivals">New Arrival</option>
            <option value="top_products">Top Product</option>
            <option value="discount_products">Discount</option>
          </select>
        </div>

        <div class="form-group">
          <label>Enter Index: </label>
          <input
            type="number"
            formControlName="index"
            class="form-control"
            [ngClass]="{
              'is-invalid':
                AddToHomeFormSubmitted && AddToHomeForm.get('index').errors
            }"
            placeholder="Enter Index"
          />
        </div>
      </form>
    </form>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-success round ml-1"
      (click)="addToPage($event.target.value)"
      rippleEffect
    >
      Add
    </button>
    <button
      type="button"
      class="btn btn-outline-danger round ml-1"
      (click)="modal.close('Accept click')"
      rippleEffect
    >
      Close
    </button>
  </div>
</ng-template>
