<div class="content-wrapper container-xxl p-0 mt-5">
  <div class="content-body">
    <!-- app e-commerce details start -->
    <section class="app-ecommerce-details">
      <div class="card">
        <!-- Product Details starts -->
        <div class="card-body">
          <div class="row my-2">
            <div
              class="col-12 col-md-5 d-flex align-items-center justify-content-center mb-2 mb-md-0"
            >
              <div class="d-flex align-items-center justify-content-center">
                <div class="card-body">
                  <swiper
                    class="swiper-coverflow swiper-container"
                    [config]="swiperCoverflow"
                  >
                    <ng-container *ngFor="let item of product.images">
                      <img
                        class="img-fluid"
                        src="{{ item }}"
                        alt="banner"
                      />
                    </ng-container>
                  </swiper>
                </div>

                <!-- <img [src]="product.image" class="img-fluid product-img" alt="product image" /> -->
              </div>
            </div>
            <div class="col-12 col-md-7">
              <h4>{{ product.product_name }}</h4>

              <span class="card-text item-company"
                >Category
                <a href="javascript:void(0)" class="company-name">{{
                  product.category.name
                }}</a></span
              >

              <div>
                <span class="card-text item-company mt-1"
                  >Tags
                  <ng-container *ngFor="let item of product.tags">
                    <a href="javascript:void(0)" class="text-info mx-2">{{
                      item.name
                    }}</a>
                  </ng-container>
                </span>
              </div>
              <div class="ecommerce-details-price d-flex flex-wrap mt-1">
                <h4 class="item-price mr-1">EG {{ product.product_price }}</h4>
                <div class="rating rating-sm pl-1 border-left mb-1">
                  <ngb-rating
                    [rate]="product.rate"
                    [readonly]="true"
                    [max]="5"
                    class="outline-none"
                  >
                    <ng-template let-fill="fill" let-index="index"
                      ><span
                        class="fa fa-star-o mr-25"
                        [class.fa-star]="fill === 100"
                      ></span
                    ></ng-template>
                  </ngb-rating>
                </div>
              </div>
              <p *ngIf="product.product_quantity >= 1" class="card-text">
                Available - <span class="text-success">In stock</span>
              </p>
              <p *ngIf="product.product_quantity < 1" class="card-text">
                Not Available - <span class="text-warning">out of stock</span>
              </p>
              <p class="card-text">
                Quantity :
                <span class="text-info">
                  {{ product.product_quantity }}</span
                >
              </p>
              <p class="card-text">
               Total Reviews :
                <span class="text-info">
                  {{ product.total_reviews }}</span
                >
              </p>

              <p class="card-text">
                {{ product.overview }}
              </p>
              <ul class="product-features list-unstyled">
                <li *ngIf="product.has_discount">
                  <span class="text-success">discount</span>
                </li>
                <li *ngIf="product.has_discount">
                  <i data-feather="smile"></i>
                  <span
                    >Price After Discount : EG
                    <span class="text-success">
                      {{ product.discounted_price }}</span
                    ></span
                  >
                </li>
              </ul>
              <hr />




              <!-- <div class="product-color-options">
                <h6>Colors</h6>
                <ul class="list-unstyled mb-0">
                  <li class="d-inline-block selected">
                    <div class="color-option b-primary">
                      <div class="filloption bg-primary"></div>
                    </div>
                  </li>
                  <li class="d-inline-block">
                    <div class="color-option b-success">
                      <div class="filloption bg-success"></div>
                    </div>
                  </li>
                  <li class="d-inline-block">
                    <div class="color-option b-danger">
                      <div class="filloption bg-danger"></div>
                    </div>
                  </li>
                  <li class="d-inline-block">
                    <div class="color-option b-warning">
                      <div class="filloption bg-warning"></div>
                    </div>
                  </li>
                  <li class="d-inline-block">
                    <div class="color-option b-info">
                      <div class="filloption bg-info"></div>
                    </div>
                  </li>
                </ul>
              </div> -->
              <ng-container *ngFor="let feature of product.features">

                   <div class="product-color-options">
                     <h6>{{ feature.name }}</h6>
                    
                     <ul class="list-unstyled mb-0">
                     <ng-container *ngFor="let value of feature.values">
                           <li class="d-inline-block selected mx-2 ">
                             <div 
                             [style.background-color]="value.value"
                              class="color-option b-primary px-2">
                               <div class=""></div>{{ value.value }}
                             </div>
                           </li>
                         </ng-container>
                    </ul>


                   </div>
              </ng-container>



              <hr />
              <div class="d-flex flex-column flex-sm-row pt-1">
                <a class="btn btn-outline-danger round" rippleEffect>
                  <span class="">Delete Product</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- Product Details ends -->

        <!-- Item features starts -->
        <div class="item-features">
          <div class="row text-center">
            <div class="col-12 col-md-4 mb-4 mb-md-0">
              <div class="w-75 mx-auto">
                <i data-feather="award"></i>
                <h4 class="mt-2 mb-1">100% Original</h4>
                <p class="card-text">
                  Chocolate bar candy canes ice cream toffee. Croissant pie
                  cookie halvah.
                </p>
              </div>
            </div>
            <div class="col-12 col-md-4 mb-4 mb-md-0">
              <div class="w-75 mx-auto">
                <i data-feather="clock"></i>
                <h4 class="mt-2 mb-1">10 Day Replacement</h4>
                <p class="card-text">
                  Marshmallow biscuit donut dragée fruitcake. Jujubes wafer
                  cupcake.
                </p>
              </div>
            </div>
            <div class="col-12 col-md-4 mb-4 mb-md-0">
              <div class="w-75 mx-auto">
                <i data-feather="shield"></i>
                <h4 class="mt-2 mb-1">1 Year Warranty</h4>
                <p class="card-text">
                  Cotton candy gingerbread cake I love sugar plum I love sweet
                  croissant.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- Item features ends -->

        <!-- Related Products starts -->

        <!-- Related Products ends -->
      </div>
    </section>
    <!-- app e-commerce details end -->
  </div>
</div>
