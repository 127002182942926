import { Component, OnInit } from "@angular/core";
import { ProductsService } from "../products.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";

@Component({
  selector: "app-temp-details",
  templateUrl: "./temp-details.component.html",
  styleUrls: ["./temp-details.component.scss"],
})
export class TempDetailsComponent implements OnInit {
  public tempId: any;
  loader: boolean;
  searchText = "";
  tempDetails: any;

  constructor(
    private _productServices: ProductsService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private FormBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.tempId = this.route.snapshot.paramMap.get("id");
    this.getTempDetails(this.tempId);
  }

  getTempDetails(id: number) {
    this._productServices.getTempDetailsById(id).subscribe(
      (res: any) => {
        console.log(res);
        this.tempDetails = res;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  acceptAction(productId: number): void {
    console.log(productId);

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to accept this product?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Accept!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        // Call the API if the user confirms
        this._productServices.acceptTempProduct(productId).subscribe({
          next: (response) => {
            Swal.fire("Accepted!", "The product has been accepted.", "success");
            console.log("Product accepted successfully:", response);
            // Reload all temporary products
            this.getTempDetails(productId)
          },
          error: (error) => {
            Swal.fire(
              "Error",
              "There was an issue accepting the product.",
              "error"
            );
            console.error("Error accepting product:", error);
          },
        });
      } else {
        Swal.fire("Cancelled", "The product was not accepted.", "info");
      }
    });
  }

  RejectAction(id: number, name: string) {
    console.log('RejectAction called with name:', name);
    console.log('RejectAction called with id:', id);
  
    Swal.fire({
      title: `Are you sure you want to cancel this Temp Product: ${name}?`,
      html: `
        <textarea id="cancelReason" class="swal2-textarea" placeholder="Enter the reason for cancellation" style="width: 100%;"></textarea>
      `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#117864",
      confirmButtonText: "Yes, Cancel this product",
      cancelButtonText: "No, Keep this product",
      preConfirm: () => {
        const reason = (document.getElementById("cancelReason") as HTMLTextAreaElement)?.value;
        console.log('Reason entered:', reason);
        if (!reason) {
          Swal.showValidationMessage("Please enter a reason for cancellation.");
          return null;
        }
        return reason;
      },
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        console.log('Reason submitted:', result.value);
        
        this._productServices.rejectedTempProduct(id, name).subscribe(
          (response: any) => {
            Swal.fire({
              icon: "success",
              title: "Cancelled!",
              text: "Product has been cancelled successfully.",
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              this.router.navigate(['/view-temp-products']);  // Navigate after success
            });
          },
          (error: any) => {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "An error occurred while cancelling this product.",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        );
      }
    });
  }
}
