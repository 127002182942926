<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none" >
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img
            src="{{ coreConfig.app.appLogoImage }}"
            alt="brand-logo"
            width="36"
          />
        </span>
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content p-0">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->

    <!-- Toggle skin -->
    <li class=" nav-item d-none d-lg-block">
      <a
        type="button"
        class="nav-link nav-link-style btn"
        (click)="toggleDarkSkin()"
      >
        <span
          [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'"
          class="ficon font-medium-5 feather"
        ></span>
      </a>
      <!-- <p class="Boutique">Boutique</p> -->
    </li>
    <!--/ Toggle skin -->
  </div>

  <ul class="hmada nav navbar-nav align-items-center ml-auto">
    <!-- ? Language selection | Uncomment if needed-->
    <!-- <li ngbDropdown class="nav-item dropdown dropdown-language">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i
        ><span class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span></a
      >
      <div ngbDropdownMenu aria-labelledby="dropdown-flag">
        <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
          <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i> {{ languageOptions[lang].title }}
        </a>
      </div>
    </li> -->
    <!--/ Language selection -->

    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a
        class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div class="user-nav d-sm-flex d-none">
          <span class="user-name font-weight-bolder"
            >{{ currentUser.firstName }} {{ currentUser.lastName }}</span
          ><span class="user-status">{{ currentUser.role }}</span>
        </div>
        <span class="">
          <ngx-avatar
            name="{{ currentUser.firstName }} {{ currentUser.lastName }}"
            value="20%"
            size="50"
            [round]="true"
          >
          </ngx-avatar>

          <span class="avatar-status-online"></span
        ></span>
      </a>

      <!-- Super Admin  -- At Right  -->
      <div
        ngbDropdownMenu
        aria-labelledby="navbarUserDropdown"
        class="dropdown-menu dropdown-menu-right"
      >
        <a ngbDropdownItem [routerLink]="['/']">
          <span [data-feather]="'user'" [class]="'mr-50'"></span> Profile
        </a>

        <a ngbDropdownItem [routerLink]="['/']"
          ><span [data-feather]="'mail'" [class]="'mr-50'"></span> Inbox</a
        >
        <a ngbDropdownItem [routerLink]="['/']"
          ><span [data-feather]="'check-square'" [class]="'mr-50'"></span>
          Task</a
        >
        <a ngbDropdownItem [routerLink]="['/']"
          ><span [data-feather]="'message-square'" [class]="'mr-50'"></span>
          Chats</a
        >
        <a
          *ngIf="currentUser?.role == 'Super Admin'"
          (click)="ModelAddRole(modelCreateRole)"
          ngbDropdownItem
        >
          <span [data-feather]="'plus-circle'" [class]="'mr-50'"></span> Create
          Account
        </a>

        <div class="dropdown-divider"></div>
        <a (click)="logout()" ngbDropdownItem
          ><span [data-feather]="'power'" [class]="'mr-50'"></span> Logout</a
        >
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>

<!-- Model Create Account  -->
<ng-template #modelCreateRole let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalCenterTitle">New Account</h5>
    <button
      id="clo"
      type="button"
      class="close"
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <form
      *ngIf="createRoleForm"
      [formGroup]="createRoleForm"
      novalidate
      autocomplete="off"
      id="fo"
    >
      <div class="row">
        <!-- First Name  -->
        <div class="form-group col-md-6">
          <label>First Name:</label>
          <input
            formControlName="firstName"
            type="text"
            placeholder="First Name"
            class="form-control"
            [ngClass]="{
              'is-invalid':
                createRoleFormSubmitted &&
                createRoleForm.get('firstName')?.errors
            }"
          />
          <div
            *ngIf="
              createRoleForm.get('firstName')?.invalid &&
              createRoleFormSubmitted
            "
            class="invalid-feedback"
          >
            <div *ngIf="createRoleForm.get('firstName')?.errors?.required">
              First Name is required.
            </div>
            <div *ngIf="createRoleForm.get('firstName')?.errors?.minlength">
              First Name must be at least 2 characters.
            </div>
            <div *ngIf="createRoleForm.get('firstName')?.errors?.maxlength">
              First Name must be at most 50 characters.
            </div>
          </div>
        </div>

        <!-- Last Name  -->
        <div class="form-group col-md-6">
          <label>Last Name:</label>
          <input
            formControlName="lastName"
            type="text"
            placeholder="Last Name"
            class="form-control"
            [ngClass]="{
              'is-invalid':
                createRoleFormSubmitted &&
                createRoleForm.get('lastName')?.errors
            }"
          />
          <div
            *ngIf="
              createRoleForm.get('lastName')?.invalid && createRoleFormSubmitted
            "
            class="invalid-feedback"
          >
            <div *ngIf="createRoleForm.get('lastName')?.errors?.required">
              Last Name is required.
            </div>
            <div *ngIf="createRoleForm.get('lastName')?.errors?.minlength">
              Last Name must be at least 2 characters.
            </div>
            <div *ngIf="createRoleForm.get('lastName')?.errors?.maxlength">
              Last Name must be at most 50 characters.
            </div>
          </div>
        </div>

        <!-- Email -->
        <div class="form-group col-md-6">
          <label>Email:</label>
          <input
            formControlName="email"
            type="email"
            placeholder="Email"
            class="form-control"
            [ngClass]="{
              'is-invalid':
                createRoleFormSubmitted && createRoleForm.get('email')?.errors
            }"
          />
          <div
            *ngIf="
              createRoleForm.get('email')?.invalid && createRoleFormSubmitted
            "
            class="invalid-feedback"
          >
            <div *ngIf="createRoleForm.get('email')?.errors?.required">
              Email is required.
            </div>
            <div *ngIf="createRoleForm.get('email')?.errors?.email">
              Please enter a valid email address.
            </div>
          </div>
        </div>

        <!-- Password -->
        <!-- Password -->
        <div class="form-group col-md-6">
          <label>Password:</label>
          <div class="input-group">
            <input
              formControlName="password"
              [type]="showPassword ? 'text' : 'password'"
              placeholder="Password"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  createRoleFormSubmitted &&
                  createRoleForm.get('password')?.errors
              }"
            />
            <div class="input-group-append">
              <button
                type="button"
                class="btn btn-outline-secondary"
                (click)="togglePasswordVisibility()"
              >
                <i
                  [ngClass]="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"
                ></i>
              </button>
            </div>
          </div>

          <div
            *ngIf="
              createRoleForm.get('password')?.invalid && createRoleFormSubmitted
            "
            class="invalid-feedback"
          >
            <div *ngIf="createRoleForm.get('password')?.errors?.required">
              Password is required.
            </div>
            <div *ngIf="createRoleForm.get('password')?.errors?.minlength">
              Password must be at least 8 characters long.
            </div>
            <div *ngIf="createRoleForm.get('password')?.errors?.pattern">
              Password must contain at least one uppercase letter, one lowercase
              letter, one number, and one special character.
            </div>
          </div>
        </div>

        <!-- Phone -->
        <div class="form-group col-md-6">
          <label>Phone:</label>
          <input
            formControlName="phone"
            type="tel"
            placeholder="Phone Number"
            class="form-control"
            [ngClass]="{
              'is-invalid':
                createRoleFormSubmitted && createRoleForm.get('phone')?.errors
            }"
          />
          <div
            *ngIf="
              createRoleForm.get('phone')?.invalid && createRoleFormSubmitted
            "
            class="invalid-feedback"
          >
            <div *ngIf="createRoleForm.get('phone')?.errors?.required">
              Phone number is required.
            </div>
            <div *ngIf="createRoleForm.get('phone')?.errors?.pattern">
              Please enter a valid phone number.
            </div>
          </div>
        </div>

        <!-- Role -->
        <div class="form-group col-md-6">
          <label>Role:</label>
          <select
            formControlName="role"
            class="form-control"
            [ngClass]="{
              'is-invalid':
                createRoleFormSubmitted && createRoleForm.get('role')?.errors
            }"
          >
            <option value="" disabled selected>Select Role</option>
            <option *ngFor="let role of roles" [value]="role.name">
              {{ role.name }}
            </option>
          </select>
          <div
            *ngIf="
              createRoleForm.get('role')?.invalid && createRoleFormSubmitted
            "
            class="invalid-feedback"
          >
            <div *ngIf="createRoleForm.get('role')?.errors?.required">
              Role is required.
            </div>
          </div>
        </div>

        <!-- Restrictions -->
        <div class="form-group col-md-12">
          <label>Restrictions:</label>
          <div class="checkbox-container">
            <div *ngFor="let restriction of restrictions" class="form-check">
              <input
                type="checkbox"
                [id]="'restriction_' + restriction.name"
                [value]="restriction.name"
                (change)="onRestrictionChange($event)"
                [checked]="isRestrictionSelected(restriction.name)"
                class="form-check-input"
              />
              <label
                [for]="'restriction_' + restriction.name"
                class="form-check-label"
              >
                {{ restriction.name }}
              </label>
            </div>
          </div>
          <div
            *ngIf="
              createRoleForm.get('restriction_name')?.invalid &&
              createRoleFormSubmitted
            "
            class="invalid-feedback d-block"
          >
            <div
              *ngIf="createRoleForm.get('restriction_name')?.errors?.required"
            >
              At least one restriction is required.
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- Submit Button -->
    <div class="form-group">
      <button
        type="submit"
        class="btn btn-primary btn-block"
        (click)="createRoleFormMethod()"
        [disabled]="isLoading || createRoleForm.invalid"
        rippleEffect
      >
        <ng-container *ngIf="!isLoading"> Create Account </ng-container>
        <ng-container *ngIf="isLoading">
          <i class="fa fa-spinner fa-spin"></i>
          Loading...
        </ng-container>
      </button>
    </div>
  </div>
</ng-template>
