import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ShipmentservService } from '../shipmentserv.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-shipment-details',
  templateUrl: './shipment-details.component.html',
  styleUrls: ['./shipment-details.component.scss']
})




export class ShipmentDetailsComponent implements OnInit {
  public shipmentData:any
  public shipmentDetails: any;
  public shipment_id : any;

  constructor(
    private _shipmentService : ShipmentservService,
        private route: ActivatedRoute  
    
  ) {}

  ngOnInit(): void {
    this.shipment_id = this.route.snapshot.paramMap.get('id')!;
    console.log(this.shipment_id);
    
    this.getShipmentDetails();
  }


  getShipmentDetails() {
    console.log("inside");
    
    this._shipmentService.ShipmentDetails(this.shipment_id).subscribe(
      (response) => {
        console.log("done");
        
        this.shipmentData = response;
        console.log('Shipment Data:', this.shipmentData);
         this.shipmentDetails = this.shipmentData.data
         console.log('Shipment Details:',this.shipmentDetails);
         
      },
      (error) => {
        console.log("not Done");
        
        console.error('Error fetching shipment details:', error);
      }
    );
  }


  // status methods 
// On The Way 
onTheWay(status_id: number): void {
  console.log(this.shipment_id);
  console.log(status_id);

  this._shipmentService.ChangeStatus(this.shipment_id , status_id).subscribe({
    next: (response) => {
      console.log('Status updated successfully:', response);
      // Show success notification
      Swal.fire({
        icon: 'success',
        title: 'Status Updated',
        text: 'The order status has been updated successfully!',
        confirmButtonText: 'OK'
      });
    },
    error: (error) => {
      console.error('Error updating status:', error);
      // Show error notification
      Swal.fire({
        icon: 'error',
        title: 'Update Failed',
        text: 'An error occurred while updating the order status. Please try again.',
        confirmButtonText: 'OK'
      });
    }
  });
}


  Delivered(status_id){
    console.log(this.shipment_id);
    console.log(status_id);

  this._shipmentService.ChangeStatus(status_id,this.shipment_id).subscribe({
    next: (response) => {
      console.log('Status updated successfully:', response);
      // Show success notification
      Swal.fire({
        icon: 'success',
        title: 'Status Updated',
        text: 'The order status has been updated successfully!',
        confirmButtonText: 'OK'
      });
    },
    error: (error) => {
      console.error('Error updating status:', error);
      // Show error notification
      Swal.fire({
        icon: 'error',
        title: 'Update Failed',
        text: 'An error occurred while updating the order status. Please try again.',
        confirmButtonText: 'OK'
      });
    }
  });
    
  }

  // cancelOrder
  cancelOrder(status_id) {
    console.log(status_id);
  }


}
