<!-- Ecommerce Item -->
    <div class="item-img text-center">
      <a [routerLink]="'/product-details/' + product.product_id">
        <img class="img-fluid card-img-top " style="width: 100%" [src]="product.images[0]" alt="img-placeholder" />
      </a>
    </div>
   <div class="card-body">
     <div class="item-wrapper">
       <div class="item-rating">
         <div class="rating rating-sm">
           <ngb-rating [rate]="product.rate" [readonly]="true" [max]="5" class="outline-none">
             <ng-template let-fill="fill" let-index="index"
               ><span class="fa fa-star-o mr-25" [class.fa-star]="fill === 100"></span
             ></ng-template>
           </ngb-rating>
         </div>
       </div>
       <div class="item-cost">
         <h6 class="item-price">EG {{product.product_price }}</h6>
       </div>
     </div>
     <h6 class="item-name">
       <a class="text-body" [routerLink]="'/product-details/' + product.product_id"> {{ product.product_name }}</a>
       <span class="card-text item-company"
         >By <a href="javascript:void(0)" class="company-name">{{ product.category.name }}</a></span
       >
     </h6>
     <p class="card-text item-description">
       {{ product.overview }}
     </p>
   </div>

   <div class="item-options text-center">
     <div class="item-wrapper">
       <div class="item-cost">
         <h4 class="item-price">${{ product.product_price }}</h4>
         <p class="card-text shipping" *ngIf="product.has_discount">
           <span class="badge badge-pill badge-light-success">Discount</span>
         </p>
       </div>
     </div>
     <!-- remove product -->
      
     <a
       href="javascript:void(0)"
       class="btn btn-danger btn-wishlist"
       rippleEffect
       (click)="removeProduct(product.product_id)"
       >
       <i class="fa mr-25 fa-trash text-light "></i>
       <span>Remove </span>
     </a>
     <a
       href="javascript:void(0)"
       class="btn btn-primary btn-cart"
       rippleEffect
       [routerLink]="'/product-details/' + product.product_id"
     >
       <i data-feather="info" class="mr-25"></i>
       <span class="add-to-cart">View Details</span>
     </a>
   </div>
   <!--/ Ecommerce Item -->
   