<div class="content-wrapper container-xxl p-0 mt-3">
  <div class="content-body">
    <!-- User List -->
    <section class="users-list-wrapper">
      <!-- users filter start -->
      <div class="card">
        <h5 class="card-header font-weight-bold text-primary display-4">New Arrival</h5>
        <div class="card-body"></div>
      </div>
      <!-- users filter end -->

      <div class="card">
        <div class="row">
          <div class="col-md-6 col-12 d-flex">
            <div class="d-flex justify-content-between align-items-center m-1">
              <label class="d-flex align-items-center">
                Show
                <select class="form-control mx-25" [(ngModel)]="selectedOption">
                  <option value="9999999999">All</option>
                  <option value="2">2</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="250">250</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                  <option value="2500">2500</option>
                  <option value="5000">5000</option>
                  <option value="10000">10000</option>
                </select>
                entries
              </label>
            </div>
            <div class="mt-1">
              <select class="form-control mx-5" [(ngModel)]="searchValue" (change)="filterUpdateCategory()">
                <option value="">All Product</option>
                <option *ngFor="let product of products?.data" [ngValue]="product.product_name">
                  {{ product.product_name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6 col-12 d-flex justify-content-start justify-content-md-end">
            <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0">
              
              <button class="btn btn-primary ml-3" rippleEffect>
                <span *ngIf="!loadProduct" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span *ngIf="!loadProduct" class="ml-25 align-middle">Loading...</span>
                <span *ngIf="loadProduct" class="d-sm-inline-block">Add Product</span>
              </button>
            </div>
          </div>
        </div>

        <ngx-datatable
          [rows]="rows"
          [rowHeight]="'auto'"
          class="bootstrap core-bootstrap"
          [limit]="selectedOption"
          [columnMode]="ColumnMode.force"
          [headerHeight]="50"
          [footerHeight]="50"
          [scrollbarH]="true"
        >
          <!--Category ID-->
          <ngx-datatable-column name="ID" prop="product_id" [width]="100">
            <ng-template let-id="value" ngx-datatable-cell-template>
              <div class="badge badge-pill badge-glow px-3">{{ id }}</div>
            </ng-template>
          </ngx-datatable-column>

          <!--Product Name-->
          <ngx-datatable-column name="Product Name" prop="product_name" [width]="220">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <ngx-avatar src="{{ row.product_image }}" name="{{ row.product_name }}" value="28%" size="40" [round]="true"></ngx-avatar>
                </div>
                <div class="cell-line-height">
                  <a class="font-medium-1 d-block">
                    <span class="font-weight-bold">{{ row.product_name }}</span>
                  </a>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <!-- Category Name-->
          <ngx-datatable-column name="Category Name" prop="category_name" [width]="220">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <ngx-avatar src="{{ row.product_image }}" name="{{ row.category_name }}" value="28%" size="40" [round]="true"></ngx-avatar>
                </div>
                <div class="cell-line-height">
                  <a class="font-medium-1 d-block">
                    <span class="font-weight-bold">{{ row.category_name }}</span>
                  </a>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <!--Product Price-->
          <ngx-datatable-column name="Product Price" prop="product_price" [width]="150">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="d-flex align-items-center">
                <div class="cell-line-height">
                  <a class="font-medium-1 d-block">
                    <span class="font-weight-bold">{{ row.product_price | currency:'USD':'symbol':'1.2-2' }}</span>
                  </a>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <!--Actions-->
          <ngx-datatable-column name="Actions" [width]="100" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
              <div ngbDropdown container="body">
                <button ngbDropdownToggle type="button" class="btn icon-btn btn-sm hide-arrow text-danger" rippleEffect>
                  <span data-feather="more-vertical" class="cursor-pointer"></span>
                </button>
                <div ngbDropdownMenu>
                  <a ngbDropdownItem><span data-feather="file-text" class="mr-50 text-info"></span>Details</a>
                  <a ngbDropdownItem><span data-feather="edit-2" class="mr-50 text-info"></span>Update</a>
                  <a ngbDropdownItem (click)="DeleteProduct(row.id , row.product_name)"><span data-feather="trash-2" class="mr-50 text-danger"></span>Delete</a>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </section>
  </div>
</div>
