
<div class="content-wrapper container-xxl p-0">
     <div class="content-body">
       <!-- User List -->
       <section class="users-list-wrapper">
         <!-- users filter start -->
         <div class="card">
           <h5 class="card-header">Store Filter</h5>
           <div class="card-body">
             <div class="users-list-filter">
               <form>
                 <div class="row">
                   <div class="col-12 col-md-4">
                     <fieldset class="form-group">
                       <ng-select
                         [items]="IsVerified"
                         [(ngModel)]="selectedVerified"
                         name="Verified"
                         bindLabel="name"
                         placeholder="Select Verified"
                         (change)="filterByVerified($event)"
                       ></ng-select>
                     </fieldset>
                   </div>
                   <div class="col-12 col-md-4">
                     <fieldset class="form-group">
                       <ng-select
                         [items]="IsSuspended"
                         [(ngModel)]="selectedSuspend"
                         name="Suspended"
                         bindLabel="name"
                         placeholder="Suspended"
                         (change)="filterBySuspended($event)"
                       ></ng-select>
                     </fieldset>
                   </div>
                   <div class="col-12 col-md-4">
                     <fieldset class="form-group">
                       <ng-select
                         [items]="IsDeleted"
                         [(ngModel)]="selectedDeleted"
                         name="Deleted"
                         bindLabel="name"
                         placeholder="Deleted"
                         (change)="filterByDeleted($event)"
                       ></ng-select>
                     </fieldset>
                   </div>
                 </div>
               </form> 
             </div>
           </div>
         </div>
         <!-- users filter end -->
   
         <div class="card">
           <div class="row">
             <div class="col-md-6 col-12">
               <div class="d-flex justify-content-between align-items-center m-1">
                 <label class="d-flex align-items-center"
                   >Show
                   <select class="form-control mx-25" [(ngModel)]="selectedOption">
                     <option value="9999999999999999999999">All</option>
                     <option value="2">2</option>
                     <option value="10">10</option>
                     <option value="25">25</option>
                     <option value="50">50</option>
                     <option value="100">100</option>
                     <option value="250">250</option>
                     <option value="500">500</option>
                     <option value="500">1000</option>
                     <option value="500">2500</option>
                     <option value="500">5000</option>
                     <option value="500">10000</option>
                   </select>
                   entries</label
                 >
               </div>
             </div>
             <div
               class="col-md-6 col-12 d-flex justify-content-start justify-content-md-end"
             >
               <div
                 class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0"
               >
                 <label class="d-flex align-items-center ml-1 ml-md-0"
                   >Search:<input
                     [(ngModel)]="searchValue"
                     name="searchValue"
                     type="search"
                     class="form-control ml-25"
                     (keyup)="filterUpdate($event)"
                     (search)="filterUpdate($event)"
                 /></label>
               </div>
             </div>
           </div>
           <ngx-datatable
             [rows]="rows"
             [rowHeight]="'auto'"
             class="bootstrap core-bootstrap "
             [limit]="selectedOption"
             [columnMode]="ColumnMode.force"
             [headerHeight]="50"
             [footerHeight]="50"
             [scrollbarH]="true"
   
           >
   
           <ngx-datatable-column name="ID" prop="id" [width]="80">
             <ng-template let-id="value" let-isDeleted="row.isDeleted"  let-isVerified="row.verified"  ngx-datatable-cell-template
          
             >
               <div
                 class="badge badge-pill badge-glow px-3"
                 [ngClass]="{
                   'badge-danger': isDeleted == true,
                   'badge-warning': isVerified == false,
                   '': isDeleted == false
                 }"
               >
                 {{ id }}
               </div>
             </ng-template>
           </ngx-datatable-column>
   
             <ngx-datatable-column
               name="Store Name"
               prop="store_name"
               [width]="200"
             >
               <ng-template
                 let-row="row"
                 let-name="value"
   
                 ngx-datatable-cell-template
               >
                 <div class="d-flex align-items-center">
                   <div class="mr-2">
                     <ngx-avatar
                       class="m-1"
                       src="{{ row.profile_image }}"
                       name="{{ row.store_name }}"
                       value="28%"
                       size="40"
                       [round]="true"
                     >
                     </ngx-avatar>
                   </div>
                   <div class="cell-line-height">
                     <a class="font-medium-1 d-block"
                       ><span class="font-weight-bold">{{
                         row.store_name
                       }}</span></a
                     >
                     <span class="text-secondary font-weight-bold">
                       @{{ row.owner_name }}</span
                     >
                   </div>
                 </div>
               </ng-template>
             </ngx-datatable-column>
             <ngx-datatable-column name="phone" prop="store_phone" [width]="100">
               <ng-template let-phone="value" ngx-datatable-cell-template>
                 <div
                   class="badge badge-pill"
                   [ngClass]="{
                     'badge-light-warning': phone != '',
                     'badge-light-primary': phone == 'Null'
                   }"
                 >
                   {{ phone }}
                 </div>
               </ng-template>
             </ngx-datatable-column>
             <ngx-datatable-column
               name="Email"
               prop="email"
               [width]="200"
             ></ngx-datatable-column>
             <ngx-datatable-column name="Address" prop="store_address" [width]="400">
               <ng-template let-address="value" ngx-datatable-cell-template>
                 <div>
                   <i
                     class="feather font-medium-3"
                     [ngClass]="{
                       'icon-edit text-warning': address == null,
                       'icon-map-pin text-danger': address !== ''
                     }"
                   ></i>
                   {{ address.address }}
                 </div>
               </ng-template>
             </ngx-datatable-column>
   
   
   
   
   
             <ngx-datatable-column name="categories" prop="categories
             " [width]="200" >
               <ng-template
                 let-row="row"
                 let-name="row.categories "
                 ngx-datatable-cell-template
               >
               <div *ngFor="let item of name">
                <div class="row">
                  <i
                  class="feather font-medium-3 mr-2"
                  [ngClass]="{
                    'icon-gift  text-warning ': item !== ''
                  }"
                ></i>
                {{ item.name }}
                </div>
             
              </div>
               </ng-template>
             </ngx-datatable-column>
   
             <ngx-datatable-column name="Slug" prop="store_slug" [width]="150">
               <ng-template let-slug="value" ngx-datatable-cell-template>
                 <div class="badge badge-pill" [ngClass]="'badge-light-primary'">
                   {{ slug }}
                 </div>
               </ng-template>
             </ngx-datatable-column>
             <ngx-datatable-column name="URL" prop="store_url" [width]="150">
               <ng-template let-url="value" ngx-datatable-cell-template>
                 <div
                   class="badge badge-pill"
                   [ngClass]="{
                     'badge-light-success': url != null,
                     'badge-light-warning': url == null
                   }"
                 >
                   {{ url }}
                 </div>
               </ng-template>
             </ngx-datatable-column>
             <ngx-datatable-column name="Mobile Verified" prop="mobile_verified" [width]="150">
               <ng-template let-status="value" ngx-datatable-cell-template>
                 <div
                   class="badge badge-pill"
                   [ngClass]="{
                     'badge-light-success': status == true,
                     'badge-light-danger': status == false
                   }"
                 >
                   {{ status }}
                 </div>
               </ng-template>
             </ngx-datatable-column>
   
             <ngx-datatable-column name="Suspend" prop="banned" [width]="100">
               <ng-template let-sus="value" ngx-datatable-cell-template>
                 <div
                   class="badge badge-pill"
                   [ngClass]="{
                     'badge-light-success': sus == false,
                     'badge-light-danger': sus == true
                   }"
                 >
                   {{ sus }}
                 </div>
               </ng-template>
             </ngx-datatable-column>
   
             <ngx-datatable-column name="Deleted" prop="isDeleted" [width]="100">
               <ng-template let-sus="value" ngx-datatable-cell-template>
                 <div
                   class="badge badge-pill"
                   [ngClass]="{
                     'badge-light-success': sus == false,
                     'badge-light-danger': sus == true
                   }"
                 >
                   {{ sus }}
                 </div>
               </ng-template>
             </ngx-datatable-column>

                <ngx-datatable-column name="verified" prop="verified" [width]="100">
            <ng-template let-sus="value" ngx-datatable-cell-template>
              <div
                class="badge badge-pill"
                [ngClass]="{
                  'badge-light-success': sus == true,
                  'badge-light-warning': sus == false
                }"
              >
                {{ sus }}
              </div>
            </ng-template>
          </ngx-datatable-column>
   
             <ngx-datatable-column name="Actions" [width]="100" [sortable]="false">
               <ng-template ngx-datatable-cell-template let-row="row">
                 <div ngbDropdown container="body">
                   <button
                     ngbDropdownToggle
                     type="button"
                     class="btn icon-btn btn-sm hide-arrow text-danger"
                     rippleEffect
                   >
                     <span
                       [data-feather]="'more-vertical'"
                       [class]="'cursor-pointer'"
                     ></span>
                   </button>
                   <div ngbDropdownMenu>
                     <a ngbDropdownItem
                       ><span
                         [data-feather]="'file-text'"
                         [size]="16"
                         [class]="'mr-50 text-info'"
                       ></span>
                       Details</a
                     >        
                     <a
                     *ngIf="row.banned == false && row.isDeleted == false "
                       ngbDropdownItem
                       (click)="SuspendUser(row.id, row.store_name)"
                       ><span
                         [data-feather]="'slash'"
                         [size]="16"
                         [class]="'mr-50 text-warning'"
                       ></span>
                       Suspend</a
                     >
                     <a
                     *ngIf="row.banned == true && row.isDeleted == false "
                       ngbDropdownItem
                       (click)="ActiveStore(row.id, row.store_name)"
                       ><span
                         [data-feather]="'check-circle'"
                         [size]="16"
                         [class]="'mr-50 text-success'"
                       ></span>
                       Active</a
                     >
   
                     <a
                       ngbDropdownItem
                       *ngIf="row.isDeleted == false "
   
                       (click)="DeleteStore(row.id, row.store_name)"
                       ><span
                         [data-feather]="'x-octagon'"
                         [size]="16"
                         [class]="'mr-50 text-danger'"
                       ></span>
                       Delete</a
                     >
   
                     <a
                       ngbDropdownItem
                       *ngIf="row.isDeleted== true "
                       (click)="RestoreStore(row.id, row.store_name)"
                       ><span
                         [data-feather]="'refresh-cw'"
                         [size]="16"
                         [class]="'mr-50 text-warning'"
                       ></span>
                       Restore</a
                     >
                   </div>
                 </div>
               </ng-template>
             </ngx-datatable-column>
           </ngx-datatable>
         </div>
       </section>
     </div>
   
   </div>
   