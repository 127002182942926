<div class="content-wrapper container-xxl p-0 mt-3">
  <div class="content-body">
    <!-- User List -->
    <section class="users-list-wrapper">
      <!-- users filter start -->
      <div class="card card-style">
        <h5 class="card-header font-weight-bold  display-4">
          Home Categories
        </h5>
        <div class="card-body"></div>
      </div>
      <!-- users filter end -->

      <div class="card card-style">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="d-flex justify-content-between align-items-center m-1">
              <label class="d-flex align-items-center">
                Show
                <select class="form-control mx-25" [(ngModel)]="selectedOption">
                  <option value="9999999999999999999999">All</option>
                  <option value="2">2</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="250">250</option>
                  <option value="500">500</option>
                  <option value="500">1000</option>
                  <option value="500">2500</option>
                  <option value="500">5000</option>
                  <option value="500">10000</option>
                </select>
                entries</label
              >
            </div>
          </div>

          <div
            class="col-md-6 col-12 d-flex justify-content-start justify-content-md-end"
          >
            <div
              class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0"
            >
              <label class="d-flex align-items-center ml-1 ml-md-0">
                Search:
                <input
                  [(ngModel)]="searchValue"
                  name="searchValue"
                  type="search"
                  class="form-control ml-25"
                  (keyup)="filterUpdate($event)"
                  (search)="filterUpdate($event)"
                />
              </label>
              <button
                (click)="addCategoryModel(modalAddCategory)"
                class="btn btn-primary ml-3"
                rippleEffect
              >
                Add New Category
              </button>
            </div>
          </div>
        </div>

        <ngx-datatable
          [rows]="rows"
          [rowHeight]="'auto'"
          class="bootstrap core-bootstrap"
          [limit]="selectedOption"
          [columnMode]="ColumnMode.force"
          [headerHeight]="50"
          [footerHeight]="50"
          [scrollbarH]="true"
        >
          <!--Category ID-->
          <ngx-datatable-column name="ID" prop="id" [width]="100">
            <ng-template
              let-id="value"
              let-isDeleted="row.isDeleted"
              let-isVerified="row.verified"
              ngx-datatable-cell-template
            >
              <div class="px-3">
                {{ id }}
              </div>
            </ng-template>
          </ngx-datatable-column>

          <!--Category English Name  -->
          <ngx-datatable-column
            name="Category Name En"
            prop="name"
            [width]="220"
          >
            <ng-template
              let-row="row"
              let-name="value"
              ngx-datatable-cell-template
            >
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <ngx-avatar
                    class="m-1"
                    src="{{ row?.image }}"
                    name="{{ row?.name_en }}"
                    value="28%"
                    size="40"
                    [round]="true"
                  >
                  </ngx-avatar>
                </div>
                <div class="cell-line-height">
                  <a class="font-medium-1 d-block"
                    ><span class="font-weight-bold">{{ row?.name_en }}</span></a
                  >
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <!-- Category Arabic Name  -->
          <ngx-datatable-column
            name="Category Name Ar"
            prop="name"
            [width]="220"
          >
            <ng-template
              let-row="row"
              let-name="value"
              ngx-datatable-cell-template
            >
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <ngx-avatar
                    class="m-1"
                    src="{{ row?.image }}"
                    name="{{ row?.name_ar }}"
                    value="28%"
                    size="40"
                    [round]="true"
                  >
                  </ngx-avatar>
                </div>
                <div class="cell-line-height">
                  <a class="font-medium-1 d-block">
                    <span class="font-weight-bold">{{ row?.name_ar }}</span>
                  </a>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <!--Category Actions-->
          <ngx-datatable-column name="Actions" [width]="100" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
              <div ngbDropdown container="body">
                <button
                  ngbDropdownToggle
                  type="button"
                  class="btn icon-btn btn-sm hide-arrow text-danger"
                  rippleEffect
                  title="more"
                >
                  <span
                    [data-feather]="'more-vertical'"
                    [class]="'cursor-pointer'"
                  ></span>
                </button>
                <div ngbDropdownMenu>
                  <!-- Delete -->
                  <a (click)="deleteCategory(row?.id)" ngbDropdownItem>
                    <span
                      [data-feather]="'trash-2'"
                      [size]="16"
                      [class]="'mr-50 text-danger'"
                    ></span>
                    Delete
                  </a>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </section>
  </div>
</div>

<!-- Add New Category Model -->
<ng-template #modalAddCategory let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalCenterTitle">New Category</h5>
    <button
      id="clo"
      type="button"
      class="close"
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <form
      id="fo"
      [formGroup]="CreateNewCategoryForm"
      (ngSubmit)="CreateNewCategoryMethod()"
    >
      <!-- Category Dropdown -->
      <div class="form-group my-1">
        <label for="categorySelect">Select Category</label>
        <select
          id="categorySelect"
          formControlName="category"
          class="form-control mb-1"
          [ngClass]="{
            'is-invalid':
              CreateNewCategoryFormSubmitted &&
              CreateNewCategoryForm.get('category')?.invalid
          }"
          [disabled]="!tempData?.length"
          aria-label="Select a category"
        >
          <option value="" disabled selected>Select a category</option>
          <ng-container *ngFor="let row of tempData">
            <option [value]="row?.id">
              {{ row?.name_en }}
            </option>
          </ng-container>
        </select>

        <!-- Validation Message -->
        <div
          *ngIf="
            CreateNewCategoryFormSubmitted &&
            CreateNewCategoryForm.get('category')?.invalid
          "
          class="invalid-feedback"
        >
          <div *ngIf="CreateNewCategoryForm.get('category')?.errors?.required">
            Category is required
          </div>
        </div>
      </div>
      <!-- English Input -->
      <label>Category Name (English): </label>
      <div class="form-group">
        <input
          formControlName="name_en"
          [ngClass]="{
            'is-invalid':
              CreateNewCategoryFormSubmitted && NewCategoryForm?.name_en?.errors
          }"
          type="text"
          placeholder="Name (English)"
          class="form-control"
          disabled="disabled"
        />
        <!-- Error messages remain the same -->
      </div>

      <!-- Arabic Input -->
      <label>Category Name (Arabic): </label>
      <div class="form-group">
        <input
          formControlName="name_ar"
          [ngClass]="{
            'is-invalid':
              CreateNewCategoryFormSubmitted && NewCategoryForm?.name_ar?.errors
          }"
          type="text"
          placeholder="Name (Arabic)"
          class="form-control"
          disabled="disabled"
        />
        <!-- Error messages remain the same -->
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-success round ml-1"
      (click)="CreateNewCategoryMethod()"
      rippleEffect
    >
      <span *ngIf="!isLoading">Add</span>
      <span *ngIf="isLoading">
        <i class="fa fa-spinner fa-spin"></i> Adding...
      </span>
    </button>

    <button
      type="button"
      class="btn btn-outline-danger round ml-1"
      (click)="modal.close('Accept click')"
      rippleEffect
    >
      Close
    </button>
  </div>
</ng-template>
