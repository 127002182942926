import { Component, OnInit } from "@angular/core";
import { HomeCategoriesService } from "../home-categories.service";
import { ColumnMode } from "@swimlane/ngx-datatable";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";

@Component({
  selector: "app-sub-category-product-list",
  templateUrl: "./sub-category-product-list.component.html",
  styleUrls: ["./sub-category-product-list.component.scss"],
})
export class SubCategoryProductListComponent implements OnInit {
  rows: any = [];
  tempData: any = [];
  selectedOption: number = 10;
  searchValue = "";
  ColumnMode = ColumnMode;
  CreateNewSubCategoryForm: UntypedFormGroup;
  modalReference: any;
  isLoading: boolean = false;
  CreateNewSubCategoryFormSubmitted: boolean = false;

  constructor(
    private _HomeCategoriesServ: HomeCategoriesService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder
  ) {
    this.CreateNewSubCategoryForm = this.formBuilder.group({
      category: ["", Validators.required], // Form Control
    });
  }

  ngOnInit(): void {
    // this.getAllSubCategory();
    this.AllSubCategoriesAdded();
  }

  // Get all SubCategories
  // getAllSubCategory() {
  //   this._HomeCategoriesServ.getAllSubCategory().subscribe(
  //     (res: any) => {
  //       this.tempData = res;
  //     },
  //     (err: any) => {
  //       console.error("Error fetching subcategories:", err);
  //     }
  //   );
  // }

  // Get all Added SubCategories
  AllSubCategoriesAdded() {
    this._HomeCategoriesServ.getAllNonCategoriesAdded().subscribe(
      (res: any) => {
        this.rows = res;
        this.tempData = res;
      },
      (err: any) => {
        console.error("Error fetching non-added subcategories:", err);
      }
    );
  }

  // Open Modal for Adding Sub Categories
  addSubCategoryModel(modalAddSubCategory: any) {
    this.isLoading = false;
    this.CreateNewSubCategoryFormSubmitted = false;
    this.CreateNewSubCategoryForm.reset();
  
    setTimeout(() => {
      this.modalReference = this.modalService.open(modalAddSubCategory, {
        centered: true,
        backdrop: "static",
        keyboard: false,
      });
    }, 300);
  
    this._HomeCategoriesServ.getAllSubCategory().subscribe(
      (res: any) => {
        this.tempData = res;
      },
      (err: any) => {
        console.error("Error fetching subcategories:", err);
      }
    );
  }
  
  







 // Add New SubCategory
CreateNewSubCategoryMethod() {
  console.log("Button Clicked!"); // Debugging
  this.CreateNewSubCategoryFormSubmitted = true;
  
  if (this.CreateNewSubCategoryForm.invalid) {
    return;
  }
  
  this.isLoading = true; // Disable button and show spinner
  const formData = new FormData();
  formData.append(
    "category_id",
    this.CreateNewSubCategoryForm.value.category
  );

  this._HomeCategoriesServ.addCategories(formData).subscribe({
    next: (response) => {
      console.log("SubCategory created successfully", response);
      this.isLoading = false; // Re-enable button
      this.modalReference?.dismiss();

      // Reset the form after success
      this.CreateNewSubCategoryForm.reset({
        name_en: '',
        category: ''
      });

      // SweetAlert Success Message
      Swal.fire({
        title: "Success!",
        text: "SubCategory created successfully.",
        icon: "success",
        confirmButtonText: "OK",
        timer: 3000,
      }).then(() => {
        this.AllSubCategoriesAdded();
      });
    },
    error: (error) => {
      console.error("Error creating SubCategory", error);
      this.isLoading = false; // Re-enable button after error

      // Reset the form even on failure
      this.CreateNewSubCategoryForm.reset({
        name_en: '',
        category: ''
      });

      // SweetAlert Error Message
      Swal.fire({
        title: "Error!",
        text: error.error?.error || "Failed to create subcategory.",
        icon: "error",
        confirmButtonText: "OK",
      });
    },
  });
}


  // Delete Category
  deleteCategory(categoryId: number) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this._HomeCategoriesServ.deleteCategory(categoryId).subscribe({
          next: (response) => {
            console.log("Category deleted successfully", response);
            Swal.fire({
              title: "Deleted!",
              text: "Category has been deleted.",
              icon: "success",
              confirmButtonText: "OK",
              timer: 3000,
            });
            this.AllSubCategoriesAdded();
          },
          error: (error) => {
            console.error("Error deleting category", error);
            Swal.fire({
              title: "Error!",
              text: "Failed to delete category. Please try again.",
              icon: "error",
              confirmButtonText: "OK",
            });
          },
        });
      }
    });
  }

  filterUpdate(event: any) {
    this.searchValue = event.target.value.toLowerCase();
  }
}
