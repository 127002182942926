import { Refund } from './../../../main/sample/modules/refunds';
import { RefundService } from './../refund.service';
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
import { Component, OnInit, ViewChild } from "@angular/core";
import Swal from "sweetalert2";
import { FormBuilder, FormGroup, NgModel, Validators } from "@angular/forms"
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { get } from 'http';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';

@Component({
  selector: "app-refund-list",
  templateUrl: "./refund-list.component.html",
  styleUrls: ["./refund-list.component.scss"],
})
export class RefundListComponent implements OnInit {
  public isLoading = false;
  public selectedStatus = "Pending"; // Default active status
  public selectedOption = 10;
  public searchValue = "";
  public ColumnMode = ColumnMode;
  public rows = []; // Data source for the table
  public tempData = []; // Data source for the table

  @ViewChild('modalSelectDelivery') modalSelectDelivery: any;
  refunds: any;
  allDeliver: any;
  DeliveryUser: any
  rejectionReason: string = '';
  Status: number | null = null;
  SelectDeliveryForm: FormGroup
  SelectDeliveryFormSubmitted = false
  modalReference: any;
  selectedRefundId: number | null = null;
  refundId: any;

  userRole :string;
  public currentUser: User;





  // Refund Status
  public refundStatus: any = [
    { name: "Pending", value: "Pending" },
    { name: "Wating For Delivery", value: "Wating For Delivery" },
    { name: "on the Way", value: "on the Way" },
    { name: "Rejected By delivery", value: "Rejected By delivery" },
    { name: "Accepted", value: "Accepted" },
    { name: "Rejected", value: "Rejected" },
  ];

  public IsVerified: any = [
    { name: "All", value: "" },
    { name: "True", value: "true" },
    { name: "False", value: "false" },
  ];
  public IsDeleted: any = [
    { name: "All", value: "" },
    { name: "True", value: "true" },
    { name: "False", value: "false" },
  ];
  public IsSuspended: any = [
    { name: "All", value: "" },
    { name: "True", value: "true" },
    { name: "false", value: "false" },
  ];

  public selectedSuspend = [];
  public selectedVerified = [];
  public selectedDeleted = [];

  constructor(
    private _refundService: RefundService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private _authenticationService: AuthenticationService,

  ) {
    this.SelectDeliveryForm = this.formBuilder.group({
      deliveryOption: ['', Validators.required],
      additionalNotes: ['']
    });

    this._authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
    console.log(this.currentUser);
    this.userRole=this.currentUser.role
    console.log("user Role " , this.userRole);
    


  }
  ngOnInit(): void {
    // This will handle both setting the status and getting filtered statuses
    this.setupRefundByRole();
    
    // Load appropriate data based on role
    if (this.userRole === 'delivery_user') {
      this.getOnTheWayRefunds();
    } else {
      this.getAllPendingRefunds();
    }
    
    // These common operations can happen for all roles
    this.getAllDeliveryUsers();
    
    // Call this after selectedStatus is set correctly
    this.onStatusChange(this.selectedStatus);
  }
  
  // Combined function that handles both setting status and filtering
  setupRefundByRole() {
    if (this.userRole === 'delivery_user') {
      this.selectedStatus = "on the Way";
      this.refundStatus = this.refundStatus.filter((status: any) =>
        status.value !== 'Pending' && status.value !== 'Wating For Delivery'
      );
    } else {
      this.selectedStatus = "Pending"; // Default for other roles
      // Keep the original refundStatus array unchanged for other roles
    }
  }





  // Change status
  onStatusChange(status: string): void {
    this.selectedStatus = status; // Update the selected status
    this.isLoading = true;

    switch (status) {
      case "Pending":
        this.getAllPendingRefunds(); ///pending
        break;
      case "Wating For Delivery":
        this.getAllWaitForDelivery();
        break;
      case "on the Way":
        this.getOnTheWayRefunds();
        break;
      case "Rejected By delivery":
        this.getAllRejectByDelivery();
        break;
      case "Accepted":
        this.getAllAccepted();
        break;
      case "Rejected":
        this.getRejectedRefunds();
        break;
      default:
        this.isLoading = false;
        console.log("Unknown status:", status);
    }
  }

  filterUpdate(event) { }

  //All Pending Refunds
  getAllPendingRefunds() {
    this.isLoading = true;
    this._refundService.allPendingRefunds().subscribe(
      (res) => {
        this.refunds = res
        this.rows = this.refunds.refunds;
        this.tempData = this.refunds.refunds;
        this.isLoading = false;
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
      }
    )
  }

  //Waiting For delivery 
  getAllWaitForDelivery() {
    this.isLoading = true;
    this._refundService.allWaitForDelivered().subscribe(
      (res) => {
        this.refunds = res
        this.rows = this.refunds.refunds;
        this.tempData = this.refunds.refunds;
        console.log(this.rows);
        this.isLoading = false;
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
      }
    )
  }

  // One The Way Refunds
  getOnTheWayRefunds() {
    this.isLoading = true;

    this._refundService.allOnTheWayRefunds().subscribe(
      (res) => {
        this.refunds = res
        this.rows = this.refunds.refunds;
        this.tempData = this.refunds.refunds;
        // console.log(this.rows);
        this.isLoading = false;
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  // Reject By Delivery
  getAllRejectByDelivery() {
    this.isLoading = true;
    this._refundService.allRejectByDelivery().subscribe(
      (res) => {
        this.refunds = res
        this.rows = this.refunds.refunds;
        this.tempData = this.refunds.refunds;
        console.log(this.rows);
        this.isLoading = false;
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  // All Rejected 
  getRejectedRefunds() {
    this.isLoading = true;
    this._refundService.allRejectedRefunds().subscribe(
      (res) => {
        this.refunds = res
        this.rows = this.refunds.refunds;
        this.tempData = this.refunds.refunds;
        console.log(this.rows);
        this.isLoading = false;
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  // All Accepted 
  getAllAccepted() {
    this.isLoading = true;
    this._refundService.allAcceptRefunds().subscribe(
      (res) => {
        this.refunds = res
        this.rows = this.refunds.refunds;
        this.tempData = this.refunds.refunds;
        console.log(this.rows);
        this.isLoading = false;
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  // All Deliveries 
  getAllDeliveryUsers() {
    this.isLoading = true;
    this._refundService.allDeliveryUsers().subscribe(
      (res) => {
        this.allDeliver = res
        this.DeliveryUser = this.allDeliver.delivery_users
        console.log(this.DeliveryUser);
        this.isLoading = false;
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  // changestatus
  changestatus(status: number, id: number) {
    this.Status = status;
    this.refundId = id

    console.log(this.Status);
    console.log(this.refundId);

    Swal.fire({
      title: 'Are you sure you want to change the status?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, change it!',
      cancelButtonText: 'No, cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        if (status === 5 || status === 6) {
          Swal.fire({
            title: 'Provide a reason for rejection',
            input: 'textarea',
            inputPlaceholder: 'Enter the reason for rejection...',
            inputValidator: (value) => {
              if (!value) return 'You need to provide a reason!';
              return null;
            },
            showCancelButton: true
          }).then((inputResult) => {
            if (inputResult.isConfirmed && inputResult.value) {
              const payload = { refund_id: id, status: status, rejection_reason: inputResult.value };
              this.sendChangeStatusRequest(payload, status, this.refundId);
            }
          });
        } else {
          const payload = { refund_id: id, status: status };
          this.sendChangeStatusRequest(payload, status, this.refundId);
        }
      }
    });
  }

  sendChangeStatusRequest(payload: any, status: number, id: any) {
    console.log(id);
    this.refundId = id
    console.log(this.refundId);

    this._refundService.changeRefundStatus(payload).subscribe(
      (res) => {
        console.log(res);
        Swal.fire('Success', 'Status changed successfully!', 'success').then(() => {
          // Open modal if status is 3 (On The Way)
          if (status === 3) {
            this.modalService.open(this.modalSelectDelivery, { centered: true, backdrop: 'static' });
          }
        });
      },
      (err) => {
        console.log(err);
        Swal.fire('Error', 'An error occurred while changing the status.', 'error');
      }
    );
  }

  // Assign eliveryUse
  assignDeliveryUser() {

    if (this.SelectDeliveryForm.invalid) {
      return;
    }

    const formValue = this.SelectDeliveryForm.value.deliveryOption
    console.log(formValue);
    console.log(this.refundId);

    this.isLoading = true;
    const payload = {
      refund_id: this.refundId,
      delivery_id: formValue
    };

    this._refundService.assignDelivery(payload).subscribe(
      (res) => {
        console.log(res);
        this.isLoading = false;

        Swal.fire({
          icon: 'success',
          title: 'Delivery Assigned',
          text: 'The delivery has been successfully assigned.',
          timer: 2000,
          showConfirmButton: false
        });
      },
      (err) => {
        console.error(err);
        this.isLoading = false;

        Swal.fire({
          icon: 'error',
          title: 'Assignment Failed',
          text: err?.error?.message || 'An error occurred while assigning the delivery.',
          confirmButtonText: 'Retry'
        });
      }
    );
  }


  // openModal(content: any, refundId: number) {
  //   this.selectedRefundId = refundId; // Store the selected row ID
  //   this.modalService.open(content, { size: 'lg', centered: true });
  // }




}
