import {
  Product,
  MainFeatureValue,
} from "./../../../main/sample/modules/product";
import { ProductDetails } from "./../../../main/sample/modules/product";
import { Router } from "@angular/router"; // Import the Router service
import { ActivatedRoute } from "@angular/router";
import { ProductsService } from "./../products.service";
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import Swal from "sweetalert2";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { log } from "console";

@Component({
  selector: "app-product-detail",
  templateUrl: "./product-detail.component.html",
  styleUrls: ["./product-detail.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: { class: "ecommerce-application" },
})
export class ProductDetailComponent implements OnInit {
  @Input() ProductDetails;
  @ViewChild("modalUpdateValueImages")
  modalUpdateValueImages!: TemplateRef<any>;

  public swiperCoverflow: SwiperConfigInterface = {
    effect: "coverflow",
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: "auto",
    coverflowEffect: {
      rotate: 60,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    pagination: {
      el: ".swiper-pagination",
    },
  };

  public product: any;
  public productData: ProductDetails;
  public productId:any;
  public modalReference:any;
  public firstSkuQuantity: string | undefined; // Define the property to hold the quantity
  public firstSkuColor: string | undefined; // Define the property to hold the quantity

  // public products variables;
  public shopSidebarToggle = false;
  public shopSidebarReset = false;
  public gridViewRef = true;
  public wishlist;
  public cartList;

  // Updata Data variables
  public updateProductForm: FormGroup;
  public selectedOption: string = "";
  public updateProductFormSubmitted = false;
  public showPriceInput: boolean = false;
  public additional_features = [
    { name_en: "", name_ar: "", value_en: "", value_ar: "" },
  ];
  public has_discount: any | null = null;
  public files: File[] = [];
  public fileNames: string[] = [];
  public isLoading: boolean = false;

  // Updata Feature variables
  public features = [
    { firstFeature: "", secondFeature: "", quantity: "", price: "" },
  ];
  public categoryId: number;
  public Features;
  public SubFeatures;
  public featureSku;

  // Update Images variables
  public imagesIds: number[] = [];
  public imagefiles;
  private requestSubscription: any;
  private abortController: AbortController = new AbortController();

  //Updata feature Value Images
  updateValueImagesFormSubmitted = false;
  updateValueImagesForm: FormGroup;
  colorsFeatureValues: any;
  selectedFiles: any[] = [];
  loadingStates: boolean[] = [];
  isClicked: boolean[] = []; // Initialize this array based on your needs
  uploadCompleted: boolean[] = []; // Tracks if upload is completed for each color
  isUploadComplete: boolean = false; // حالة الزر
  modalReference1: any;

  // Update Colored Image
  coloredImage_ids: number[] = [];
  image_files: any[] = [];
  refundableControl: FormControl;
  isRefundable: boolean = false;
  coloredImagesIds: number[] = [];
  newColoredImages:any




  constructor(
    private _productServices: ProductsService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private FormBuilder: FormBuilder
  ) {
    this.updateProductForm = this.FormBuilder.group({
      selectedOption: [""],
      name_en: ["", [Validators.minLength(2)]],
      name_ar: ["", [Validators.minLength(2)]],
      product_price: [null, []],
      discounted_price: [null],
      discount: [null],
      description_en: ["", [Validators.minLength(2)]],
      description_ar: ["", [Validators.minLength(2)]],
      images: [null],
      refundable: this.refundableControl,
    });

    this.updateValueImagesForm = this.FormBuilder.group({
      image: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.productId = this.route.snapshot.paramMap.get("id");
    this.getProductDetails(this.productId);
    this.refundableControl = new FormControl(0, Validators.required);
    this.toggleRefundable(event);
  }

  // Product details
  getProductDetails(id: number): void {
    // console.log("this is url id :" , id);

    this._productServices.GetProductById(id).subscribe(
      (res: any) => {
        this.productData = res;
        console.log("Product Details:", this.productData);

        this.featureSku = this.productData.product_skus[0].id;
        // console.log(this.featureSku);

        this.categoryId = this.productData.category.id;
        // console.log("Category Id :", this.categoryId);

        this.imagesIds = this.productData.images_ids;
        console.log(this.imagesIds);

        // Colored Images Ids
        this.coloredImagesIds = this.productData.product_skus.reduce(
          (acc: number[], sku) => {
            sku.feature_values.forEach((feature) => {
              feature.colored_images.forEach((img) => {
                acc.push(Number(img.id));
              });
            });
            return acc;
          },
          []
        );
        // Remove the first ID
        this.coloredImagesIds.shift();
        console.log(this.coloredImagesIds);
      },
      (error) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "NO PRODUCT found!",
          showConfirmButton: true,
        });
        console.error("Error fetching product details:", error);
      }
    );
  }

  toggleRefundable(event: Event) {
    const value = +(event.target as HTMLInputElement).value;
    this.isRefundable = value === 1;

    // Ensure the form control is updated
    this.updateProductForm.patchValue({
      refundable: value,
    });

    console.log(
      "Refundable status:",
      this.isRefundable ? "Refundable" : "Non-Refundable",
      "Form value:",
      this.updateProductForm.get("refundable")?.value
    );
  }

  // Total Quantity
  getTotalQuantity(): number {
    if (!this.productData?.product_skus) {
      return 0;
    }

    // Summing the quantities from product_skus array
    return this.productData.product_skus.reduce((total: number, sku: any) => {
      return total + Number(sku.quantity); // Ensure quantity is treated as a number
    }, 0);
  }

  // Delete Product
  removeProduct(id: number, name: string): void {
    Swal.fire({
      title: `Are you sure Want To Delete : ${name} ?`,
      text: "You won't be able to revert this!",
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#4c54f5",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes , Delete it",
    }).then((result) => {
      if (result.isConfirmed) {
        this._productServices.DeleteProductById(id).subscribe(
          (re: any) => {
            this.router.navigate(["/view-all-products"]); // Use Router for navigation
            Swal.fire(
              "Deleted!",
              "Product has been Deleted Successfully .",
              "success"
            );
          },
          (err: any) => {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "An Error Occurred While Deleting Product",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        );
      }
    });
  }

  //Option Change
  onOptionChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedOption = selectElement.value;
    console.log(this.selectedOption);

    if (this.selectedOption === "feature") {
      this.getSubFeatures();
    }

    // if (this.selectedOption === 'image') {
    // }
  }

  // Discount Change
  onDiscountChange(event: Event): void {
    // const value = (event.target as HTMLSelectElement).value;
    // this.has_discount = value === '1';
    this.updateProductForm.patchValue({ discounted_price: this.has_discount });
    const target = event.target as HTMLSelectElement;
    this.has_discount = target.value;
    console.log("has_discount:", this.has_discount);
  }

  // Add Feature
  addFeature() {
    this.features.push({
      firstFeature: "",
      secondFeature: "",
      price: "",
      quantity: "",
    });
  }
  // Delete Feature
  deleteFeature(id) {
    for (let i = 0; i < this.features.length; i++) {
      if (this.features.indexOf(this.features[i]) === id) {
        this.features.splice(i, 1);
        break;
      }
    }
  }

  // Get Features
  getSubFeatures() {
    // console.log(this.categoryId);
    this._productServices.getFeatures(this.categoryId).subscribe(
      (res: any) => {
        this.Features = res;
        this.SubFeatures = this.Features.features;
        // console.log(this.SubFeatures)
      },
      (er: any) => {
        console.log(er);
      }
    );
  }

  // Add Additional Feature //
  addAdditionalFeature() {
    this.additional_features.push({
      name_en: "",
      name_ar: "",
      value_en: "",
      value_ar: "",
    });
  }

  // Delete Additional Feature
  deleteAdditionalFeature(id) {
    for (let i = 0; i < this.additional_features.length; i++) {
      if (
        this.additional_features.indexOf(this.additional_features[i]) === id
      ) {
        this.additional_features.splice(i, 1);
        break;
      }
    }
  }

  // modelEditProduct
  modelEditProduct(
    modalEditProduct: any,
    id: number,
    name_en: string,
    name_ar: string,
    product_price: number,
    has_discount: boolean,
    discounted_price: number,
    description_en: string,
    description_ar: string,
    refundable: boolean
  ): void {
    this.updateProductFormSubmitted = false;
    this.updateProductForm.reset();

    // Populate the form with existing product data
    this.updateProductForm.patchValue({
      product_id: id,
      name_en: name_en,
      name_ar: name_ar,
      product_price: product_price,
      has_discount: has_discount,
      discounted_price: discounted_price,
      description_ar: description_ar,
      description_en: description_en,
      refundable: refundable,
      // Add other fields you want to pre-fill
    });

    // Open the modal
    this.modalReference = this.modalService.open(modalEditProduct, {
      backdrop: false,
      centered: true,
    });
  }

  // photo of Product
  onFileUpload(event: any) {
    const files = event.target.files;
    // console.log(files);

    if (files && files.length > 0) {
      this.files = Array.from(files);
      this.fileNames = this.files.map((file) => file.name);
      console.log("photos : ", this.files);
      this.updateProductForm.patchValue({
        images: this.files,
      });
      this.updateProductForm.get("images")?.updateValueAndValidity();
    }
  }

  // Delete main Photo
  removeImage(index: number, imageUrl: string) {
    this.productData.images = this.productData.images.filter(
      (_, i) => i !== index
    );
    this.imagesIds = this.imagesIds.filter((_, i) => i !== index);
    console.log(this.imagesIds);
    console.log(this.productId);
    console.log(this.fileNames);
  }

  // Method Edit Feature
  updateFeature(): void {
    this.isLoading = true;
    console.log("Product id:", this.productId);
    this.updateProductFormSubmitted = true;

    // Check if the form is invalid
    if (this.updateProductForm.invalid) {
      console.log("Form is invalid");
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill in all the required fields!",
        confirmButtonText: "OK",
      });
      return;
    }

    // Feature Payload
    const payload = {
      features: this.features.map((feature) => ({
        feature_values_ids: [
          feature.firstFeature,
          feature.secondFeature,
        ].filter((id) => id),
        price: feature.price,
        quantity: feature.quantity,
      })),
    };

    // Feature update
    this._productServices
      .UpdateProductFeature(this.productId, payload)
      .subscribe({
        next: (response: any) => {
          this.isLoading = false;
          console.log("Product feature updated successfully", response);

          // Array Of New Colors Feature Values
          this.colorsFeatureValues = response.new_colors;
          console.log(this.colorsFeatureValues?.color_name);
          console.log(this.productId);

          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Product features updated successfully!",
            confirmButtonText: "OK",
          }).then(() => {
            if (response.new_colors && response.new_colors.length > 0) {
              this.ModelUpdateValueImages(this.modalUpdateValueImages);
            }
          });

          // Reset the form and features array
          this.updateProductForm.reset();
          this.features = [];

          this.modalReference.close();

          // Fetch updated product details
          this.getProductDetails(this.productId);
        },

        error: (error) => {
          this.isLoading = false;
          console.error("Error updating product feature", error);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to update product features. Please try again.",
            confirmButtonText: "OK",
          });
        },
      });
  }

  // Method to update images
  updateImage(): void {
    this.isLoading = true;
    console.log(this.productId);

    const formData = new FormData();
    formData.append("product_id", this.productId.toString());
    this.imagesIds.forEach((id, index) => {
      formData.append(`image_ids[${index}]`, id.toString());
    });

    // File size validation
    const MAX_FILE_SIZE_MB = 1; // Maximum file size in MB
    const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024; // Convert MB to bytes
    const oversizedFiles: string[] = [];

    // Append files if they pass validation
    if (this.files && this.files.length > 0) {
      this.files.forEach((file, index) => {
        if (file.size > MAX_FILE_SIZE_BYTES) {
          oversizedFiles.push(file.name); // Collect names of oversized files
        } else {
          formData.append(`image_files[${index}]`, file, file.name);
        }
      });
    }

    // Handle oversized files
    if (oversizedFiles.length > 0) {
      console.error(
        "The following files exceed the size limit:",
        oversizedFiles
      );
      alert(
        `The following files exceed the size limit of ${MAX_FILE_SIZE_MB} MB:\n${oversizedFiles.join(
          "\n"
        )}`
      );
      this.isLoading = false; // Reset loading state
      return; // Stop the process and avoid sending oversized files to the backend
    }

    // Log FormData for debugging purposes
    formData.forEach((value, key) => {
      console.log(key, value);
    });

    // Start the HTTP request
    this.requestSubscription = this._productServices
      .UpdateProductImage(formData)
      .subscribe(
        (res) => {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Product image updated successfully!",
            showConfirmButton: true,
          }).then(() => {
            // Reset file input and properties after success
            this.files = []; // Clear the files array
            this.fileNames = []; // Clear the fileNames array
            this.updateProductForm.patchValue({ images: [] }); // Reset the form value
            this.modalService.dismissAll();

            // Fetch updated product details
            this.getProductDetails(this.productId);
            this.refreshData();
          });

          console.log(res);
        },
        (error) => {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to update product image. Please try again later.",
            showConfirmButton: true,
          });
          console.log(error);
        }
      );
  }

  // Method Edit Data
  updateData() {
    this.isLoading = true;

    console.log("Product id:", this.productId);
    this.updateProductFormSubmitted = true;

    // Check if the form is invalid
    if (this.updateProductForm.invalid) {
      console.log("Form is invalid");
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill in all the required fields!",
        confirmButtonText: "OK",
      });
      return;
    }

    // Data Payload
    const discountedPrice =
      this.updateProductForm.get("discounted_price")?.value;
    console.log("Discounted Price from form:", discountedPrice);

    // Prepare payload with careful preservation of existing data
    const payload: any = {
      ...this.productData, // Spread existing product data first
      name_en: this.updateProductForm.get("name_en")?.value,
      name_ar: this.updateProductForm.get("name_ar")?.value,
      price: this.updateProductForm.get("product_price")?.value,
      description_ar: this.updateProductForm.get("description_ar")?.value,
      description_en: this.updateProductForm.get("description_en")?.value,
      has_discount: this.has_discount,
      discounted_price: discountedPrice,
      // Preserve the last known refundable status from the original product data
      refundable: this.refundableControl.value,
    };

    // Carefully handle additional features
    // Always include product_additional_features in the payload
    if (this.additional_features && this.additional_features.length > 0) {
      // Filter and map only features with both English and Arabic names
      payload.product_additional_features = this.additional_features
        .filter((feature) => feature.name_en && feature.name_ar)
        .map((feature) => ({
          name_en: feature.name_en,
          name_ar: feature.name_ar,
          value_en: feature.value_en,
          value_ar: feature.value_ar,
        }));
    } else {
      // Explicitly set to an empty array if no features
      payload.product_additional_features = [];
    }

    // Optional: Allow manual refundable status update if needed
    const refundableFormValue = this.updateProductForm.get("refundable")?.value;
    if (refundableFormValue !== null && refundableFormValue !== undefined) {
      payload.refundable = refundableFormValue ? 1 : 0;
    }

    // Data submission
    this._productServices.UpdateProductData(this.productId, payload).subscribe({
      next: (response) => {
        this.isLoading = false;
        console.log("Product updated successfully", response);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Product updated successfully!",
          confirmButtonText: "OK",
        });
        this.modalReference.close();
        this.getProductDetails(this.productId);
        this.refreshData();
      },
      error: (error) => {
        this.isLoading = false;

        console.error("Error updating product", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to update the product. Please try again.",
          confirmButtonText: "OK",
        });
      },
    });
  }

  // refresh Data
  refreshData(): void {
    this.isLoading = true;
    setTimeout(() => {
      this.getProductDetails(this.productId);
      this.isLoading = false;
    }, 300);
  }

  // cancel Request
  cancelRequest(): void {
    this.abortController.abort(); // Cancel the HTTP request
    console.log("Request canceled");
    this.isLoading = false; // Reset loading state

    // Unsubscribe from the ongoing request to stop any further actions in the subscription
    if (this.requestSubscription) {
      this.requestSubscription.unsubscribe();
    }

    Swal.fire({
      icon: "info",
      title: "Request Canceled",
      text: "The image update request has been canceled.",
      showConfirmButton: true,
    });
  }
  // *******************************Update Value Images****************************************
  onFilechange(event: any, index: number) {
    const files = event.target.files;
    const maxFiles = 3; // Maximum number of files allowed
    const maxSize = 1 * 1024 * 1024; // 1MB in bytes

    // Validate file count
    if (files.length > maxFiles) {
      alert(`You can only upload a maximum of ${maxFiles} images.`);
      event.target.value = ""; // Clear the file input
      this.fileNames[index] = ""; // Reset the file name display
      return;
    }

    // Validate file size
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > maxSize) {
        alert(`File "${files[i].name}" exceeds the 1MB size limit.`);
        event.target.value = ""; // Clear the file input
        this.fileNames[index] = ""; // Reset the file name display
        return;
      }
    }

    // Update file names and selected files
    if (files.length > 0) {
      this.fileNames[index] =
        files.length === 1 ? files[0].name : `${files.length} files selected`;
      this.selectedFiles[index] = Array.from(files); // Store the selected files
    } else {
      this.fileNames[index] = "";
      this.selectedFiles[index] = []; // Clear the selected files
    }

    // Update form control validity
    this.updateValueImagesForm.get("image")?.setValue(files);
    this.updateValueImagesForm.get("image")?.updateValueAndValidity();
  }

  ModelUpdateValueImages(modalUpdateValueImages) {
    this.updateValueImagesFormSubmitted = false;
    this.updateValueImagesForm.reset();
    this.modalReference1 = this.modalService.open(modalUpdateValueImages, {
      backdrop: false,
      centered: true,
    });
  }

  // Updata Value Images Method
  UpdateValueImagesMethod(colorIndex: number) {
    this.updateValueImagesFormSubmitted = true;

    // Check if files are selected
    if (!this.selectedFiles[colorIndex]?.length) {
      alert("Please select at least one image.");
      return;
    }
    this.isUploadComplete = false; // اجعل الزر معطلًا أثناء العملية

    // Set loading state
    this.loadingStates[colorIndex] = true;

    const color = this.colorsFeatureValues[colorIndex];
    const formData = new FormData();
    formData.append("product_id", this.productId.toString());
    formData.append("feature_value_id", color.feature_value_id.toString());

    // Append files for the specific color
    this.selectedFiles[colorIndex].forEach((file) => {
      formData.append("images[]", file, file.name);
    });

    // Send to API
    this._productServices.AddFeatureValueImages(formData).subscribe({
      next: (response) => {
        this.loadingStates[colorIndex] = false; // Stop loading
        this.uploadCompleted[colorIndex] = true; // Mark upload as completed
        console.log(
          "Upload successful for color",
          color.feature_value_name,
          response
        );

        // Show success alert
        Swal.fire({
          icon: "success",
          title: "Upload Successful",
          text: `Image uploaded successfully for ${color.feature_value_name}`,
          confirmButtonColor: "#3085d6",
        });
        this.isUploadComplete = this.uploadCompleted.every(
          (status) => status === true
        );
        this.getProductDetails(this.productId);
      },

      error: (error) => {
        this.loadingStates[colorIndex] = false; // Stop loading on error
        console.error(
          "Upload failed for color",
          color.feature_value_name,
          error
        );

        // Show error alert
        Swal.fire({
          icon: "error",
          title: "Upload Failed",
          text: `Failed to upload image for ${color.feature_value_name}. Please try again.`,
          confirmButtonColor: "#d33",
        });
        this.isUploadComplete = false; // إذا فشل أي رفع، لا يمكن تفعيل الزر
      },
    });
  }
    // *******************************Update Colored Images ****************************************


  // remove Colored Images 
  removeColoredImage(skuIndex: number, featureIndex: number, imageIndex: number) {
    // Get the ID of the image before removing it
    const deletedImageId =
      this.productData.product_skus[skuIndex].feature_values[featureIndex].colored_images[imageIndex].id;
  
    // Remove the image from colored_images array
    this.productData.product_skus[skuIndex].feature_values[featureIndex].colored_images.splice(imageIndex, 1);
  
    // Find and remove the corresponding ID from coloredImagesIds
    const idIndex = this.coloredImagesIds.indexOf(Number(deletedImageId));
    if (idIndex !== -1) {
      this.coloredImagesIds.splice(idIndex, 1);
    }
    console.log(this.coloredImagesIds);
  }
  

  // uploaded  cOLORED images
  onColoredFileUpload(event: any, skuIndex: number, featureIndex: number) {
    const files = event.target.files;
    if (!files || files.length === 0) return;
  
    const feature =
      this.productData.product_skus[skuIndex].feature_values[featureIndex];
  
    // Ensure `uploadedFiles`, `fileNames`, and `colored_images` are initialized
    if (!feature.uploadedFiles) {
      feature.uploadedFiles = []; // Initialize if not set
    }
    if (!feature.fileNames) {
      feature.fileNames = []; // Initialize if not set
    }
    if (!feature.colored_images) {
      feature.colored_images = []; // Initialize if not set
    }
  
    // Ensure `newColoredImages` is initialized
    if (!this.newColoredImages) {
      this.newColoredImages = []; // Initialize if not set
    }
  
    // Check if adding new files exceeds the limit of 3 images
    if (feature.uploadedFiles.length + files.length > 3) {
      alert("You can only upload up to 3 images per color.");
      return;
    }
  
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
  
      // Check file size (Max 1MB)
      if (file.size > 1048576) {
        alert(`File ${file.name} is larger than 1MB and cannot be uploaded.`);
        continue; // Skip this file
      }
  
      feature.fileNames.push(file.name); // Store file name
      feature.uploadedFiles.push(file); // Store file in feature object
      this.newColoredImages.push(file); // Store all images globally for API submission
  
      // Log the uploaded file
      console.log("Uploaded File:", file);
      console.log(this.newColoredImages);
  
      const reader = new FileReader();
      reader.onload = (e: any) => {
        feature.colored_images.push(e.target.result); // Add the image URL to `colored_images`
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  }
  
  updateColoredImages() {
    if (!this.newColoredImages || this.newColoredImages.length === 0) {
      alert("No images to upload.");
      this.isLoading = false;
      return;
    }
  
    const formData = new FormData();
    formData.append("product_id", this.productId);
    this.newColoredImages.forEach((file: File) => {
      formData.append("image_files[]", file);
    });
  
    // Call API service
    this._productServices.UpdateColoredImage(formData).subscribe(
      (response) => {
        console.log("Upload Success:", response);
        alert("Images uploaded successfully!");
        this.newColoredImages = []; // Reset after successful upload
      },
      (error) => {
        console.error("Upload Error:", error);
        alert("Failed to upload images.");
      }
    );
  
    this.isLoading = false; 
  }

  
  
  
  
 
}
