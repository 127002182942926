<div class="content-wrapper container-xxl p-0 mt-5">
  <div class="content-body">
    <!-- app e-commerce details start -->
    <section class="app-ecommerce-details">
      <div class="card shipment-card card-style p-5">
        <!-- Product Details starts -->
        <div class="shipment-card-body">
          <div class="row my-2">
            <!-- left Side   -->
            <div
              class="col-12 col-md-5 d-flex align-items-center justify-content-center mb-2 mb-md-0"
            >
              <div class="shipment-card-body">
                <swiper class="swiper-coverflow swiper-container">
                  <ng-container
                    *ngFor="let image of shipmentDetails?.product_images"
                  >
                    <img class="img-fluid" [src]="image" alt="Product Image" />
                  </ng-container>
                </swiper>
              </div>
            </div>

            <!-- Right Info  -->
            <div class="col-12 col-md-7">
              <!-- Client Info  -->
              <div class="p-3 shadow-lg rounded border border-3">
                <!-- <h1 class="text-info">{{ shipmentDetails?.store_name }}</h1><br> -->

                <!-- Store Name -->
                <div class="">
                  <h4 class="card-text item-company">
                    Store Name :
                    <a href="javascript:void(0)" class="company-name text-info">
                      {{ shipmentDetails?.store_name }}
                    </a>
                  </h4>
                </div>
                <br />

                <!-- client Phone -->
                <div>
                  <h4 class="card-text item-company mt-1">
                    Client Phone :
                    <ng-container>
                      <a href="javascript:void(0)" class="text-info mx-2">{{
                        shipmentDetails?.user_phone
                      }}</a>
                    </ng-container>
                  </h4>
                </div>
                <br />
                <!-- client name -->
                <div>
                  <h4 class="card-text item-company mt-1">
                    Client Name :
                    <ng-container>
                      <a href="javascript:void(0)" class="text-info mx-2">{{
                        shipmentDetails?.user_name
                      }}</a>
                    </ng-container>
                  </h4>
                </div>
                <br />

                <!-- shipment id -->
                <div>
                  <h4 class="card-text item-company mt-1">
                    Shipment id :
                    <ng-container>
                      <a href="javascript:void(0)" class="text-info mx-2">{{
                        shipmentDetails?.product_shipment_id
                      }}</a>
                    </ng-container>
                  </h4>
                </div>
              </div>
              <br />

              <!------------------------------------ Product Info  -------------------------->

              <!-- Product Name -->
              <div class="p-3 shadow-lg rounded border border-3">
                <div class="">
                  <h4 class="text-info">
                    <h4 class="">
                      Product Name :
                      <ng-container>
                        <a href="javascript:void(0)" class="text-info mx-2">
                          {{ shipmentDetails?.product_name }}
                        </a>
                      </ng-container>
                    </h4>
                  </h4>
                </div>
                <br />

                <!-- Product Price  -->
                <div>
                  <h4 class="card-text item-company mt-1">
                    Product Price :
                    <ng-container>
                      <a href="javascript:void(0)" class="text-info mx-2">
                        YER {{ shipmentDetails?.product_price }}</a
                      >
                    </ng-container>
                  </h4>
                </div>
                <br />
                <!-- Product status -->
                <div>
                  <h4 class="card-text item-company mt-1">
                    Product status :
                    <ng-container>
                      <a href="javascript:void(0)" class="text-info mx-2">
                        {{ shipmentDetails?.shipment_status }}
                      </a>
                    </ng-container>
                  </h4>
                </div>
                <br />
                <!-- Product Feature -->
                <div *ngIf="shipmentDetails?.feature_values?.length > 0">
                  <h4 class="card-text item-company mt-1">Product Features:</h4>
                  <ul class="list-unstyled text-white mt-1">
                    <li *ngFor="let feature of shipmentDetails?.feature_values">
                      <h6 class="fw-bold">{{ feature?.feature_name }}:</h6>
                      <ng-container
                        *ngIf="
                          feature?.feature_name?.toLowerCase() === 'color';
                          else normalFeature
                        "
                      >
                        <span
                          class="text-white px-2 rounded"
                          [ngStyle]="{
                            'background-color': feature?.feature_value_name,
                            border: '1px solid #fff'
                          }"
                        >
                          {{ feature?.feature_value_name }}
                        </span>
                      </ng-container>
                      <ng-template #normalFeature>
                        <a href="javascript:void(0)" class="text-info mx-2">
                          {{ feature?.feature_value_name }}
                        </a>
                      </ng-template>
                    </li>
                  </ul>
                </div>

                <br />
              </div>

              <!-- Status Action  -->
              <hr />
              <div class="">
                <div class="d-flex order-status">
                  <ng-container *ngIf="shipmentDetails?.shipment_status">
                    <!-- Use ngSwitch to handle different statuses -->
                    <ng-container [ngSwitch]="shipmentDetails.shipment_status">
                      <!-- Case: Preparing -->
                      <ng-container *ngSwitchCase="'Preparing'">
                        <span class="h4 text-info mt-1"
                          >{{ shipmentDetails.shipment_status }} :</span
                        >
                        <!-- Cancel By Customer -->
                        <div
                          class="d-flex flex-column flex-sm-row pt-1 mx-2"
                          (click)="cancelOrder(4)"
                        >
                          <a class="btn btn-outline-danger round" rippleEffect>
                            <span> Cancel By Customer </span>
                          </a>
                        </div>
                        <!-- Cancel By Store -->
                        <div
                          class="d-flex flex-column flex-sm-row pt-1 mx-2"
                          (click)="cancelOrder(6)"
                        >
                          <a class="btn btn-outline-danger round" rippleEffect>
                            <span> Cancel By Store </span>
                          </a>
                        </div>
                      </ng-container>

                      <!-- Case: Pending -->
                      <ng-container *ngSwitchCase="'Pending'">
                        <span class="h4 text-info mt-1"
                          >{{ shipmentDetails.shipment_status }} :</span
                        >
                        <!-- Cancel By Customer -->
                        <div
                          class="d-flex flex-column flex-sm-row pt-1 mx-2"
                          (click)="cancelOrder(4)"
                        >
                          <a class="btn btn-outline-danger round" rippleEffect>
                            <span> Cancel By Customer </span>
                          </a>
                        </div>
                        <!-- Cancel By Store -->
                        <div
                          class="d-flex flex-column flex-sm-row pt-1 mx-2"
                          (click)="cancelOrder(6)"
                        >
                          <a class="btn btn-outline-danger round" rippleEffect>
                            <span> Cancel By Store </span>
                          </a>
                        </div>
                      </ng-container>

                      <!-- Case: On the way -->
                      <ng-container *ngSwitchCase="'On the way'">
                        <span class="h4 text-info mt-1"
                          >{{ shipmentDetails.shipment_status }} :</span
                        >
                        <!-- Order Delivered -->
                        <div
                          class="d-flex flex-column flex-sm-row pt-1 mx-2"
                          (click)="Delivered(3)"
                        >
                          <a class="btn btn-outline-success round" rippleEffect>
                            <span> Order Delivered </span>
                          </a>
                        </div>
                        <!-- Cancel By Customer -->
                        <div
                          class="d-flex flex-column flex-sm-row pt-1 mx-2"
                          (click)="cancelOrder(4)"
                        >
                          <a class="btn btn-outline-danger round" rippleEffect>
                            <span> Cancel By Customer </span>
                          </a>
                        </div>
                      </ng-container>

                      <!-- Case: Delivered To Warehouse -->
                      <ng-container *ngSwitchCase="'Delivered to Warehouse'">
                        <span class="h4 text-info mt-1"
                          >{{ shipmentDetails.shipment_status }} :</span
                        >
                        <!-- On The Way -->
                        <div
                          class="d-flex flex-column flex-sm-row pt-1 mx-2"
                          (click)="onTheWay(2)"
                        >
                          <a class="btn btn-outline-success round" rippleEffect>
                            <span> On The Way </span>
                          </a>
                        </div>
                        <!-- Cancel By Admin -->
                        <div
                          class="d-flex flex-column flex-sm-row pt-1 mx-2"
                          (click)="cancelOrder(8)"
                        >
                          <a class="btn btn-outline-danger round" rippleEffect>
                            <span> Cancel By Admin </span>
                          </a>
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
