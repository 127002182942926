<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- User List -->
    <section class="users-list-wrapper">
      <!-- users filter start -->
      <div class="card card-style">
        <h5 class="card-header font-weight-bold display-4">All Refunds</h5>
        <div class="p-4">
          <nav class="nav nav-pills flex-column flex-sm-row">
            <a *ngFor="let status of refundStatus"
              class="flex-sm-fill text-sm-center order-nav-link mx-2 border border-1 py-3"
              [class.active]="selectedStatus === status.value" 
              (click)="onStatusChange(status.value)"
              href="javascript:void(0)">
              {{ status.name }}
            </a>
          </nav>
        </div>
      </div>

      <div class="card">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="d-flex justify-content-between align-items-center m-1">
              <label class="d-flex align-items-center">
                Show
                <select class="form-control mx-25" [(ngModel)]="selectedOption">
                  <option value="9999999999999999999999">All</option>
                  <option value="2">2</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="250">250</option>
                  <option value="500">500</option>
                  <option value="500">1000</option>
                  <option value="500">2500</option>
                  <option value="500">5000</option>
                  <option value="500">10000</option>
                </select>
                entries</label>
            </div>
          </div>

          <div class="col-md-6 col-12 d-flex justify-content-start justify-content-md-end">
            <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0">
              <label class="d-flex align-items-center ml-1 ml-md-0">Search:<input [(ngModel)]="searchValue"
                  name="searchValue" type="search" class="form-control ml-25" (keyup)="filterUpdate($event)"
                  (search)="filterUpdate($event)" aria-label="" /></label>
            </div>
          </div>
        </div>

        <ngx-datatable [rows]="rows" [rowHeight]="'auto'" class="bootstrap core-bootstrap" [limit]="selectedOption"
          [columnMode]="ColumnMode.force" [headerHeight]="50" [footerHeight]="50" [scrollbarH]="true">
          <!--Refund ID-->
          <!-- <ngx-datatable-column name="ID" prop="id" [width]="100">
            <ng-template let-id="value" let-isDeleted="row.isDeleted" let-isVerified="row.verified"
              ngx-datatable-cell-template>
              <div class="px-3">
                {{ id }}
              </div>
            </ng-template>
          </ngx-datatable-column> -->

          <!-- ID  -->
          <ngx-datatable-column name="ID" prop="id" [width]="100">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
              <div class="d-flex align-items-center" [routerLink]="['/refund-details', row.id]">
                <div class="mr-2">
               
                </div>
                <div class="cell-line-height">
                  <a class="font-medium-1 d-block"><span class="font-weight-bold">{{ row.id}}</span></a>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <!-- Product Shipment Id -->
          <ngx-datatable-column name="Product Shipment Id" prop="product_shipment_id" [width]="220">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
              <div class="d-flex align-items-center" [routerLink]="['/refund-details', row.id]">
                <div class="mr-2">
                  <ngx-avatar class="m-1" src="{{ row.image }}" name="{{ row.product_shipment_id }}" value="28%"
                    size="40" [round]="true">
                  </ngx-avatar>
                </div>
                <div class="cell-line-height">
                  <a class="font-medium-1 d-block"><span class="font-weight-bold">{{
                      row.product_shipment_id
                      }}</span></a>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <!-- Product Id  -->
          <ngx-datatable-column name="Product Id" prop="product_id" [width]="220">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
              <div class="d-flex align-items-center" [routerLink]="['/refund-details', row.id]">
                <div class="mr-2">
                  <ngx-avatar class="m-1" src="{{ row.image }}" name="{{ row.product_id }}" value="28%" size="40"
                    [round]="true">
                  </ngx-avatar>
                </div>
                <div class="cell-line-height">
                  <a class="font-medium-1 d-block">
                    <span class="font-weight-bold">{{ row.product_id }}</span>
                  </a>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <!-- User Id  -->
          <ngx-datatable-column name="User Id" prop="user_id" [width]="220">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
              <div class="d-flex align-items-center" [routerLink]="['/refund-details', row.id]">
                <div class="mr-2">
                  <ngx-avatar class="m-1" src="{{ row.image }}" name="{{ row.user_id }}" value="28%" size="40"
                    [round]="true">
                  </ngx-avatar>
                </div>
                <div class="cell-line-height">
                  <a class="font-medium-1 d-block">
                    <span class="font-weight-bold">{{ row.user_id }}</span>
                  </a>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <!--reason -->
          <ngx-datatable-column name="reason" prop="reason" [width]="220">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
              <div class="d-flex align-items-center" [routerLink]="['/refund-details', row.id]">
                <div class="mr-2">
                  <ngx-avatar class="m-1" src="{{ row.image }}" name="{{ row.reason }}" value="28%" size="40"
                    [round]="true">
                  </ngx-avatar>
                </div>
                <div class="cell-line-height">
                  <a class="font-medium-1 d-block"><span class="font-weight-bold">{{ row.reason }}</span></a>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <!-- Refund Status Column -->
          <ngx-datatable-column name="Refund Status" prop="refund_status" [width]="150">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <span
                class="badge text-uppercase"
                [ngClass]="{
                  'badge-success': value === 'accepted',
                  'badge-danger': value === 'rejected' || value === 'rejected by delivery',
                  'badge-info': value === 'wait for delivery',
                  'badge-warning': value === 'on the way'
                }"
              >
                {{ value }}
              </span>
            </ng-template>
          </ngx-datatable-column>
          

          <!-- Refunded Method Column -->
          <ngx-datatable-column name="Refunded" prop="refunded" [width]="150">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <span class="text-capitalize">{{ value }}</span>
            </ng-template>
          </ngx-datatable-column>

          <!-- Wallet Number Column -->
          <ngx-datatable-column name="Wallet Number" prop="wallet_number" [width]="180">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <span class="font-weight-bold">{{ value }}</span>
            </ng-template>
          </ngx-datatable-column>

          <!-- Images Column -->
          <!-- <ngx-datatable-column name="Images" [width]="200">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="d-flex">
                <img *ngFor="let img of row.images; let i = index" [src]="img" alt="Refund Image {{ i + 1 }}"
                  class="rounded mr-1" width="40" height="40" />
              </div>
            </ng-template>
          </ngx-datatable-column> -->

          <!--Refund Actions-->
          <ngx-datatable-column name="Actions" [width]="100" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
              <div ngbDropdown container="body">
                <button ngbDropdownToggle type="button" class="btn icon-btn btn-sm hide-arrow text-danger" rippleEffect
                  aria-label="More options" title="More options">
                  <span [data-feather]="'more-vertical'" class="cursor-pointer"></span>
                </button>

                <div ngbDropdownMenu>
                  <!-- Show Accept Wait For Delivery and Reject if refund_status is 'pending' -->
                  <ng-container *ngIf="row.refund_status === 'pending'">
                    <a ngbDropdownItem (click)="changestatus(2, row.id, row.product_shipment_id)">
                      <span [data-feather]="'clock'" size="16" class="mr-50 text-info"></span>
                      Accept Wait For Delivery
                    </a>
                    <a ngbDropdownItem (click)="changestatus(6, row.id, row.product_shipment_id)">
                      <span [data-feather]="'x-circle'" size="16" class="mr-50 text-danger"></span>
                      Reject
                    </a>
                  </ng-container>

                  <!-- Show On The Way if refund_status is 'wait for delivery' -->
                  <ng-container *ngIf="row.refund_status === 'wait for delivery'">
                    <a ngbDropdownItem (click)="changestatus(3, row.id, row.product_shipment_id)">
                      <span [data-feather]="'phone-forwarded'" size="16" class="mr-50 text-warning"></span>
                      On The Way
                    </a>
                  </ng-container>

                  <!-- Show Accepted and Rejected By Delivery if refund_status is 'On The Way' -->
                  <ng-container *ngIf="row.refund_status === 'on the way'">
                    <a ngbDropdownItem (click)="changestatus(4, row.id, row.product_shipment_id)">
                      <span [data-feather]="'check-circle'" size="16" class="mr-50 text-success"></span>
                      Accepted
                    </a>
                    <a ngbDropdownItem (click)="changestatus(5, row.id, row.product_shipment_id)">
                      <span [data-feather]="'alert-circle'" size="16" class="mr-50 text-danger"></span>
                      Rejected By Delivery
                    </a>
                  </ng-container>

                  <!-- Always show Details -->
                  <a ngbDropdownItem [routerLink]="['/refund-details', row.id]">
                    <span [data-feather]="'file-text'" size="16" class="mr-50 text-warning"></span>
                    Details
                  </a>

<!--                   
                  <button class="btn btn-outline-primary" (click)="openModal(modalSelectDelivery, row.id)">
                    <span [data-feather]="'truck'" size="16" class="mr-50 text-primary"></span>
                    Assign Delivery
                  </button> -->

                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </section>
  </div>
</div>


<!-- Model Select Dekivery  -->
<ng-template #modalSelectDelivery let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalCenterTitle">Select Delivery</h5>
    <!-- <button id="clo" type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button> -->
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <form id="fo" [formGroup]="SelectDeliveryForm" (ngSubmit)="assignDeliveryUser()">
      <!-- Select Dropdown -->
      <div class="form-group">
        <label for="deliveryOption">Delivery Option</label>
        <select id="deliveryOption" class="form-control" formControlName="deliveryOption">
          <option *ngFor="let Deivery of DeliveryUser" [value]="Deivery.id">
            {{ Deivery.firstName }} {{ Deivery.lastName }}
          </option>
        </select>
        <!-- Validation Error Message -->
        <div *ngIf="submitted && f.deliveryOption.errors" class="text-danger">
          <div *ngIf="f.deliveryOption.errors.required">Delivery option is required.</div>
        </div>
      </div>

      <!-- Textarea -->
      <div class="form-group">
        <label for="additionalNotes">Additional Notes</label>
        <textarea id="additionalNotes" class="form-control" formControlName="additionalNotes" rows="3"></textarea>
        <!-- Validation Error Message -->
        <div *ngIf="submitted && f.additionalNotes.errors" class="text-danger">
          <div *ngIf="f.additionalNotes.errors.required">Additional notes are required.</div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-success round ml-1" (click)="assignDeliveryUser()"
      [disabled]="SelectDeliveryForm.invalid || isLoading">
      <ng-container *ngIf="!isLoading"> Add </ng-container>
      <ng-container *ngIf="isLoading">
        <i class="fa fa-spinner fa-spin"></i>
        Adding...
      </ng-container>
    </button>

    <button type="button" class="btn btn-outline-danger round ml-1" (click)="modal.close('Accept click')">
      Close
    </button>
  </div>
</ng-template>












<!-- ///////////////////////////////loader//////////////////// -->
<div *ngIf="isLoading" id="loader-wrapper">
  <div id="loader"></div>
  <span *ngIf="isLoading" class="loader"></span>
  <div class="loader-section section-left"></div>
  <div class="loader-section section-right"></div>
</div>
<!-- ///////////////////////////////loader//////////////////// -->