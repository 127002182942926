import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AllReviewsService {
  public onUserListChanged: BehaviorSubject<any>;

  constructor(private _httpClient: HttpClient) { 
     this.onUserListChanged = new BehaviorSubject({});
  }


  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getAllReviews()]).then(() => {
        resolve();
      }, reject);
    });
  }


  //Get AllReviews
  getAllReviews(){
    return this._httpClient.get(`${environment.apiUrl}admin/get-new-reviews`);
  }
  //Get Accepted Reviews
  getAcceptedReviews(){
    return this._httpClient.get(`${environment.apiUrl}admin/get-accepted-reviews`);
  }
  //Change Review
  changeStatus(id:number ,data:any){
    return this._httpClient.put(`${environment.apiUrl}admin/change-review-status/${id}` ,data)
  }

  deleteReview(id:number ){
    return this._httpClient.delete(`${environment.apiUrl}admin/delete-review/${id}`)
  }














}
