import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { environment } from "environments/environment";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})

export class RefundService {
  public rows: any;
  public onUserListChanged: BehaviorSubject<any>;

  constructor(private _httpClient: HttpClient) {
    // Set the defaults
    this.onUserListChanged = new BehaviorSubject({});
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.allPendingRefunds()]).then(() => {
        resolve();
      }, reject);
    });
  }


  // pending_Refunds
  allPendingRefunds() {
    return this._httpClient.get(`${environment.apiUrl}admin/get-all-refunds`)
  }

  // Wait For Delivery 
  allWaitForDelivered() {
    return this._httpClient.get(`${environment.apiUrl}admin/get-waitfordelivery`)
  }
  // On The way Refunds
  allOnTheWayRefunds() {
    return this._httpClient.get(`${environment.apiUrl}admin/get-ontheway`)
  }

  //Reject By Delivery
  allRejectByDelivery() {
    return this._httpClient.get(`${environment.apiUrl}admin/get-rejectedbydelivery`)
  }

  // All Rejected Refunds
  allRejectedRefunds() {
    return this._httpClient.get(`${environment.apiUrl}admin/get-rejected`)
  }

  // All Accept Refunds
  allAcceptRefunds() {
    return this._httpClient.get(`${environment.apiUrl}admin/get-accepted`)
  }


  allDeliveryUsers(){
    return this._httpClient.get(`${environment.apiUrl}admin/get-delivery-users`)
  }


  // Get Refund By Id
  refundById(id: number) {
    return this._httpClient.get(`${environment.apiUrl}admin/getRefundById/${id}`)
  }

  //Asign Devlivery
  assignDelivery(data){
    return this._httpClient.post(`${environment.apiUrl}admin/assign-delivery`, data)
  }

  // Cahnge Rfund Status 
  changeRefundStatus(data: any) {
    return this._httpClient.post(`${environment.apiUrl}admin/update-refund-status`, data)
  }



}
