<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- User List -->
    <section class="users-list-wrapper">
      <!-- users filter start -->
      <div class="card tableeeeeee p-2">
        <h5 class="card-header h2">Shipments Filter</h5>
        <div class="p-4">
          <nav class="nav nav-pills flex-column flex-sm-row">
            <a
              *ngFor="let status of shipmentStatus"
              class="flex-sm-fill text-sm-center nav-link mx-2 text-white border border-1 py-3"
              [class.active]="selectedStatus === status.value"
              (click)="onStatusChange(status.value)"
              href="javascript:void(0)"
            >
              {{ status.name }}
            </a>
          </nav>
        </div>
      </div>

      <div class="card tableeeeeee p-3">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="d-flex justify-content-between align-items-center m-1">
              <label class="d-flex align-items-center">
                Show
                <select class="form-control mx-25" [(ngModel)]="selectedOption">
                  <option value="9999999999999999999999">All</option>
                  <option value="2">2</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="250">250</option>
                  <option value="500">500</option>
                  <option value="500">1000</option>
                  <option value="500">2500</option>
                  <option value="500">5000</option>
                  <option value="500">10000</option>
                </select>
                entries</label
              >
            </div>
          </div>
          <div
            class="col-md-6 col-12 d-flex justify-content-start justify-content-md-end"
          >
            <div
              class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0"
            >
              <label class="d-flex align-items-center ml-1 ml-md-0">
                <span class="mr-2">Search:</span>
                <input
                  [(ngModel)]="searchValue"
                  name="searchValue"
                  type="search"
                  class="form-control ml-2"
                  (keyup)="filterUpdate($event)"
                  (search)="filterUpdate($event)"
                />
              </label>
            </div>
          </div>
        </div>

        <ngx-datatable
          [rows]="rows"
          [rowHeight]="'auto'"
          class="bootstrap core-bootstrap"
          [limit]="selectedOption"
          [columnMode]="ColumnMode.force"
          [headerHeight]="50"
          [footerHeight]="50"
          [scrollbarH]="true"
        >
          <!-- Id  -->
          <ngx-datatable-column name="Order ID" prop="order_id" [width]="100">
            <ng-template
              let-id="value"
              let-isDeleted="row.isDeleted"
              let-isVerified="row.verified"
              ngx-datatable-cell-template
            >
              <div class="badge badge-pill badge-glow px-3">
                {{ id }}
              </div>
            </ng-template>
          </ngx-datatable-column>

          <!-- Shipment UID -->
          <ngx-datatable-column
            name="Shipment UID"
            prop="shipment_uid"
            [width]="150"
          ></ngx-datatable-column>

          <!--Product Shipment ID -->
          <ngx-datatable-column
            name="Product Shipment ID"
            prop="product_shipment_id"
            [width]="150"
          ></ngx-datatable-column>

          <!-- store_name -->
          <ngx-datatable-column name="Store" prop="store_name" [width]="220">
            <ng-template
              let-row="row"
              let-name="value"
              ngx-datatable-cell-template
            >
              <div
                class="d-flex align-items-center"
                [routerLink]="'/shipment-details/' + row.product_shipment_id"
              >
                <div class="mr-2">
                  <ngx-avatar
                    class="m-1"
                    src="{{ row.store_image }}"
                    name="{{ row.store_name }}"
                    value="28%"
                    size="40"
                    [round]="true"
                  >
                  </ngx-avatar>
                </div>
                <div class="cell-line-height">
                  <a class="font-medium-1 d-block"
                    ><span class="font-weight-bold">{{
                      row.store_name
                    }}</span></a
                  >
                  <span class="text-secondary font-weight-bold">
                    📞{{ row.store_phone }}</span
                  >
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <!-- client_name -->
          <ngx-datatable-column name="Client" prop="client_name" [width]="220">
            <ng-template
              let-row="row"
              let-name="value"
              ngx-datatable-cell-template
            >
              <div
                class="d-flex align-items-center"
                [routerLink]="'/shipment-details/' + row.product_shipment_id"
              >
                <div class="mr-2">
                  <ngx-avatar
                    class="m-1"
                    src="{{ row.client_image }}"
                    name="{{ row.client_name }}"
                    value="28%"
                    size="40"
                    [round]="true"
                  >
                  </ngx-avatar>
                </div>
                <div class="cell-line-height">
                  <a class="font-medium-1 d-block"
                    ><span class="font-weight-bold">{{
                      row.client_name
                    }}</span></a
                  >
                  <span class="text-secondary font-weight-bold">
                    📞{{ row.client_phone }}</span
                  >
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <!-- order_status -->
          <ngx-datatable-column name="status" prop="order_status" [width]="150">
            <ng-template let-status="value" ngx-datatable-cell-template>
              <div
                class="badge badge-pill"
                [ngClass]="{
                  'badge-light-primary': order_status == 'New',
                  'badge-light-secondary': order_status == 'On the way',
                  'badge-light-success': order_status == 'Delivered',
                  'badge-light-warning': order_status == 'Not Delivered',
                  'badge-light-dark': order_status == 'Canceled By User',
                  'badge-light-danger': order_status == 'Preparing',
                  'badge-light-success':
                    order_status == 'Delivered To Warehouse'
                }"
              >
                {{ order_status }}
              </div>
            </ng-template>
          </ngx-datatable-column>

          <!-- products_count -->
          <ngx-datatable-column
            name="Products Count "
            prop="products_count"
            [width]="180"
          >
            <ng-template let-met="value" ngx-datatable-cell-template>
              <div class="badge badge-pill badge-glow badge-dark p-2">
                {{ met }}
              </div>
            </ng-template>
          </ngx-datatable-column>

          <!-- placed_on -->
          <ngx-datatable-column name="Placed At" prop="placed_on" [width]="100">
            <ng-template let-met="value" ngx-datatable-cell-template>
              <div class="badge badge-pill badge-glow badge-info p-2">
                {{ met }}
              </div>
            </ng-template>
          </ngx-datatable-column>

          <!-- order_summary -->
          <ngx-datatable-column
            name="Order Summary"
            prop="order_summary"
            [width]="250"
          >
            <ng-template let-order="value" ngx-datatable-cell-template>
              <div class="d-block font-weight-bold my-1">
                Price Before Discount :
                <span class="text-info">
                  {{ order.price_before_discount }}
                </span>
              </div>
              <div class="d-block font-weight-bold my-1">
                Delivery :
                <span class="text-info"> {{ order.delivery }} </span>
              </div>
              <div class="d-block font-weight-bold my-1">
                discount :
                <span class="text-info"> {{ order.discount }}</span>
              </div>
              <div class="d-block font-weight-bold my-1">
                Price After Discount :
                <span class="text-info"> {{ order.price_after_discount }}</span>
              </div>
              <hr class="d-block border-secondary" />
              <div class="d-block font-weight-bold my-1">
                Total :
                <span class="text-info"> {{ order.total }} </span>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <!-- Actions -->
          <ngx-datatable-column name="Actions" [width]="100" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
              <div ngbDropdown container="body">
                <button
                  ngbDropdownToggle
                  type="button"
                  class="btn icon-btn btn-sm hide-arrow text-danger"
                  rippleEffect
                >
                  <span
                    [data-feather]="'more-vertical'"
                    [class]="'cursor-pointer'"
                  ></span>
                </button>
                <div ngbDropdownMenu>
                  <a
                    ngbDropdownItem
                    [routerLink]="'/order-details/' + row.order_id"
                    (click)="navigate(row.order_id)"
                  >
                    <span
                      [data-feather]="'file-text'"
                      [size]="16"
                      [class]="'mr-50 text-info'"
                    ></span>
                    Details
                  </a>
                  <a
                    ngbDropdownItem
                    (click)="modalOpenVC(modalVC, row.shipment_id)"
                    ><span
                      [data-feather]="'edit'"
                      [size]="16"
                      [class]="'mr-50 text-warning'"
                    ></span>
                    Change Status</a
                  >
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </section>
  </div>
</div>
<!-- ///////////////////////////////loader//////////////////// -->
<div *ngIf="isLoading" id="loader-wrapper">
  <div id="loader"></div>
  <span *ngIf="loaders" class="loader"></span>
  <div class="loader-section section-left"></div>
  <div class="loader-section section-right"></div>
</div>

<ng-template #modalVC let-modal>
  <div class="modal-header bg-light-warning">
    <h5 class="modal-title" id="exampleModalCenterTitle">
      Change Order Status
    </h5>
    <button
      id="clo"
      type="button"
      class="close"
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <form
      id="fo"
      [formGroup]="ReactiveOrderStatusForm"
      (ngSubmit)="ReactiveUDFormOnSubmit()"
    >
      <div class="alert alert-warning" role="alert">
        <div class="d-flex">
          <i class="fi-alert-circle me-2 me-sm-3"></i>
          <p class="fs-sm mb-1">
            Warning: The status of the order should not be changed to When there
            is a problem !
          </p>
        </div>
      </div>
      <ng-select
        class="ng-select-size-lg mt-2"
        [items]="OrderStatuss"
        [multiple]="true"
        [maxSelectedItems]="1"
        [closeOnSelect]="true"
        [searchable]="true"
        bindLabel="name"
        placeholder="Order Status"
        formControlName="change_id"
        [ngClass]="{
          'is-invalid':
            ReactiveOrderStatusFormSubmitted && ReactiveOSForm.change_id.errors
        }"
      >
        <ng-template ng-label-tmp let-item="item" let-clear="clear">
          <span class="ng-value-label"> {{ item.name }}</span>
          <span
            class="ng-value-icon right"
            (click)="clear(item)"
            aria-hidden="true"
            >×</span
          >
        </ng-template>
      </ng-select>
      <div
        *ngIf="
          ReactiveOrderStatusFormSubmitted && ReactiveOSForm.change_id.errors
        "
      >
        <div *ngIf="ReactiveOSForm.change_id.errors.required">
          Status is required 🫠
        </div>
      </div>
      <!--/ Multi Select Size large -->
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-warning round ml-1"
      (click)="ReactiveOSFormOnSubmit()"
      rippleEffect
    >
      Change
    </button>
    <button
      type="button"
      class="btn btn-outline-danger round ml-1"
      (click)="modal.close('Accept click')"
      rippleEffect
    >
      Close
    </button>
  </div>
</ng-template>
