import {
  Product,
  Subcategory,
} from "./../../../main/sample/modules/store-profile";
import { ProductsService } from "./../products.service";
import Swal from "sweetalert2";
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { catchError, finalize } from "rxjs/operators";
import { Observable } from "rxjs";
import { Router, ResolveEnd } from "@angular/router";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AllStores } from "app/main/sample/modules/product";
import { timeout } from "rxjs/operators";
import { NavigationServiceService } from "app/components/Shared Servece/navigation-service.service";

@Component({
  selector: "app-product-list",
  templateUrl: "./product-list.component.html",
  styleUrls: ["./product-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: { class: "ecommerce-application" },
})
export class ProductListComponent implements OnInit {
  @ViewChild("modalAddValueImages") modalAddValueImages!: TemplateRef<any>;

  public isLoading = false;
  public contentHeader: object;
  public rows;
  public data;
  public subcategories;
  public SecSub;
  public SecondSubcategories;
  public shopSidebarToggle = false;
  public shopSidebarReset = false;
  public gridViewRef = true;
  public wishlist;
  public cartList;
  public createProductForm: FormGroup;
  public createProductFormSubmitted = false;
  public modalRefereence;
  public selectedCategoryID;
  public Features;
  public SubFeatures;
  public FeaturesByThird;
  public SubcategoryId;
  public modalReference;
  public modalReference1;
  public files: File[] = [];
  public fileNames: string[] = [];
  public file: File | null = null;
  public product_has_discount;
  public allStores: any;
  public firstFeature: string;
  public secondFeature: string;
  public price: number;
  public quantity: number;
  public showSecondSubCategory: boolean = false;
  public features = [
    { firstFeature: "", secondFeature: "", quantity: "", price: "" },
  ];
  public additional_features = [
    { name_en: "", name_ar: "", value_en: "", value_ar: "" },
  ];

  // Pagination
  public page: number = 1;
  public pageSize: number = 9;
  public totalProducts: number;
  searchText: string = "";
  public displayedProducts: any[] = [];
  public limit = 9;
  public products: any;

  // Add Feature Value Images Form
  addValueImagesForm: FormGroup;
  addValueImagesFormSubmitted = false;
  public colorsFeatureValues: any[];
  public productId: number;
  selectedFiles: any[] = [];
  fileName: string = "";
  loadingStates: boolean[] = [];
  isClicked: boolean[] = []; // Initialize this array based on your needs
  uploadCompleted: boolean[] = []; // Tracks if upload is completed for each color
  isUploadComplete: boolean = false; // حالة الزر
  isRefundable: boolean = false;
  public product: any;

  constructor(
    private _productServices: ProductsService,
    private router: Router,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private navigationService: NavigationServiceService // Inject NavigationService here
  ) {
    // create Product Form
    this.createProductForm = this.fb.group({
      store_id: [null, Validators.required],
      selectedCategoryId: [null, Validators.required],
      selectedSubCategoryId: [null, Validators.required],
      product_category_id: [null], //third level
      name_en: ["", [Validators.required, Validators.minLength(2)]],
      name_ar: ["", [Validators.required, Validators.minLength(2)]],
      product_price: [null, [Validators.required]],
      discounted_price: [null, Validators.required],
      description_en: ["", [Validators.required, Validators.minLength(2)]],
      description_ar: ["", [Validators.required, Validators.minLength(2)]],
      images: [null, Validators.required],
      refundable: [0], // Set a default value of 0
    });

    // Add Feature Value Images Form
    this.addValueImagesForm = this.fb.group({
      image: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.getAllStores();
    this.loadProducts(this.limit, this.page);
    this.loadingStates = new Array(this.colorsFeatureValues.length).fill(false);
    this.toggleRefundable(event);
  }

 

  toggle() {
    this.showSecondSubCategory = !this.showSecondSubCategory;
  }

  toggleRefundable(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    this.isRefundable = checked; // Update standalone model
    this.createProductForm.patchValue({ refundable: checked ? 1 : 0 }); // Update form value to 1 or 0
    console.log("Refundable toggled:", checked, "Form value:", this.createProductForm.get("refundable").value);
  }

  addFeature() {
    this.features.push({
      firstFeature: "",
      secondFeature: "",
      price: "",
      quantity: "",
    });
  }

  deleteFeature(id) {
    for (let i = 0; i < this.features.length; i++) {
      if (this.features.indexOf(this.features[i]) === id) {
        this.features.splice(i, 1);
        break;
      }
    }
  }

  addAdditionalFeature() {
    this.additional_features.push({
      name_en: "",
      name_ar: "",
      value_en: "",
      value_ar: "",
    });
  }

  deleteAdditionalFeature(id) {
    for (let i = 0; i < this.additional_features.length; i++) {
      if (
        this.additional_features.indexOf(this.additional_features[i]) === id
      ) {
        this.additional_features.splice(i, 1);
        break;
      }
    }
  }

  /**
   * Update to List View
   */
  listView() {
    this.gridViewRef = false;
  }

  /**
   * Update to Grid View
   */
  gridView() {
    this.gridViewRef = true;
  }

  //Upload Excl/
  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      // console.log('Selected file:', file);
      this._productServices.uploadFile(file).subscribe(
        (response) => {
          if (response) {
            // console.log('File successfully uploaded!', response);
            Swal.fire("Success", "File successfully uploaded!", "success");
          }
        },
        (error) => {
          console.log("File doesnot upload!", error);
          Swal.fire("success", "File doesnot upload!", "success");
        }
      );
    }
  }

  // get All Product Method
  loadProducts(limit: number, page: number) {
    this.isLoading = true;

    this._productServices.GetAllProductsByLimited(limit, page).subscribe(
      (res: any) => {
        this.products = res.data;
        this.totalProducts = res.total;
        if (res.data && res.data.length > 0) {
          // console.log('Product By Pagination:', this.products);
          this.isLoading = false;
        } else {
          Swal.fire({
            position: "center",
            icon: "info",
            title: "There Are No Products Added Yet",
            showConfirmButton: true,
          });
        }
      },
      (er: any) => {
        console.log(er);
        this.isLoading = false;
      }
    );
  }

  // refreshData
  refreshData(): void {
    this.isLoading = true;
    // console.log(this.limit, this.page);

    setTimeout(() => {
      this.isLoading = false;
      this.loadProducts(this.limit, this.page);
    }, 1000);
  }

  changePage(page) {
    // console.log(page);
    this.page = page;
    this.loadProducts(this.limit, this.page);
  }

  getAllStores(): void {
    this._productServices.viewAllStores().subscribe(
      (res) => {
        this.allStores = res;
        // console.log('Stores fetched successfully:', this.allStores);
      },
      (error) => {
        console.error("Error fetching stores:", error);
      }
    );
  }

  onStoreSelect(storeId): void {
    // console.log('Selected store ID:', storeId);

    this._productServices.AllCategoryById(storeId).subscribe(
      (res: any) => {
        this.rows = res;
        // console.log("Categories :",this.rows);
      },
      (er: any) => {
        console.log(er);
      }
    );
  }

  getSubCategory(id: number) {
    // console.log("Category id :" , id);

    this._productServices.getSubCategory(id).subscribe(
      (res: any) => {
        this.data = res;
        this.subcategories = this.data[0].subcategories;
        // console.log("SubCategory :", this.data[0].subcategories);
      },
      (er: any) => {
        console.log(er);
      }
    );
  }

  getSecondSub(id: number) {
    this._productServices.getSubCategory(id).subscribe(
      (res: any) => {
        this.SecSub = res;
        this.SecondSubcategories = this.SecSub[0].subcategories;
        // console.log("Third Level :" , this.SecSub[0].subcategories);
      },
      (er: any) => {
        console.log(er);
      }
    );
  }

  getSubFeatures(id: number) {
    // console.log("SecSub id :",id);

    this._productServices.getFeatures(id).subscribe(
      (res: any) => {
        this.Features = res;
        // console.log(this.Features);

        // console.log(this.Features.features);
        this.SubFeatures = this.Features.features;
        // console.log(this.SubFeatures);
      },
      (er: any) => {
        console.log(er);
      }
    );
  }

  // photo of Product
  onFileUpload(event: any) {
    const files = event.target.files;
    // console.log(files);

    if (files && files.length > 0) {
      this.files = Array.from(files);
      this.fileNames = this.files.map((file) => file.name);
      // console.log("photos : " , this.fileNames);
      this.createProductForm.patchValue({
        images: this.files,
      });
      this.createProductForm.get("images")?.updateValueAndValidity();
    }
  }

  // Model Add Product
  ModelAddNewProduct(modalAddProduct) {
    // this.features=[{ firstFeature: '', secondFeature: '' , quantity:'' , price:'' }]
    this.createProductFormSubmitted = false;
    this.createProductForm.reset();
    this.modalReference = this.modalService.open(modalAddProduct, {
      backdrop: false,
      centered: true,
    });
  }

  // ModelAddValueImages
  ModelAddValueImages(modalAddValueImages) {
    this.addValueImagesFormSubmitted = false;
    this.addValueImagesForm.reset();
    this.modalReference1 = this.modalService.open(modalAddValueImages, {
      backdrop: false,
      centered: true,
    });
  }

  //  Add Product Method
  createProductFormMethod() {
    this.isLoading = true;
  
    // Ensure the refundable value is always a number (0 or 1)
    let refundableValue = this.createProductForm.get('refundable').value;
    
    // Check and convert refundable value
    if (refundableValue === null || refundableValue === undefined) {
      refundableValue = 0; // Default to 0 if null/undefined
    }
  
    // Ensure it's a number, converting from string if necessary
    refundableValue = Number(refundableValue) ? 1 : 0;
  
    // Update the form control with the standardized value
    this.createProductForm.patchValue({ 
      refundable: refundableValue 
    });


    const startTime = new Date().getTime();
  
    this.createProductFormSubmitted = true;
    if (this.createProductForm.invalid) {
      return;
    }
  
    // Discounted Price
    if (this.createProductForm.get("discounted_price").value > 0) {
      this.product_has_discount = 1;
    } else {
      this.product_has_discount = 0;
    }
  
    // Check for files and their size limit
    const maxFileSize = 1 * 1024 * 1024; // 1 MB in bytes
    const validFiles: File[] = [];
    if (this.files && this.files.length > 0) {
      // Filter out files larger than 1 MB
      this.files.forEach((file) => {
        if (file.size <= maxFileSize) {
          validFiles.push(file);
        } else {
          alert(`File "${file.name}" is too large. Maximum size is 1 MB.`);
        }
      });
    }
  
    // If no valid files, prevent form submission
    if (validFiles.length === 0) {
      this.isLoading = false;
      return;
    }
  
    const images = [];
    images.push(...validFiles);
  
    const transformedFeatures = this.features.map((feature) => ({
      feature_values_ids: [feature.firstFeature, feature.secondFeature],
      price: feature.price,
      quantity: feature.quantity,
    }));
  
    const transformAdditionalFeaterus = this.additional_features
      .map((additional) => {
        const feature = {
          ...(additional.name_ar ? { name_ar: additional.name_ar } : {}),
          ...(additional.name_en ? { name_en: additional.name_en } : {}),
          ...(additional.value_en ? { value_en: additional.value_en } : {}),
          ...(additional.value_ar ? { value_ar: additional.value_ar } : {}),
        };
        if (Object.keys(feature).length > 0) {
          return feature;
        }
        return null;
      })
      .filter((feature) => feature !== null);
  
    const productCategoryId = this.showSecondSubCategory
      ? this.createProductForm.get("product_category_id").value
      : this.createProductForm.get("selectedSubCategoryId").value;
  
    let formData = new FormData();
  
    // Append all the form data as before
    formData.append("product_category_id", productCategoryId);
    formData.append("store_id", this.createProductForm.get("store_id").value);
    formData.append("name_en", this.createProductForm.get("name_en").value);
    formData.append("name_ar", this.createProductForm.get("name_ar").value);
    formData.append("product_price", this.createProductForm.get("product_price").value);
    formData.append("discounted_price", this.createProductForm.get("discounted_price").value);
    formData.append("description_en", this.createProductForm.get("description_en").value);
    formData.append("description_ar", this.createProductForm.get("description_ar").value);
    formData.append("product_has_discount", String(this.product_has_discount));
    formData.append("refundable", String(this.createProductForm.get("refundable").value));
    if (this.createProductForm.get("refundable").value === null) {
      this.createProductForm.patchValue({ refundable: 0 });
    }
    transformedFeatures.forEach((feature, index) => {
      if (feature.feature_values_ids && feature.feature_values_ids.length > 0) {
        feature.feature_values_ids.forEach((id, subIndex) => {
          if (id !== null && id !== undefined && id !== "") {
            formData.append(
              `features[${index}][feature_values_ids][${subIndex}]`,
              String(id)
            );
          }
        });
      }
      if (
        feature.price !== null &&
        feature.price !== undefined &&
        feature.price !== ""
      ) {
        formData.append(`features[${index}][price]`, String(feature.price));
      }
  
      if (
        feature.quantity !== null &&
        feature.quantity !== undefined &&
        feature.quantity !== ""
      ) {
        formData.append(
          `features[${index}][quantity]`,
          String(feature.quantity)
        );
      }
    });
  
    transformAdditionalFeaterus.forEach((additionalFeature, index) => {
      formData.append(
        `product_additional_features[${index}][name_ar]`,
        additionalFeature.name_ar
      );
      formData.append(
        `product_additional_features[${index}][name_en]`,
        additionalFeature.name_en
      );
      formData.append(
        `product_additional_features[${index}][value_ar]`,
        additionalFeature.value_ar
      );
      formData.append(
        `product_additional_features[${index}][value_en]`,
        additionalFeature.value_en
      );
    });
  
    if (this.files && this.files.length > 0) {
      this.files.forEach((file, index) => {
        formData.append(`images[${index}]`, file, file.name);
      });
    }
  
    // Log all FormData values
    console.log("FormData values:");
    for (const pair of (formData as any).entries()) {
      console.log(pair[0], pair[1]);
    }
  
    this._productServices
      .AddNewProduct(formData)
      .pipe(timeout(60000))
      .subscribe({
        next: (res: any) => {
          this.isLoading = false;
          const endTime = new Date().getTime();
          const duration = endTime - startTime;
          console.log(res);
          this.modalReference.close();
  
          this.colorsFeatureValues = res.color_feature_values;
          this.productId = res.product_id;
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Product added successfully",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            if (
              res.color_feature_values &&
              res.color_feature_values.length > 0
            ) {
              this.ModelAddValueImages(this.modalAddValueImages);
            }
          });
  
          this.createProductForm.reset();
          this.createProductFormSubmitted = false;
          this.createProductForm.patchValue({
            product_category_id: productCategoryId,
          });
          this.files = [];
          this.fileNames = [];
          (document.getElementById("fileInput") as HTMLInputElement).value = ""; // Reset the file input element
  
          this.features = this.features.map(() => ({
            firstFeature: "",
            secondFeature: "",
            price: "",
            quantity: "",
          }));
  
          this.additional_features = [
            { name_en: "", name_ar: "", value_en: "", value_ar: "" },
          ];
        },
  
        error: (er: any) => {
          this.isLoading = false;
          console.log(er.error.message);
          let errorMessage =
            er.error.message || "The request timed out. Please try again.";
          if (er.name === "TimeoutError") {
            Swal.fire({
              position: "center",
              icon: "warning",
              title: "The request timed out. Please try again.",
              showConfirmButton: true,
              confirmButtonText: "OK",
            });
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: errorMessage,
              timer: 1500,
              showConfirmButton: true,
              confirmButtonText: "OK",
            });
          }
        },
      });
  }

  // Handle file selection
  onFilechange(event: any, index: number) {
    const files = event.target.files;
    const maxFiles = 3; // Maximum number of files allowed
    const maxSize = 1 * 1024 * 1024; // 1MB in bytes

    // Validate file count
    if (files.length > maxFiles) {
      alert(`You can only upload a maximum of ${maxFiles} images.`);
      event.target.value = ""; // Clear the file input
      this.fileNames[index] = ""; // Reset the file name display
      return;
    }

    // Validate file size
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > maxSize) {
        alert(`File "${files[i].name}" exceeds the 1MB size limit.`);
        event.target.value = ""; // Clear the file input
        this.fileNames[index] = ""; // Reset the file name display
        return;
      }
    }

    // Update file names and selected files
    if (files.length > 0) {
      this.fileNames[index] =
        files.length === 1 ? files[0].name : `${files.length} files selected`;
      this.selectedFiles[index] = Array.from(files); // Store the selected files
    } else {
      this.fileNames[index] = "";
      this.selectedFiles[index] = []; // Clear the selected files
    }

    // Update form control validity
    this.addValueImagesForm.get("image")?.setValue(files);
    this.addValueImagesForm.get("image")?.updateValueAndValidity();
  }

  // Add Value Images Method
  AddValueImagesMethod(colorIndex: number) {
    this.addValueImagesFormSubmitted = true;

    // Check if files are selected
    if (!this.selectedFiles[colorIndex]?.length) {
      alert("Please select at least one image.");
      return;
    }
    this.isUploadComplete = false; // اجعل الزر معطلًا أثناء العملية

    // Set loading state
    this.loadingStates[colorIndex] = true;

    const color = this.colorsFeatureValues[colorIndex];
    const formData = new FormData();
    formData.append("product_id", this.productId.toString());
    formData.append("feature_value_id", color.feature_value_id.toString());

    // Append files for the specific color
    this.selectedFiles[colorIndex].forEach((file) => {
      formData.append("images[]", file, file.name);
    });

    // Send to API
    this._productServices.AddFeatureValueImages(formData).subscribe({
      next: (response) => {
        this.loadingStates[colorIndex] = false; // Stop loading
        this.uploadCompleted[colorIndex] = true; // Mark upload as completed
        console.log(
          "Upload successful for color",
          color.feature_value_name,
          response
        );

        // Show success alert
        Swal.fire({
          icon: "success",
          title: "Upload Successful",
          text: `Image uploaded successfully for ${color.feature_value_name}`,
          confirmButtonColor: "#3085d6",
        });
        this.isUploadComplete = this.uploadCompleted.every(
          (status) => status === true
        );
      },
      error: (error) => {
        this.loadingStates[colorIndex] = false; // Stop loading on error
        console.error(
          "Upload failed for color",
          color.feature_value_name,
          error
        );

        // Show error alert
        Swal.fire({
          icon: "error",
          title: "Upload Failed",
          text: `Failed to upload image for ${color.feature_value_name}. Please try again.`,
          confirmButtonColor: "#d33",
        });
        this.isUploadComplete = false; // إذا فشل أي رفع، لا يمكن تفعيل الزر
      },
    });
  }

  onSearchChange(event: Event): void {
    // const inputElement = event.target as HTMLInputElement;
    // this.searchText = inputElement.value; // Store in variable
    // console.log("Typed Text:", this.searchText);

    // this._productServices.searchByName(this.searchText).subscribe(
    //   (res) => {
    //     this.products = res;
    //   },
    //   (error) => {
    //     console.error("Search Error:", error);
    //   }
    // );
  }

  ngOnDestroy(): void {
    this.navigationService.setLastUrl(this.router.url); // Save the last URL on component destruction
  }
}
