import { Component, OnInit } from "@angular/core";
import { ProductsService } from "../products.service";
import Swal from "sweetalert2";
import {} from "rxjs";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { timeout } from "rxjs/operators";
import { TruncateWordsPipe } from "app/main/sample/pipes/truncate-words.pipe";
import {
  TempProduct,
  TempProductResponse,
} from "app/main/sample/modules/temp-product-interface";

@Component({
  selector: "app-temp-product",
  templateUrl: "./temp-product.component.html",
  styleUrls: ["./temp-product.component.scss"],
})
export class TempProductComponent implements OnInit {
  public isLoading = false;
  public tempProducts: TempProduct[] = [];
  public filteredTempProducts: TempProduct[] = [];

  // Pagination
  public page: number = 1;
  public pageSize: number = 9;
  public totalProducts: number = 0;
  public searchText: string = "";
  public searchCategory: string = "";

  constructor(
    private _productServices: ProductsService,
    private router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.getTemporaryProducts(this.page, this.pageSize);
  }

  // Fetch temporary products
  getTemporaryProducts(page: number, pageSize: number): void {
    this.isLoading = true;
    this._productServices.getTempProduct(pageSize, page).subscribe({
      next: (response: any) => {
        this.isLoading = false;
        this.tempProducts = response.data;
        this.filteredTempProducts = response.data; // Keep a copy of the original list
        this.totalProducts = response.total; // Ensure the API returns the total number of products
      },
      error: (err) => {
        this.isLoading = false;
        console.error("Error fetching products:", err);
      },
    });
  }

  // Handle page change
  changePage(page: number): void {
    this.page = page;
    this.getTemporaryProducts(this.page, this.pageSize);
  }

  // Filter products by category
  filterProductsByCategory(event: any): void {
    const val = event.target.value.toLowerCase();
    this.filteredTempProducts = this.tempProducts.filter((product) =>
      product.category_name.toLowerCase().includes(val)
    );
  }

  getFirstFiveWords(name: string): string {
    if (!name) return "";
    const words = name.split(" ");
    return words.slice(0, 5).join(" ") + (words.length > 5 ? "..." : "");
  }

  acceptAction(productId: number): void {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to accept this product?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Accept!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        // Call the API if the user confirms
        this._productServices.acceptTempProduct(productId).subscribe({
          next: (response) => {
            Swal.fire("Accepted!", "The product has been accepted.", "success");
            console.log("Product accepted successfully:", response);
            // Reload all temporary products
            this.getTemporaryProducts(this.pageSize, this.page);
          },
          error: (error) => {
            Swal.fire(
              "Error",
              "There was an issue accepting the product.",
              "error"
            );
            console.error("Error accepting product:", error);
          },
        });
      } else {
        Swal.fire("Cancelled", "The product was not accepted.", "info");
      }
    });
  }

  RejectAction(id: number, name: string) {
    console.log("RejectAction called with name:", name);
    console.log("RejectAction called with id:", id);
    Swal.fire({
      title: `Are you sure you want to cancel this Temp Product: ${name}?`,
      html: `
        <textarea id="cancelReason" class="swal2-textarea" placeholder="Enter the reason for cancellation" style="width: 100%;"></textarea>
      `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#117864",
      confirmButtonText: "Yes, Cancel this product",
      cancelButtonText: "No, Keep this product",
      preConfirm: () => {
        const reason = (
          document.getElementById("cancelReason") as HTMLTextAreaElement
        )?.value;
        console.log("Reason entered:", reason);
        if (!reason) {
          Swal.showValidationMessage("Please enter a reason for cancellation.");
          this.getTemporaryProducts(this.pageSize, this.page);
          return null;
        }
        return reason;
      },
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        console.log("Reason submitted:", result.value);
        this._productServices.rejectedTempProduct(id, name).subscribe(
          (response: any) => {
            Swal.fire(
              "Cancelled!",
              "Product has been cancelled successfully.",
              "success"
            );
          },
          (error: any) => {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "An error occurred while cancelling this product.",
              showConfirmButton: false,
              timer: 1500,
            });
            this.getTemporaryProducts(this.pageSize, this.page);
          }
        );
      }
    });
  }
}
