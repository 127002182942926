<div class="content-wrapper container-xxl p-0 mt-5">
    <div class="content-body">
      <section class="app-ecommerce-details">
        <div class="card">
          <!-- Product Details -->
          <div class="card-body">
            <div class="row my-2">
              <!-- Product Image -->
              <div class="col-12 col-md-5 d-flex align-items-center justify-content-center mb-2 mb-md-0">
                <img class="img-fluid" [src]="rows.productimage" alt="Product Image" />
              </div>
  
              <!-- Product Info -->
              <div class="col-12 col-md-7">
                <h4>{{ rows.product_name }}</h4>
                <span class="card-text item-company">
                  Store: <strong>{{ rows.store_name }}</strong>
                </span>
                <p class="card-text mt-2">
                  Price: <span class="text-success">{{ rows.product_price }}</span> EGP
                </p>
                <p class="card-text">
                  Discounted Price: <span class="text-danger">{{ rows.discounted_price }}</span> EGP
                </p>
                <p class="card-text">
                  Refund Status: <span class="text-info">{{ rows.refund_status }}</span>
                </p>
                <p class="card-text">
                  Refunded to: <span class="text-primary">{{ rows.refunded }}</span>
                </p>
                <p class="card-text">
                  Wallet Number: <span class="text-dark">{{ rows.wallet_number }}</span>
                </p>
                <p class="card-text">
                  User Address: <span class="text-muted">{{ rows.user_address }}</span>
                </p>
  
                <!-- Features -->
                <div class="mt-4">
                  <h6>Features:</h6>
                  <ul class="list-unstyled">
                    <li *ngFor="let feature of rows.feature_values">
                      <strong>{{ feature.feature_name }}:</strong> 
                      <span *ngIf="feature.feature_name === 'Color'" [style.background-color]="feature.feature_value_name" class="px-2 text-white rounded">
                        {{ feature.feature_value_name }}
                      </span>
                      <span *ngIf="feature.feature_name !== 'Color'">
                        {{ feature.feature_value_name }}
                      </span>
                    </li>
                  </ul>
                </div>
  
                <!-- Refund Images -->
                <div class="mt-4">
                  <h6>Refund Images:</h6>
                  <div class="d-flex">
                    <img *ngFor="let image of rows.refundimages" [src]="image" alt="Refund Image" class="img-thumbnail me-2" style="width: 100px; height: 100px;" />
                  </div>
                </div>
  
                <hr />
  
                <!-- Delete Button -->
                <div class="d-flex flex-column flex-sm-row pt-1">
                  <!-- Accept Wait For Delivery -->
                  <button
                    *ngIf="rows.refund_status === 'pending'"
                    class="btn btn-outline-info round mr-1 mb-1"
                    (click)="changestatus(2, row.id, row.product_shipment_id)"
                  >
                    <span data-feather="clock" size="16" class="mr-50"></span>
                    Accept Wait For Delivery
                  </button>
                
                  <!-- Reject -->
                  <button
                    *ngIf="rows.refund_status === 'pending'"
                    class="btn btn-outline-danger round mr-1 mb-1"
                    (click)="changestatus(6, row.id, row.product_shipment_id)"
                  >
                    <span data-feather="x-circle" size="16" class="mr-50"></span>
                    Reject
                  </button>
                
                  <!-- On The Way -->
                  <button
                    *ngIf="rows.refund_status === 'wait for delivery'"
                    class="btn btn-outline-warning round mr-1 mb-1"
                    (click)="changestatus(3, row.id, row.product_shipment_id)"
                  >
                    <span data-feather="phone-forwarded" size="16" class="mr-50"></span>
                    On The Way
                  </button>
                
                  <!-- Accepted -->
                  <button
                    *ngIf="rows.refund_status === 'on the way'"
                    class="btn btn-outline-success round mr-1 mb-1"
                    (click)="changestatus(4, row.id, row.product_shipment_id)"
                  >
                    <span data-feather="check-circle" size="16" class="mr-50"></span>
                    Accepted
                  </button>
                
                  <!-- Rejected By Delivery -->
                  <button
                    *ngIf="rows.refund_status === 'on the way'"
                    class="btn btn-outline-danger round mr-1 mb-1"
                    (click)="changestatus(5, row.id, row.product_shipment_id)"
                  >
                    <span data-feather="alert-circle" size="16" class="mr-50"></span>
                    Rejected By Delivery
                  </button>         
                </div>
                
              </div>
            </div>
          </div>
  
          <!-- Item Features -->
          <div class="item-features">
            <div class="row text-center">
              <div class="col-12 col-md-4 mb-4">
                <div class="w-75 mx-auto">
                  <i data-feather="award"></i>
                  <h4 class="mt-2 mb-1">100% Original</h4>
                  <p class="card-text">Guaranteed original product.</p>
                </div>
                <hr />
              </div>
              <div class="col-12 col-md-4 mb-4">
                <div class="w-75 mx-auto">
                  <i data-feather="clock"></i>
                  <h4 class="mt-2 mb-1">14 Day Replacement</h4>
                  <p class="card-text">Easy return within 14 days.</p>
                </div>
                <hr />
              </div>
              <div class="col-12 col-md-4 mb-4">
                <div class="w-75 mx-auto">
                  <i data-feather="shield"></i>
                  <h4 class="mt-2 mb-1">1 Year Warranty</h4>
                  <p class="card-text">Warranty included for peace of mind.</p>
                </div>
                <hr />
              </div>
            </div>
          </div>
          <!-- Item Features End -->
        </div>
      </section>
    </div>
  </div>
  