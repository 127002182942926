import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Subcategoryinterface } from "app/main/sample/modules/product";
import { environment } from "environments/environment";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HomeCategoriesService {
  public rows: any;
  public onUserListChanged: BehaviorSubject<any>;

  constructor(private _httpClient: HttpClient) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getAllCategory()]).then(() => {
        resolve();
      }, reject);
    });
  }

  // Get All Categories
  getAllCategory() {
    return this._httpClient.get(`${environment.apiUrl}admin/admin-category`);
  }



  // get All subcategories 
  getAllSubCategory() {
    return this._httpClient.get<Subcategoryinterface>(`${environment.apiUrl}admin/admin-second-level-category`);
  }

  // Add Categories
  addCategories(data: any) {
    return this._httpClient.post(
      `${environment.apiUrl}admin/add-home-category`,
      data
    );
  }

  // Get All Categories Added
  getAllCategoriesAdded() {
    return this._httpClient.get(`${environment.apiUrl}admin/categories/main`);
  }

  // Get All Non Categories Added
  getAllNonCategoriesAdded() {
    return this._httpClient.get(
      `${environment.apiUrl}admin/categories/not-main`
    );
  }

  deleteCategory(id:number){
    return this._httpClient.delete(`${environment.apiUrl}admin/delete-category/${id}`)
  }
  GetBanners() {
    return this._httpClient.get(`${environment.apiUrl}admin/get-all-banners`);  
  }
  AddBanner(data){
    return this._httpClient.post(`${environment.apiUrl}admin/add-banner`,data);
  }
  DeleteBanner(id){
    return this._httpClient.delete(`${environment.apiUrl}admin/delete-banner/${id}`);
  }
  ChangeBannerOrder(data){
    return this._httpClient.post(`${environment.apiUrl}admin/reorder-banners` , data);
  }
}
