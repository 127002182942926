<div class="content-wrapper container-xxl p-0 mt-3">
    <div class="content-body">
      <!-- User List -->
      <section class="users-list-wrapper">
        <!-- users filter start -->
        <div class="card">
          <h5 class="card-header font-weight-bold  display-4">All Problems</h5>
        
          <div class="card-body">
          
          </div>
        </div>
        <!-- users filter end -->
  
        <div class="card">
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="d-flex justify-content-between align-items-center m-1">
                <label class="d-flex align-items-center"
                  >Show
                  <select class="form-control mx-25" [(ngModel)]="selectedOption">
                    <option value="9999999999999999999999">All</option>
                    <option value="2">2</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="250">250</option>
                    <option value="500">500</option>
                    <option value="500">1000</option>
                    <option value="500">2500</option>
                    <option value="500">5000</option>
                    <option value="500">10000</option>
                  </select>
                  entries</label
                >
              </div>
            </div>
            <div
              class="col-md-6 col-12 d-flex justify-content-start justify-content-md-end"
            >
              <div
                class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0"
              >
                <label class="d-flex align-items-center ml-1 ml-md-0"
                  >Search:<input
                    [(ngModel)]="searchValue"
                    name="searchValue"
                    type="search"
                    (keyup)="filterUpdate($event)"
                    (search)="filterUpdate($event)"
                    class="form-control ml-25"
                 
                /></label>
              



              </div>
            </div>
          </div>
          <ngx-datatable
            [rows]="rows"
            [rowHeight]="'auto'"
            class="bootstrap core-bootstrap"
            [limit]="selectedOption"
            [columnMode]="ColumnMode.force"
            [headerHeight]="50"
            [footerHeight]="50"
            [scrollbarH]="true"
          >
            <ngx-datatable-column name="Shipment Id" prop="id" [width]="100">
              <ng-template
                let-id="value"
                let-isDeleted="row.isDeleted"
                let-isVerified="row.verified"
                ngx-datatable-cell-template
              >
                <div
                  class="badge badge-pill badge-glow px-3"
           
                >
                  {{ id }}
                </div>
              </ng-template>
            </ngx-datatable-column>
  
   
            <ngx-datatable-column
            name="User"
            prop="user"
            [width]="200"
          >
            <ng-template
              let-row="row"
              let-name="value"

              ngx-datatable-cell-template
            >
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <ngx-avatar
                    class="m-1"
                    src="{{ row.user.user_image }}"
                    name="{{ row.user.user_name }}"
                    value="28%"
                    size="40"
                    [round]="true"
                  >
                  </ngx-avatar>
                </div>
                <div class="cell-line-height">
                  <a class="font-medium-1 d-block"
                    ><span class="font-weight-bold">{{
                      row.user.user_name
                    }}</span></a
                  >
                  <span class="text-secondary font-weight-bold">
                    {{ row.user.user_email}}</span
                  >
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
  
<!--             
            <ngx-datatable-column name="Status" prop="created_at
            " [width]="200" >
              <ng-template
                let-row="row"
               
                ngx-datatable-cell-template
              >
              {{ row.created_at| date: "short" }}


            
              </ng-template>
            </ngx-datatable-column>
            -->
            <ngx-datatable-column name="status" prop="status" [width]="100">
                <ng-template let-status="value" ngx-datatable-cell-template>
                  <div
                    class="badge badge-pill"
                    [ngClass]="{
                      
                      'badge-light-primary': status == 'new',
                      'badge-light-success': status == 'solved',
                      'badge-light-warning': status == 'in progress'
                    
                    }"
                  >
                    {{ status }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

            <ngx-datatable-column name="Problem" prop="message
            " [width]="300" >
              <ng-template
                let-row="row"
               
                ngx-datatable-cell-template
              >
              {{ row.message}}


            
              </ng-template>
            </ngx-datatable-column>
  
            <ngx-datatable-column name="Tracked By" prop="admin_name" [width]="150">
                <ng-template
                  let-row="row"
                  let-name="value"
                  ngx-datatable-cell-template

                  
                >
                 {{ name }}
                  
                </ng-template>
              </ngx-datatable-column>
  
           

  
  
            <ngx-datatable-column name="Actions" [width]="100" [sortable]="false">
              <ng-template ngx-datatable-cell-template let-row="row">
                <div ngbDropdown container="body">
                  <button *ngIf="row.admin_id==user_id||row.admin_id==-1"
                    ngbDropdownToggle
                    type="button"
                    class="btn icon-btn btn-sm hide-arrow text-danger"
                    rippleEffect
                  >
                    <span
                      [data-feather]="'more-vertical'"
                      [class]="'cursor-pointer'"
                    ></span>
                  </button>
                  <div ngbDropdownMenu >
                   
                    
                      
                  


                             
                    <a (click)="track(row.id)" *ngIf="row.status ==='new'"
                    ngbDropdownItem
                    ><span
                      [data-feather]="'trello'"
                      [size]="16"
                      [class]="'mr-50 text-info'"
                    ></span>
                    Track </a>
                        
                    <a (click)="mark_solved(row.id)"  *ngIf="row.status ==='in progress'"
                      ngbDropdownItem
                      ><span
                        [data-feather]="'check'"
                        [size]="16"
                        [class]="'mr-50 text-success'"
                      ></span>
                      Mark as solved </a>



                      <a (click)="delete(row.id)"
                      ngbDropdownItem
                      ><span
                        [data-feather]="'x-square'"
                        [size]="16"
                        [class]="'mr-50 text-danger'"
                      ></span>
                      Delete </a>
                  
                  </div>
               



                </div>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </section>
    </div>
  </div>
  
  
  



  
  























  