<div *ngIf="!isLoading" class="content-wrapper container-xxl p-0 mt-3">
  <div class="content-detached">
    <div class="content-body">
      <!-- Search Section -->
      <section id="ecommerce-searchbar" class="my-3">
        <div class="card p-4">
          <h5 class="card-title">Search Products</h5>
          <div class="row g-3">
            <div class="col-md-4">
              <input
                type="text"
                class="form-control"
                placeholder="Search by Product Name"
                [(ngModel)]="searchText"
              />
            </div>
            <div class="col-md-4">
              <input
                type="text"
                class="form-control"
                placeholder="Search by Category"
                [(ngModel)]="searchCategory"
                (keyup)="filterProductsByCategory()"
              />
            </div>
            <div class="col-md-4">
              <input
                type="number"
                class="form-control"
                placeholder="Search by Max Price"
                [(ngModel)]="searchPrice"
              />
            </div>
          </div>
        </div>
      </section>

      <!-- Product Display -->
      <section id="product-display" class="my-3">
        <div class="row">
          <div
            class="col-xl-3 col-md-4 col-sm-6"
            *ngFor="let product of tempProducts"
          >
            <div class="card product-card shadow-sm">
              <a [routerLink]="'/temp-details/' + product?.product_id">
                <img
                  [src]="product.product_image"
                  class="card-img-top"
                  alt="{{ product.product_name }}"
                />
              </a>
              <div class="card-body text-center">
                <!-- product Name -->
                <div class="div">
                  <h5 class="card-title">
                    {{ getFirstFiveWords(product.product_name) }}
                  </h5>
                </div>

                <!-- Category -->
                <div class="d-flex justify-content-center align-items-center">
                  <h6 class="">Category:</h6>
                  <span class="mx-1 mb-1 text-info">{{
                    product.category_name
                  }}</span>
                </div>

                <!-- Price -->
                <div class="Price">
                  <p class="text-info">
                    Price:
                    <span *ngIf="!product.has_discount" class="text-success">
                      {{ product.product_price }} YER
                    </span>
                    <span *ngIf="product.has_discount" class="text-success">
                      <del class="text-warning"
                        >{{ product.product_price }} YER</del
                      >
                      {{ product.discounted_price }} YER
                    </span>
                  </p>
                </div>

                <span
                  class="badge"
                  [ngClass]="{
                    'bg-success': product.status === 'accepted',
                    'bg-warning': product.status === 'new_create',
                    'bg-danger': product.status === 'rejected',
                    'bg-info': product.status === 'new_update'
                  }"
                  >{{ product.status }}</span
                >
                <div class="d-flex justify-content-center mt-2">
                  <button
                    *ngIf="product.status !== 'accepted'"
                    class="btn btn-success mx-1"
                    (click)="acceptAction(product.product_id)"
                  >
                    Accept
                  </button>
                  <button
                    *ngIf="product.status === 'new_create'"
                    class="btn btn-danger mx-1"
                    (click)="
                      RejectAction(product.product_id, product.product_name)
                    "
                  >
                    Reject
                  </button>
                  <button
                    *ngIf="product.status === 'accepted'"
                    class="btn btn-success mx-1"
                    disabled
                  >
                    Accepted
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- E-commerce Pagination Starts -->
      <!-- Pagination Section -->
      <section id="ecommerce-pagination">
        <div class="row">
          <div class="col-sm-12">
            <div class="d-flex justify-content-center">
              <ngb-pagination
                [collectionSize]="totalProducts"
                [(page)]="page"
                [pageSize]="pageSize"
                [maxSize]="5"
                [rotate]="true"
                [ellipses]="true"
                [boundaryLinks]="true"
                (pageChange)="changePage($event)"
              >
                <!-- Custom pagination icons -->
                <ng-template ngbPaginationFirst>
                  <span
                    class="font-weight-bolder"
                    data-feather="chevrons-left"
                    size="18"
                  ></span>
                </ng-template>
                <ng-template ngbPaginationPrevious>
                  <span
                    class="font-weight-bolder"
                    data-feather="chevron-left"
                    size="18"
                  ></span>
                </ng-template>
                <ng-template ngbPaginationNext>
                  <span
                    class="font-weight-bolder"
                    data-feather="chevron-right"
                    size="18"
                  ></span>
                </ng-template>
                <ng-template ngbPaginationLast>
                  <span
                    class="font-weight-bolder"
                    data-feather="chevrons-right"
                    size="18"
                  ></span>
                </ng-template>
              </ngb-pagination>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>

<!-- ///////////////////////////////loader//////////////////// -->
<div *ngIf="isLoading" id="loader-wrapper">
  <div id="loader"></div>
  <span *ngIf="isLoading" class="loader"></span>
  <div class="loader-section section-left"></div>
  <div class="loader-section section-right"></div>
</div>
