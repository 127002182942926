<div class="content-wrapper container-xxl p-0 mt-3">
  <div class="content-body">
    <!-- User List -->
    <section class="users-list-wrapper">
      <!-- users filter start -->
      <div class="card">
        <h5 class="card-header font-weight-bold display-4">All Reviews</h5>
        <div class="p-4">
          <nav class="nav nav-pills flex-column flex-sm-row">
            <a
              *ngFor="let status of reviewStatus"
              class="flex-sm-fill text-sm-center nav-link mx-2 border border-1 py-3"
              [class.active]="selectedStatus === status.value"
              (click)="onStatusChange(status.value)"
              href="javascript:void(0)"
            >
              {{ status.name }}
            </a>
          </nav>
        </div>
      </div>
      <!--   -->

      <div class="row">
        <div *ngFor="let review of reviews" class="col-md-4 mb-4">
          <div class="card h-100 shadow-sm border-0 mb-3">
            <div class="card-body text-start d-flex flex-column p-3">
              <!-- Display Images if Present -->
              <div
                *ngIf="review.reviewImages && review.reviewImages.length > 0"
                class="mb-3"
              >
                <div class="row g-2">
                  <div
                    *ngFor="let image of review.reviewImages"
                    class="col-4 mb-2"
                  >
                    <img
                      [src]="image"
                      class="img-fluid rounded shadow-sm"
                      alt="Review Image"
                      style="width: 100%; height: 80px; object-fit: cover"
                    />
                  </div>
                </div>
              </div>

              <!-- User Name -->
              <div class="d-flex align-items-center mb-2">
                <i data-feather="user" class="feather-sm text-primary me-2"></i>
                <span class="mx-1"
                  >User:
                  <span class="text-primary fw-bold">{{
                    review?.User_name || "No name provided"
                  }}</span>
                </span>
              </div>

              <!-- Review Text with See More/Less -->
              <div class="mb-3">
                <div class="d-flex align-items-center mb-1">
                  <i
                    data-feather="message-square"
                    class="feather-sm text-primary me-2"
                  ></i>
                  <h6 class="mb-0 mx-1">Review:</h6>
                </div>
                <div class="review-text-container">
                  <p
                    class="review-text"
                    [class.expanded]="review.expanded"
                    [class.collapsed]="!review.expanded"
                  >
                    {{ review.review || "No review text" }}
                  </p>
                  <button
                    *ngIf="review.review && review.review.length > 150"
                    class="btn btn-link btn-sm p-0 text-decoration-none"
                    (click)="review.expanded = !review.expanded"
                  >
                    {{ review.expanded ? "See Less" : "See More" }}
                  </button>
                </div>
              </div>

              <!-- Rating Display with Feather Icons -->
              <div class="d-flex align-items-center mb-2">
                <i data-feather="star" class="feather-sm text-primary me-2"></i>
                <span class="mx-1">Rating:</span>
                <span class="text-warning">
                  <i
                    *ngFor="let star of [].constructor(review.rate || 0)"
                    data-feather="star"
                    class="feather-star filled"
                  ></i>
                  <i
                    *ngFor="let star of [].constructor(5 - (review.rate || 0))"
                    data-feather="star"
                    class="feather-star"
                  ></i>
                </span>
              </div>

              <!-- Review Date -->
              <div class="d-flex align-items-center mb-2">
                <i
                  data-feather="calendar"
                  class="feather-sm text-primary me-2"
                ></i>
                <span class="mx-1">Date:</span>
                <span class="text-secondary">{{
                  review.created_at
                    ? review.created_at.split("T")[0]
                    : "No date"
                }}</span>
              </div>

              <hr class="my-3" />

              <!-- Conditional Button Rendering -->
              <div class="d-flex justify-content-center mt-auto">
                <!-- Show Accept/Reject buttons if status is NOT 'published' -->
                <ng-container
                  *ngIf="review.status !== 'published'; else deleteButton"
                >
                  <button
                    class="btn btn-success btn-sm mx-1 px-3"
                    (click)="changeStatusReview(review.id, 1)"
                  >
                    <i data-feather="check" class="feather-sm me-1"></i> Accept
                  </button>
                  <button
                    class="btn btn-danger btn-sm mx-1 px-3"
                    (click)="changeStatusReview(review.id, 0)"
                  >
                    <i data-feather="x" class="feather-sm me-1"></i> Reject
                  </button>
                </ng-container>

                <!-- Delete Button (shown only if status is 'published') -->
                <ng-template #deleteButton>
                  <button
                    class="btn btn-danger btn-sm mx-1 px-3"
                    (click)="deleteReview(review.id)"
                  >
                    <i data-feather="trash-2" class="feather-sm me-1"></i>
                    Delete
                  </button>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

<!-- ///////////////////////////////loader//////////////////// -->
<div *ngIf="isLoading" id="loader-wrapper">
  <div id="loader"></div>
  <span *ngIf="isLoading" class="loader"></span>
  <div class="loader-section section-left"></div>
  <div class="loader-section section-right"></div>
</div>
