import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../products.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-reviews-products',
  templateUrl: './reviews-products.component.html',
  styleUrls: ['./reviews-products.component.scss']
})
export class ReviewsProductsComponent implements OnInit {

// Pagination 
public page: number = 1;
public pageSize: number = 9;
public totalProducts: number;
public searchText = '';
public loader: boolean = true;
public displayedProducts: any[] = [];
// public currentPage; 
public limit = 9;
public products: any[] = [];




  constructor(private _productServices: ProductsService,
      private router: Router,
      private fb: FormBuilder,
      private modalService: NgbModal,) { }

  ngOnInit(): void {
  }

}
