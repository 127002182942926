import {
  Component,
  OnDestroy,
  OnInit,
  HostBinding,
  HostListener,
  ViewEncapsulation,
  ChangeDetectorRef,
  NgZone,
} from "@angular/core";
import { MediaObserver } from "@angular/flex-layout";
import * as _ from "lodash";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "app/auth/service";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { CoreConfigService } from "@core/services/config.service";
import { CoreMediaService } from "@core/services/media.service";
import { User } from "app/auth/models";
import { coreConfig } from "app/app-config";
import { Router } from "@angular/router";
import { HomeService } from "app/components/home/home.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import Swal from "sweetalert2";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit, OnDestroy {
  public horizontalMenu: boolean;
  public hiddenMenu: boolean;

  public coreConfig: any;
  public currentSkin: string;
  public prevSkin: string;

  public currentUser: User;

  public languageOptions: any;
  public navigation: any;
  public selectedLanguage: any;

  // Create Acccount
  public createRoleForm: FormGroup;
  public createRoleFormSubmitted = false;
  public modalReference;
  public roles;
  public restrictions;
  public getRoleData;
  public isLoading = false;
  public userRole: string | null = null;
  passwordFieldType: string = "password";
  showPassword: boolean = false;


  @HostBinding("class.fixed-top")
  public isFixed = false;

  @HostBinding("class.navbar-static-style-on-scroll")
  public windowScrolled = false;

  // Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (
      (window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop > 100) &&
      this.coreConfig.layout.navbar.type == "navbar-static-top" &&
      this.coreConfig.layout.type == "horizontal"
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreMediaService} _coreMediaService
   * @param {MediaObserver} _mediaObserver
   * @param {TranslateService} _translateService
   */
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private _coreConfigService: CoreConfigService,
    private _coreMediaService: CoreMediaService,
    private _coreSidebarService: CoreSidebarService,
    private _mediaObserver: MediaObserver,
    public _translateService: TranslateService,
    private _homeServeice: HomeService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone
  ) {
    this._authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
    // console.log(this.currentUser);

    this.languageOptions = {
      en: {
        title: "English",
        flag: "us",
      },
      fr: {
        title: "French",
        flag: "fr",
      },
      de: {
        title: "German",
        flag: "de",
      },
      pt: {
        title: "Portuguese",
        flag: "pt",
      },
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    // Create Account Form
    this.createRoleForm = this.fb.group({
      firstName: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
        ],
      ],
      lastName: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
        ],
      ],
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(8)]],
      phone: [null, [Validators.pattern(/^(01[0-9]{9}|7[0-9]{7})$/)]],
      role: ["", [Validators.required]],
      restriction_name: [[], [Validators.required]],
    });
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  /**
   * Set the language
   *
   * @param language
   */
  setLanguage(language): void {
    // Set the selected language for the navbar on change
    this.selectedLanguage = language;

    // Use the selected language id for translations
    this._translateService.use(language);

    this._coreConfigService.setConfig(
      { app: { appLanguage: language } },
      { emitEvent: true }
    );
  }

  /**
   * Toggle Dark Skin
   */
  toggleDarkSkin() {
    // Get the current skin
    this._coreConfigService
      .getConfig()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.currentSkin = config.layout.skin;
        localStorage.setItem("currentSkin", this.currentSkin);
      });

    // Toggle Dark skin with prevSkin skin
    this.prevSkin = localStorage.getItem("prevSkin");

    if (this.currentSkin === "dark") {
      this._coreConfigService.setConfig(
        { layout: { skin: this.prevSkin ? this.prevSkin : "default" } },
        { emitEvent: true }
      );
    } else {
      localStorage.setItem("prevSkin", this.currentSkin);
      this._coreConfigService.setConfig(
        { layout: { skin: "dark" } },
        { emitEvent: true }
      );
    }
  }

  /**
   * Logout method
   */
  logout() {
    this._authenticationService.logout();
    this._router.navigate(["/pages/authentication/login-v2"]);
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the config changes
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
        this.horizontalMenu = config.layout.type === "horizontal";
        this.hiddenMenu = config.layout.menu.hidden === true;
        this.currentSkin = config.layout.skin;

        // Fix: for vertical layout if default navbar fixed-top than set isFixed = true
        if (this.coreConfig.layout.type === "vertical") {
          setTimeout(() => {
            if (this.coreConfig.layout.navbar.type === "fixed-top") {
              this.isFixed = true;
            }
          }, 0);
        }
      });

    // Horizontal Layout Only: Add class fixed-top to navbar below large screen
    if (this.coreConfig.layout.type == "horizontal") {
      // On every media(screen) change
      this._coreMediaService.onMediaUpdate
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {
          const isFixedTop = this._mediaObserver.isActive("bs-gt-xl");
          if (isFixedTop) {
            this.isFixed = false;
          } else {
            this.isFixed = true;
          }
        });
    }

    // Set the selected language from default languageOptions
    this.selectedLanguage = _.find(this.languageOptions, {
      id: this._translateService.currentLang,
    });

    this.getRole();
    this.getRestrictions();
  }

  // Get All Restirctions
  getRestrictions(): void {
    this._homeServeice.getAllRestrictions().subscribe({
      next: (res: any) => {
        this.restrictions = res.Restrictions;
        // console.log('restrictions:', this.restrictions);
      },
      error: (error) => {
        console.error("Error fetching restrictions:", error);
      },
    });
  }

  // Get Role
  getRole() {
    this._homeServeice.getAllRoles().subscribe({
      next: (res: any) => {
        this.roles = res.roles;
        // console.log('Roles:', this.roles);
      },
      error: (error) => {
        console.error("Error fetching restrictions:", error);
      },
    });
  }

  // Model Add Account
  ModelAddRole(modelCreateRole) {
    this.createRoleFormSubmitted = false;
    this.createRoleForm.reset();
    this.modalReference = this.modalService.open(modelCreateRole, {
      backdrop: false,
      centered: true,
    });
  }

  // Helper method to check if a restriction is selected
  isRestrictionSelected(restrictionName: string): boolean {
    const selectedRestrictions = this.createRoleForm.get('restriction_name')?.value || [];
    return selectedRestrictions.includes(restrictionName);
  }

  // Handle checkbox changes
  onRestrictionChange(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    const currentRestrictions = this.createRoleForm.get('restriction_name')?.value || [];
    
    if (checkbox.checked) {
      // Add the restriction if checked
      currentRestrictions.push(checkbox.value);
    } else {
      // Remove the restriction if unchecked
      const index = currentRestrictions.indexOf(checkbox.value);
      if (index > -1) {
        currentRestrictions.splice(index, 1);
      }
    }
    
    // Update the form control
    this.createRoleForm.patchValue({
      restriction_name: currentRestrictions
    });
  }

  // create Role Form Method
  createRoleFormMethod() {
    this.createRoleFormSubmitted = true;
    if (this.createRoleForm.invalid) {
      console.log("Form is invalid:", this.createRoleForm.errors);
      return;
    }
    this.isLoading = true;

    const formValues = this.createRoleForm.value;
    const formData = new FormData();
    formData.append("firstName", formValues.firstName);
    formData.append("lastName", formValues.lastName);
    formData.append("email", formValues.email);
    formData.append("password", formValues.password);
    formData.append("phone", formValues.phone);
    formData.append("role", formValues.role);

    if (formValues.restriction_name && formValues.restriction_name.length > 0) {
      formValues.restriction_name.forEach((restriction, index) => {
        formData.append(`restriction_name[${index}]`, restriction);
      });
    }

    this._homeServeice.Register(formData).subscribe({
      next: (response) => {
        this.isLoading = false;
        
        // First success popup
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Account created successfully",
          confirmButtonText: "Show Credentials",
        }).then(() => {
          // Second popup with credentials
          Swal.fire({
            icon: "info",
            title: "Account Credentials",
            html: `
              <div class="credentials-popup">
                <p><strong>Email:</strong> ${formValues.email}</p>
                <p><strong>Password:</strong> ${formValues.password}</p>
                <div class="alert alert-warning mt-3">
                  <small>Please save these credentials securely.</small>
                </div>
              </div>
            `,
            confirmButtonText: "Close",
            allowOutsideClick: false,
          }).then(() => {
            this.modalReference.close();
          });
        });
      },
      error: (err) => {
        console.log(err.error.message);

        this.isLoading = false;
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: err.error.message || "Failed to create account",
          confirmButtonText: "Try Again",
        });
      },
    });
  }



togglePasswordVisibility(): void {
  this.showPassword = !this.showPassword;
}




  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
