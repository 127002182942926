<div class="content-wrapper container-xxl p-0 mt-5">
  <div class="content-body">
    <!-- app e-commerce details start -->
    <section class="app-ecommerce-details">
      <div class="card">
        <!-- Product Details starts -->
        <div class="card-body">
          <div class="row my-2">
            <!-- left Side   -->
            <div
              class="col-12 col-md-5 d-flex align-items-center justify-content-center mb-2 mb-md-0"
            >
              <div class="card-body">
                <swiper class="swiper-coverflow swiper-container">
                  <ng-container
                    *ngFor="let image of shipmentDetails?.product_images"
                  >
                    <img class="img-fluid" [src]="image" alt="Product Image" />
                  </ng-container>
                </swiper>
              </div>
            </div>

            <!-- Right Info  -->
            <div class="col-12 col-md-7">
              <!-- Client Info  -->
              <div class="p-3 shadow-lg rounded border border-3">
                <!-- <h1 class="text-info">{{ shipmentDetails?.store_name }}</h1><br> -->

                <!-- Store Name -->
                <div class="">
                  <span class="h3 lead card-text text-white item-company">
                    Store Name :
                    <a href="javascript:void(0)" class="company-name text-info">
                      {{ shipmentDetails?.store_name }}
                    </a>
                  </span>
                </div>
                <br />

                <!-- client Phone -->
                <div>
                  <span class="h3 lead card-text text-white item-company mt-1">
                    Client Phone :
                    <ng-container>
                      <a href="javascript:void(0)" class="text-info mx-2">{{
                        shipmentDetails?.user_phone
                      }}</a>
                    </ng-container>
                  </span>
                </div>
                <br />

                <!-- shipment id -->
                <div>
                  <span class="h3 lead card-text text-white item-company mt-1">
                    Shipment id :
                    <ng-container>
                      <a href="javascript:void(0)" class="text-info mx-2">{{
                        shipmentDetails?.product_shipment_id
                      }}</a>
                    </ng-container>
                  </span>
                </div>
              </div>
              <br />

              <!------------------------------------ Product Info  -------------------------->

              <!-- Product Name -->
              <div class="p-3 shadow-lg rounded border border-3">
                <div class="div">
                  <h5 class="text-info">
                    <span class="text-white">Product Name : </span
                    >{{ shipmentDetails?.product_name }}
                  </h5>
                </div>
                <br />

                <!-- Product Price  -->
                <div>
                  <span class="h6 lead card-text text-white item-company mt-1">
                    Product Price :
                    <ng-container>
                      <a href="javascript:void(0)" class="text-info mx-2">
                        {{ shipmentDetails?.product_price }} YER</a
                      >
                    </ng-container>
                  </span>
                </div>
                <br />

                <div>
                  <span class="h6 lead card-text text-white item-company mt-1">
                    Product status :
                    <ng-container>
                      <a href="javascript:void(0)" class="text-info mx-2">
                        {{ shipmentDetails?.shipment_status }}
                      </a>
                    </ng-container>
                  </span>
                </div>
                <br />
              </div>

              <!-- Status Action  -->
              <hr />
              <div class="">
                <div class="d-flex order-status">
                  <!-- Check if the order status is not 'New' or 'Preparing' -->
                  <ng-container
                    *ngIf="
                      shipmentDetails?.order_status !== 'New' &&
                      shipmentDetails?.order_status !== 'Preparing'
                    "
                  >
                    <!-- Check if the order status is pending' -->
                    <ng-container
                      *ngIf="shipmentDetails?.order_status == 'Pending'"
                    >
                      <span class="h4 text-info mt-1"
                        >{{ shipmentDetails?.order_status }} :</span
                      >
                      <!-- Cancelled By Customer   -->
                      <div
                        class="d-flex flex-column flex-sm-row pt-1 mx-2"
                        (click)="cancelOrder(4)"
                      >
                        <a class="btn btn-outline-danger round" rippleEffect>
                          <span> Cancel By Customer </span>
                        </a>
                      </div>
                       <!-- Cancelled By Store   -->
                      <div
                        class="d-flex flex-column flex-sm-row pt-1 mx-2"
                        (click)="cancelOrder(6)"
                      >
                        <a class="btn btn-outline-danger round" rippleEffect>
                          <span> Cancel By Store </span>
                        </a>
                      </div>
                    </ng-container>

                    <!-- Check if the order status is Perparing' -->
                    <ng-container
                      *ngIf="shipmentDetails?.order_status == 'Perparing'"
                    >
                      <span class="h4 text-info mt-1"
                        >{{ shipmentDetails?.order_status }} :</span
                      >
                     <!-- Cancelled By Customer   -->
                     <div
                     class="d-flex flex-column flex-sm-row pt-1 mx-2"
                     (click)="cancelOrder(4)"
                   >
                     <a class="btn btn-outline-danger round" rippleEffect>
                       <span> Cancel By Customer </span>
                     </a>
                   </div>
                   <div
                     class="d-flex flex-column flex-sm-row pt-1 mx-2"
                     (click)="cancelOrder(6)"
                   >
                     <a class="btn btn-outline-danger round" rippleEffect>
                       <span> Cancel By Store </span>
                     </a>
                   </div>
                    </ng-container>

                    <!-- Check if the order status is NOT 'On The Way' -->
                    <ng-container
                      *ngIf="shipmentDetails?.order_status == 'On the way'"
                    >
                      <span class="h4 text-info mt-1"
                        >{{ shipmentDetails?.order_status }} :</span
                      >
                    <!-- Order Deliverd  -->
                      <div
                        class="d-flex flex-column flex-sm-row pt-1 mx-2"
                        (click)="Delivered(3)"
                      >
                        <a class="btn btn-outline-success round" rippleEffect>
                          <span> Order Delivered </span>
                        </a>
                      </div>
                      <!-- Cancelled By Customer   -->
                      <div
                        class="d-flex flex-column flex-sm-row pt-1 mx-2"
                        (click)="cancelOrder(4)"
                      >
                        <a class="btn btn-outline-danger round" rippleEffect>
                          <span> Cancel By Customer </span>
                        </a>
                      </div>
                    </ng-container>

                    <!-- Additional Actions when status is NOT 'delivered to ware house' -->
                    <ng-container
                      *ngIf="
                        shipmentDetails?.order_status ==
                        'Delivered To Warehouse'
                      "
                    >
                      <span class="h4 text-info mt-1"
                        >{{ shipmentDetails?.order_status }} :</span
                      >
                      <!-- On The Way  -->
                      <div
                        class="d-flex flex-column flex-sm-row pt-1 mx-2"
                        (click)="onTheWay(2)"
                      >
                        <a class="btn btn-outline-warning round" rippleEffect>
                          <span> On The Way </span>
                        </a>
                      </div>

                      <!-- Cancel By Admin  -->
                      <div
                        class="d-flex flex-column flex-sm-row pt-1 mx-2"
                        (click)="cancelOrder(8)"
                      >
                        <a class="btn btn-outline-warning round" rippleEffect>
                          <span> Cancel By Admin </span>
                        </a>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
