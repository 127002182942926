import { Component, NgModule, OnInit } from '@angular/core';
import { RefundService } from '../refund.service';
import { ActivatedRoute } from '@angular/router';
import { SwiperConfigInterface, SwiperModule } from 'ngx-swiper-wrapper';
import { BrowserModule } from '@angular/platform-browser';
import Swal from 'sweetalert2';
   
@Component({
  selector: 'app-refund-details',
  templateUrl: './refund-details.component.html',
  styleUrls: ['./refund-details.component.scss']
})
export class RefundDetailsComponent implements OnInit {
 public swiperCoverflow: SwiperConfigInterface = {
    effect: 'coverflow',
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    coverflowEffect: {
      rotate: 60,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true
    },
    pagination: {
      el: '.swiper-pagination'
    }
  };

  refundData: any; 
  rows:any
  refund_id:any;
  Status:any;

  constructor(
    private _refundService: RefundService,
    private route: ActivatedRoute    
  ) { }
  
  ngOnInit(): void {
    this.refund_id = this.route.snapshot.paramMap.get('id')!;
    this.getRefundDetails()
  }

  getRefundDetails() {
    this._refundService.refundById(this.refund_id ).subscribe({
      next: (res) => {
        this.refundData = res; 
        this.rows= this.refundData.refund
        console.log(this.rows);
        
      },
      error: (error) => {
        console.error('Error fetching refund data:', error);
      }
    });
  }


    changestatus(status: number, id: number, productId: number) {
      this.Status = status;
    
      // Display confirmation dialog
      Swal.fire({
        title: 'Are you sure you want to change the status?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, change it!',
        cancelButtonText: 'No, cancel'
      }).then((result) => {
        if (result.isConfirmed) {
          // If status is 5 or 6, prompt for rejection reason
          if (status === 5 || status === 6) {
            Swal.fire({
              title: 'Provide a reason for rejection',
              input: 'textarea',
              inputPlaceholder: 'Enter the reason for rejection...',
              inputValidator: (value) => {
                if (!value) {
                  return 'You need to provide a reason!';
                }
                return null;
              },
              showCancelButton: true
            }).then((inputResult) => {
              if (inputResult.isConfirmed && inputResult.value) {
                const payload = {
                  refund_id: id,
                  status: status,
                  rejection_reason: inputResult.value
                };
                this.sendChangeStatusRequest(payload);
              }
            });
          } else {
            const payload = {
              refund_id: id,
              status: status
            };
            this.sendChangeStatusRequest(payload);
          }
        }
      });
    }
    sendChangeStatusRequest(payload: any) {
      this._refundService.changeRefundStatus(payload).subscribe(
        (res) => {
          console.log(res);
          Swal.fire('Success', 'Status changed successfully!', 'success');
          // Optionally, update the UI or perform other actions
        },
        (err) => {
          console.log(err);
          Swal.fire('Error', 'An error occurred while changing the status.', 'error');
        }
      );
    }
}
