import { AdminSelectCategory } from './../../../main/sample/modules/admin-select-category';
import { map } from 'rxjs/operators';
import { repeaterAnimation } from './form-repeater.animation';
import { Subcategoryinterface, Value } from './../../../main/sample/modules/subcategoryinterface';
import { SubcategoryserviceService } from './../subcategoryservice.service';
import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
import Swal from "sweetalert2";
import { Subject } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { Router } from '@angular/router';
import { SharedService } from 'app/components/Shared Servece/shared.service';


@Component({
  selector: 'app-subcategory-list',
  templateUrl: './subcategory-list.component.html',
  styleUrls: ['./subcategory-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [repeaterAnimation]

})
export class SubcategoryListComponent implements OnInit {
  private tempData: any;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild("tableRowDetails") tableRowDetails: any;

  public sidebarToggleRef = false;
  public category: AdminSelectCategory;
  public rows: Subcategoryinterface;

  public data: Subcategoryinterface;

  public selectedOption = 10;
  public ColumnMode = ColumnMode;
  public temp: any;
  public searchValue = "";
  public modalReference:any;
  public modalReference2:any;  
  public modalReference3:any;  
  public modalReference4:any;  
  public modalReferenceAddSubCategoryThird:any;  
  public subcategory_id:number=0;
  
  public UpdatedCategory_id:any;
  public UpdatedSubCategory_id:any;
  public UpdatedCategory_title:any;


  public ReactiveOrderStatusForm: UntypedFormGroup;
  public ReactiveOrderStatusFormAddThird: UntypedFormGroup;
  public ReactiveOrderStatusFormSubmitted = false;


  public CreateSubCategoryForm: UntypedFormGroup;
  public CreateSubCategoryFormSubmitted = false;


// For Feature 
public items = [{ itemName_en: '', itemName_ar: '' }];
public item = { itemName_en: '', itemName_ar: '' };






  public subItems = [{subItemName: '' }];
  public subItem = {subItemName: '',}




  public loading = false;
  public loadAddSub = true;




  // Received Category Id 
  public CategoryId: number | null = null;
  public Category_Id: number | null = null;
  public subCategoryData ;




  // Upload Photo Variable
  public file:File;
  public fileName=''



  constructor(
    private _Subcategoryserv: SubcategoryserviceService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private sharedService: SharedService
  ) {

    // CreateSubCategoryForm
    this.CreateSubCategoryForm = this.formBuilder.group({
      parent_id: ["", [Validators.required]],
      name_en: ['', [Validators.required,Validators.minLength(2),Validators.maxLength(50), Validators.pattern(/^[A-Za-z\s]+$/)]],
      name_ar: ['', [Validators.required,Validators.minLength(2),Validators.maxLength(50),Validators.pattern(/^[\u0600-\u06FF\s]+$/)]],
      image: ["", []],
    });


    this.ReactiveOrderStatusForm = this.formBuilder.group({
      parent_id: ["", [Validators.required]],
      name_en: ['', [Validators.required,Validators.minLength(2),Validators.maxLength(50), Validators.pattern(/^[A-Za-z\s]+$/)]],
      name_ar: ['', [Validators.required,Validators.minLength(2),Validators.maxLength(50),Validators.pattern(/^[\u0600-\u06FF\s]+$/)]],
      image: ["", []],
    });
    
    this.ReactiveOrderStatusFormAddThird = this.formBuilder.group({
      name_en: ['', [Validators.required,Validators.minLength(2),Validators.maxLength(50), Validators.pattern(/^[A-Za-z\s]+$/)]],
      name_ar: ['', [Validators.required,Validators.minLength(2),Validators.maxLength(50),Validators.pattern(/^[\u0600-\u06FF\s]+$/)]],
      image: ["", []],
    });

  
  }

  get CreateSubCategoryControls() {
    return this.CreateSubCategoryForm.controls;
  }
  
  get ReactiveOSForm() {
    return this.ReactiveOrderStatusForm.controls;
  }
  get ReactiveOSFormThird() {
    return this.ReactiveOrderStatusFormAddThird.controls;
  }




  ngOnInit(): void {
    this.getAllSubCategory();

      // Received Category Id
    // this.sharedService.currentId$.subscribe((id) => {
    //   this.CategoryId = id;
    //   if (this.CategoryId) {
    //     this.getAllSubCategory();
    //   }
    // });
    // this.getFirstSubCategory();
  }




  // Navigate and send row id
  navigateSubIdWithState(SubCategoryId: number): void {
    this.sharedService.changeId(SubCategoryId);
    console.log(SubCategoryId);
  }

  getFirstSubCategory() {
    console.log(this.CategoryId);
    this._Subcategoryserv.getSubCategory(this.CategoryId).subscribe(
      (res) => {
        console.log('Subcategory Data:', res);
       this.subCategoryData = res
       console.log(this.subCategoryData);
       
      },
      (error) => {
        console.error('Error fetching subcategory:', error);
      }
    );
  }


// Feature 
  deleteItem(id) {
    for (let i = 0; i < this.items.length; i++) {
      if (this.items.indexOf(this.items[i]) === id) {
        this.items.splice(i, 1);
        break;
      }
    }
  }

  addItem() {
    this.items.push({
      itemName_en: '',
      itemName_ar: ''
    });
  }

  
  // Get all SubCategoryy
  getAllSubCategory() {
    this._Subcategoryserv.getAllSubCategory().subscribe(
      (res: any) => {
        this.rows = res;
        this.tempData = res;

      },
      (er: any) => {
        console.log(er);
      }
    );
  }

  // Upload Photo 
  onFilechange(event: any) {
    this.file = event.target.files[0];
    this.fileName = this.file.name;
    console.log(this.fileName);
  }

  filterUpdate(event) {
    // Reset ng-select on search


    const val = event.target.value.toLowerCase();

    // Filter Our Data
    const temp = this.tempData.filter(function (d) {
      return (
        d.name.toLowerCase().indexOf(val) !== -1 ||
        !val
      );
    });

    // Update The Rows
    this.rows = temp;
    // Whenever The Filter Changes, Always Go Back To The First Page
    this.table.offset = 0;
  }

  filterRows(verifiedFilter, suspendFilter, deletedFilter): any[] {
    // Reset search on select change
    this.searchValue = "";

    verifiedFilter = verifiedFilter.toLowerCase();
    suspendFilter = suspendFilter.toLowerCase();
    deletedFilter = deletedFilter.toLowerCase();

    return this.tempData.filter((row) => {
      const isPartialNameMatch =
        row.verified.toString().toLowerCase().indexOf(verifiedFilter) !== -1 ||
        !verifiedFilter;
      const isPartialGenderMatch =
        `${row.banned}`.toLowerCase().indexOf(suspendFilter) !== -1 ||
        !suspendFilter;
      const isPartialStatusMatch =
        row.isDeleted.toString().toLowerCase().indexOf(deletedFilter) !== -1 ||
        !deletedFilter;
      return isPartialNameMatch && isPartialGenderMatch && isPartialStatusMatch;
    });
  }


 // <!-- Add SubCategory  Model-->
  modalOpenVC(modalVC) {
    
    this.loadAddSub=false;
    
    this._Subcategoryserv.getAllCategory().subscribe(
      (res: any) => {
        this.category = res;
        
        console.log(this.category);        
        this.CreateSubCategoryFormSubmitted = false;
        this.CreateSubCategoryForm.reset();
        this.modalReference = this.modalService.open(modalVC, {
          backdrop: false,
          centered: true,
        });
        this.loadAddSub=true;
        
      },
      (er: any) => {
        this.loadAddSub=true;
        Swal.fire({
          position: "center",
          icon: "error",
          title: "An Error Occurred While Get Category ",
          showConfirmButton: false,
          timer: 1500,
        });
      
        
      }
    );
  }
    // <!-- Add SubCategory  -->
    AddSubCategoryMethod() {
      this.CreateSubCategoryFormSubmitted = true;
  
      if (this.CreateSubCategoryForm.invalid) {
        return;
      }
  
      if (this.file) {
        var formData = new FormData();
        formData.append("image",this.file);
        formData.append("name_en",this.CreateSubCategoryControls.name_en.value);
        formData.append("name_ar",this.CreateSubCategoryControls.name_ar.value);
        formData.append("parent_id",this.CreateSubCategoryControls.parent_id.value);
        formData.append("is_parent","1");
      }
      
  
      this._Subcategoryserv.addSubCategory(formData).subscribe(
          (re: any) => {
            this.modalReference.close();
            if(re.message == "category saved successfully"){
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Sub Category Has been Added Successfully ",
                showConfirmButton: false,
                timer: 1500,
              });
              this.getAllSubCategory();
            }
          },
  
          (er: any) => {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "An Error Occurred While Add Sub Category !",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        );
    }


  // <!-- Add SubCategory  (Sub El Sub)  -->
  ReactiveFormOnSubmit() {
    console.log("Reactive Form OnSubmit - Parent ID:", this.subcategory_id);
    this.ReactiveOrderStatusFormSubmitted = true;

    if (this.ReactiveOrderStatusFormAddThird.invalid) {
      console.log("Invalid Form Control:", this.ReactiveOSFormThird);

      return;
    }

    if (this.file) {
      console.log("Reactive Form OnSubmit - IF:", this.subcategory_id);
      var formData = new FormData();
      formData.append("image",this.file);
      formData.append("name_en",this.ReactiveOSFormThird.name_en.value);
      formData.append("name_ar",this.ReactiveOSFormThird.name_ar.value);
      formData.append("parent_id",this.subcategory_id.toString());
      formData.append("is_parent","0");
    }
    
    console.log("Reactive Form OnSubmit - formData:", formData);

    this._Subcategoryserv.addSubCategory(formData).subscribe(
        (re: any) => {
          if(re.message == "category saved successfully"){
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Sub Category Has been Added Successfully ",
              showConfirmButton: false,
              timer: 1500,
            });
            this.modalReferenceAddSubCategoryThird.close();
            this.getAllSubCategory();
          }
        },

        (er: any) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "An Error Occurred While Add Sub Category !",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      );
  }

  // <!-- Add SubCategory Model  -->
modalAddThirdSubCategory(modalSubThirdCat, id) {
  this.subItems = [{subItemName: ''}];
  this.ReactiveOrderStatusFormSubmitted = false;
  this.ReactiveOrderStatusFormAddThird.reset();
  this.modalReferenceAddSubCategoryThird = this.modalService.open(modalSubThirdCat, {
    backdrop: false,
    centered: true,
  });
  

  this.subcategory_id = id;
}

  //<!--  Delete SubCategory -->
  DeleteSubCategory(id: number, name: string) {
    console.log(id);

    Swal.fire({
      title: `Are you sure Want To Delete Sub Category : ${name} ?`,
      text: "You won't be able to revert this!",
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#4c54f5",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes , Delete it",
    }).then((result) => {
      if (result.isConfirmed) {
        this._Subcategoryserv.DeleteSubCategory(id).subscribe(
          (re: any) => {
            this.getAllSubCategory();
            Swal.fire(
              "Deleted!",
              "Sub Category has been Deleted Successfully .",
              "success"
            );
          },
          (err: any) => {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "An Error Occurred While Deleting Sub Category",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        );
      }
    });
  }


  // <!-- Add featuer Model -->
  modalAddFeature(modalCreateFeature: any, id: number) {
    this.items = [{ itemName_en: '', itemName_ar: '' }];
    this.subcategory_id = id;
  
    this.modalReference4 = this.modalService.open(modalCreateFeature, {
      backdrop: false,
      centered: true,
    });
    console.log("Modal opened with subcategory ID:", id);
  }
  
  

  // <!-- Add featuer  -->
  AddFeatureMethod() {
    this.loading = true;
  // this.Category_Id= id    

    const features = this.items.map(item => {
      return {
        name_en: item.itemName_en,
        name_ar: item.itemName_ar
      };
    });
    const data = {
      features: features,
      category_id: this.subcategory_id
    };
    
    console.log(features);
    console.log(this.subcategory_id);
    
  
    this._Subcategoryserv.addSubFeature(data)
      .subscribe(
        (re: any) => {
          console.log(re.message)
          console.log(re)
          this.loading = false;

          Swal.fire({
            position: "center",
            icon: "success",
            title: "Features Have Been Added Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          this.getAllSubCategory();
          this.modalReference4.close(); 
        },
        (er: any) => {
          this.loading = false;
          Swal.fire({
            position: "center",
            icon: "error",
            title: "An Error Occurred While Adding Features!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      );
  }




  
  // <!-- Update Sub Category -->
  ReactiveUpdateSubCatFormOnSubmit() {
    this.ReactiveOrderStatusFormSubmitted = true;
  
  
    // Check if the form is valid before proceeding
    if (this.ReactiveOSForm.invalid) {
      return;
    }

// Prepare formData
var formData = new FormData();
  
formData.append("name_en", this.ReactiveOSForm.name_en.value);
formData.append("name_ar", this.ReactiveOSForm.name_ar.value);
formData.append("parent_id", this.ReactiveOSForm.parent_id.value);
formData.append("_method", "PUT");


    this._Subcategoryserv
      .updateSubCategory(formData , this.UpdatedCategory_id).subscribe(
        (re: any) => {
          this.getAllSubCategory();
          this.modalReference3.close();
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Sub Category Has been Updated Successfully ",
            showConfirmButton: false,
            timer: 1500,
          });
        },

        (er: any) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "An Error Occurred While Updated Sub Category !",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      );
  }


  // <!-- Update Category Model-->
    modalOpenUpdateSubC(modalSubC ,UpdatedCategory_id , UpdatedCategory_name_ar ,UpdatedCategory_name_en ) {

      this.UpdatedCategory_id=UpdatedCategory_id;      
  
      this._Subcategoryserv.getAllCategory().subscribe(
        (res: any) => {
          this.category = res;
          
          this.ReactiveOrderStatusFormSubmitted = false;
          this.ReactiveOrderStatusForm.reset();
          this.modalReference3 = this.modalService.open(modalSubC, {
            backdrop: false,
            centered: true,
          });
          
        },
        (er: any) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "An Error Occurred While Get Category ",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      );
  
    }





  



}
