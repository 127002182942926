<div  *ngIf="!loader" class="content-wrapper container-xxl overflow-hidden p-0 mt-3">
    <div class="content-detached ">
      <div class="content-body">

         <!-- E-commerce Head Section Starts -->
         <section id="ecommerce-searchbar" class="ecommerce-searchbar my-3">
          <div class="card p-4 shadow-sm">
            <h5 class="card-title mb-3">Search Products</h5>
            <div class="row g-3">
              <!-- Search by Product Name -->
              <div class="col-md-4">
                <div class="input-group input-group-merge">
                  <input
                    type="text"
                    class="form-control search-product py-3"
                    placeholder="Search by Product Name"
                    aria-label="Search by Product Name"
                    [(ngModel)]="searchText"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"><i data-feather="search" class="text-muted"></i></span>
                  </div>
                </div>
              </div>
        
              <!-- Search by Category -->
              <div class="col-md-4">
                <div class="input-group input-group-merge">
                  <input
                    type="text"
                    class="form-control search-product py-3"
                    placeholder="Search by Category"
                    aria-label="Search by Category"
                    [(ngModel)]="searchCategory"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"><i data-feather="list" class="text-muted"></i></span>
                  </div>
                </div>
              </div>
        
              <!-- Search by Price Range -->
              <div class="col-md-4">
                <div class="input-group input-group-merge">
                  <input
                    type="number"
                    class="form-control search-product py-3"
                    placeholder="Search by Maximum Price"
                    aria-label="Search by Price"
                    [(ngModel)]="searchPrice"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"><i data-feather="dollar-sign" class="text-muted"></i></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        

          <!-- Product Display Section -->
      <section id="product-display" class="my-3">
        <div class="row">
          <div
            class="col-md-4 col-sm-6"
            *ngFor="let product of products | filter: searchText"
          >
            <div class="card">
              <img
                [src]="product.product_image"
                class="card-img-top"
                alt="{{ product.product_name }}"
              />
              <div class="card-body">
                <h5 class="card-title">{{ product.product_name }}</h5>
                <p class="card-text">
                  {{ product.description }}
                </p>
                <p class="card-text font-weight-bold">
                  Price: {{ product.product_price | currency }}
                </p>
                <button class="btn btn-primary">Add to Cart</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      
         <!-- E-commerce Pagination Starts -->
         <section id="ecommerce-pagination">
          <div class="row">
            <div class="col-sm-12">
              <div class="d-flex justify-content-center">
                <ngb-pagination
                  [collectionSize]="totalProducts"
                  [(page)]="page"
                  [pageSize]="pageSize"
                  [maxSize]="5"
                  [rotate]="true"
                  [ellipses]="true"
                  [boundaryLinks]="true"
                  (pageChange)="changePage(page)"
                >
                  <!-- Custom pagination icons -->
                  <ng-template ngbPaginationPrevious>
                    <span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'" [size]="18"></span>
                  </ng-template>
                  <ng-template ngbPaginationFirst
                    ><span [data-feather]="'chevrons-left'" [class]="'font-weight-bolder'" [size]="18"></span>
                  </ng-template>
                  <ng-template ngbPaginationNext
                    ><span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'" [size]="18"></span
                  ></ng-template>
                  <ng-template ngbPaginationLast
                    ><span [data-feather]="'chevrons-right'" [class]="'font-weight-bolder'" [size]="18"></span>
                  </ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
        </section>
         <!-- E-commerce Pagination Ends -->

 


      </div>
    </div>
  </div>
