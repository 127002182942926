import { ColumnMode } from "@swimlane/ngx-datatable";
import { Component, OnInit } from "@angular/core";
import { HomeCategoriesService } from "../home-categories.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import Swal from "sweetalert2";

@Component({
  selector: "app-home-banners",
  templateUrl: "./home-banners.component.html",
  styleUrls: ["./home-banners.component.scss"],
})
export class HomeBannersComponent implements OnInit {
  isLoading: boolean = false;
  rows: any;
  tempData: any;
  selectedOption: number = 10;
  searchValue = "";
  ColumnMode = ColumnMode;
  AddNewBannerForm: UntypedFormGroup;
  AddNewBannerFormSubmitted = false;
  modalReference: any;
  fileNames: string[] = [];
  uploadedFiles: File[] = [];
  changeIndexFormSubmitted;
  changeIndexForm: UntypedFormGroup;
  modalReference1;
  currentId: number;

  constructor(
    private _HomeCategoriesServ: HomeCategoriesService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder
  ) {
    this.AddNewBannerForm = this.formBuilder.group({
      image: ["", Validators.required],
      title: [""],
    });
    this.changeIndexForm = this.formBuilder.group({
      new_index: ["",[  Validators.required,  Validators.min(0),   Validators.pattern("^[0-9]*$"), ],],
    });
  }

  ngOnInit(): void {
    this.getAllBanners();
  }

  filterUpdate(e: any) {}

  // Get all Catogray
  getAllBanners() {
    this._HomeCategoriesServ.GetBanners().subscribe(
      (res: any) => {
        this.rows = res;
        console.log(this.rows);
      },
      (er: any) => {
        console.log(er);
      }
    );
  }

  ModelAddNewBanners(modalAddNewBanners) {
    setTimeout(() => {
      this.isLoading = false;
      // Reset Form only if modal is about to open
      this.AddNewBannerFormSubmitted = false;
      this.AddNewBannerForm.reset();

      // Open modal
      this.modalReference = this.modalService.open(modalAddNewBanners, {
        centered: true,
        backdrop: "static",
        keyboard: false,
      });
    }, 300);
  }

  onFileUpload(event: any): void {
    const files = event.target.files;
    const maxFileSize = 1 * 1024 * 1024; // 1MB in bytes

    // Reset arrays
    this.fileNames = [];
    this.uploadedFiles = [];

    if (files && files.length > 0) {
      const file = files[0]; // Only take the first file

      // Check if it's an image
      if (!file.type.startsWith("image/")) {
        console.error(`File ${file.name} is not an image`);
        // Alert the user
        alert("Please select an image file.");
        event.target.value = ""; // Reset file input
        return;
      }

      // Check file size
      if (file.size > maxFileSize) {
        console.error(`File ${file.name} exceeds the maximum size of 1MB`);
        // Alert the user
        alert("The image must be smaller than 1MB.");
        event.target.value = ""; // Reset file input
        return;
      }

      // If valid image and size, add to arrays
      this.fileNames.push(file.name);
      this.uploadedFiles.push(file);

      // Update form control
      this.AddNewBannerForm.get("images").setValue(this.uploadedFiles);
      this.AddNewBannerForm.get("images").updateValueAndValidity();

      console.log(
        `File accepted: ${file.name}, Size: ${(file.size / 1024 / 1024).toFixed(
          2
        )}MB`
      );
    } else {
      this.AddNewBannerForm.get("images").setValue(null);
      this.AddNewBannerForm.get("images").markAsPristine();
    }
  }
  addNewBannerMethod() {
    this.AddNewBannerFormSubmitted = true;
    if (this.AddNewBannerForm.invalid) {
      return;
    }

    const formData = new FormData();
    const bannerText = this.AddNewBannerForm.get("title").value;
    formData.append("title", bannerText);

    // Use the files you've already stored in uploadedFiles array instead of accessing DOM
    if (this.uploadedFiles && this.uploadedFiles.length > 0) {
      for (let i = 0; i < this.uploadedFiles.length; i++) {
        formData.append("image", this.uploadedFiles[i]);
      }
    }

    this.isLoading = true;
    this._HomeCategoriesServ.AddBanner(formData).subscribe(
      (res: any) => {
        // Success alert
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Banner added successfully",
          confirmButtonColor: "#7367F0",
        });

        this.modalReference.close();
        this.getAllBanners();
        this.isLoading = false;
      },
      (er: any) => {
        // Error alert
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: er.error?.message || "Failed to add banner. Please try again.",
          confirmButtonColor: "#EA5455",
        });

        console.log(er);
        this.isLoading = false;
      }
    );
  }

  deleteBanner(bannerId: string) {
    console.log(bannerId);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed, proceed with deletion
        this.isLoading = true;

        this._HomeCategoriesServ.DeleteBanner(bannerId).subscribe(
          (res: any) => {
            // Success alert
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "Banner has been deleted successfully.",
              confirmButtonColor: "#7367F0",
            });

            // Refresh banner list
            this.getAllBanners();
            this.isLoading = false;
          },
          (er: any) => {
            // Error alert
            Swal.fire({
              icon: "error",
              title: "Error!",
              text:
                er.error?.message ||
                "Failed to delete banner. Please try again.",
              confirmButtonColor: "#EA5455",
            });

            console.log(er);
            this.isLoading = false;
          }
        );
      }
    });
  }

  EditBanner() {
    // Retrieve new_index from the form
    const new_index = this.changeIndexForm.get("new_index")?.value;

    if (!new_index) {
      Swal.fire({
        title: "Error!",
        text: "Please provide a new index.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }
    this.isLoading = true;
    const payload = {
      banner_id: this.currentId, // Use the passed id instead of this.currentId
      new_index: new_index,
    };
    console.log(payload);

    this._HomeCategoriesServ.ChangeBannerOrder(payload).subscribe({
      next: (response) => {
        Swal.fire({
          title: "Success!",
          text: "Banner order changed successfully.",
          icon: "success",
          confirmButtonText: "OK",
        });
        this.getAllBanners();
        this.modalReference1.close();
        this.isLoading = false; // Re-enable button after success
      },
      error: (error) => {
        Swal.fire({
          title: "Error!",
          text: "Failed to change banner order.",
          icon: "error",
          confirmButtonText: "Try Again",
        });
        this.isLoading = false; // Re-enable button after success
      },
    });
  }

  ModelChangeIndex(modelChangeIndex, id: any) {
    this.currentId = id;
    console.log(this.currentId);
    setTimeout(() => {
      this.isLoading = false;
      // Reset Form only if modal is about to open
      this.changeIndexFormSubmitted = false;
      this.changeIndexForm.reset();

      // Open modal
      this.modalReference1 = this.modalService.open(modelChangeIndex, {
        centered: true,
        backdrop: "static",
        keyboard: false,
      });
    }, 300);
  }
}
