<div
  *ngIf="!loader"
  class="content-wrapper container-xxl overflow-hidden p-0 mt-3"
>
  <div class="content-detached">
    <div class="content-body">
      <!-- E-commerce Head Section Starts -->
      <section id="ecommerce-searchbar" class="ecommerce-searchbar my-3">
        <div class="card p-4 shadow-sm">
          <h5 class="card-title mb-3">Search Products</h5>
          <div class="row g-3">
            <!-- Search by Product Name -->
            <div class="col-md-4">
              <div class="input-group input-group-merge">
                <input
                  type="text"
                  class="form-control search-product py-3"
                  placeholder="Search by Product Name"
                  aria-label="Search by Product Name"
                  [(ngModel)]="searchText"
                />
                <div class="input-group-append">
                  <span class="input-group-text"
                    ><i data-feather="search" class="text-muted"></i
                  ></span>
                </div>
              </div>
            </div>

            <!-- Search by Category -->
            <div class="col-md-4">
              <div class="input-group input-group-merge">
                <input
                  type="text"
                  class="form-control search-product py-3"
                  placeholder="Search by Category"
                  aria-label="Search by Category"
                  [(ngModel)]="searchCategory"
                />
                <div class="input-group-append">
                  <span class="input-group-text"
                    ><i data-feather="list" class="text-muted"></i
                  ></span>
                </div>
              </div>
            </div>

            <!-- Search by Price Range -->
            <div class="col-md-4">
              <div class="input-group input-group-merge">
                <input
                  type="number"
                  class="form-control search-product py-3"
                  placeholder="Search by Maximum Price"
                  aria-label="Search by Price"
                  [(ngModel)]="searchPrice"
                />
                <div class="input-group-append">
                  <span class="input-group-text"
                    ><i data-feather="dollar-sign" class="text-muted"></i
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Product Display Section -->
      <section class="temp-details">
        <div class="card  temp-details-color">
          <!-- Product Details starts -->
          <div class="card-body">
            <div class="row my-2">
              <!-- left Side   -->
              <div
                class="col-12 col-md-5 d-flex align-items-center justify-content-center mb-2 mb-md-0"
              >
                <div class="card-body">
                  <swiper class="swiper-coverflow swiper-container">
                    <ng-container>
                      <img
                        class="img-fluid"
                        [src]="tempDetails?.images"
                        alt="Product Image"
                      />
                    </ng-container>
                  </swiper>
                </div>
              </div>

              <!-- Right Info  -->
              <div class="col-12 col-md-7">
                <div class="right-side p-3 shadow-lg rounded border border-3">
                  <!-- Product Name -->
                  <div class="">
                    <h6 class="h3 lead card-text">
                      Product Name :
                      <a
                        href="javascript:void(0)"
                        class="company-name text-info"
                      >
                        {{ tempDetails?.name_en }}
                      </a>
                    </h6>
                  </div>
                  <br />

                  <!-- Store Name -->
                  <div class="">
                    <h6 class="h3 lead card-text">
                      Store Name :
                      <a
                        href="javascript:void(0)"
                        class="company-name text-info"
                      >
                        {{ tempDetails?.store?.name }}
                      </a>
                    </h6>
                  </div>
                  <br />

                  <!-- Product Price  -->
                  <div class="d-flex align-items-center">
                    <h6 class="h3 lead card-text mb-0">
                      Product Price:
                      <span class="fw-bold text-info"
                        >{{ tempDetails?.product_price }} YER</span
                      >
                      <ng-container *ngIf="tempDetails?.has_discount">
                        <span
                          class="text-muted text-decoration-line-through mx-2 small"
                        >
                          {{ tempDetails?.discounted_price }} YER
                        </span>
                        <span class="badge bg-danger ms-2"
                          >-{{ tempDetails?.discount_percentage }}%</span
                        >
                      </ng-container>
                    </h6>
                  </div>
                  <br />

                  <!-- Product Description -->
                  <div>
                    <h6 class="h3 lead card-text">
                      Description:
                      <a class="text-info">{{ tempDetails?.description_en }}</a>
                    </h6>
                  </div>
                  <br />

                  <!-- Product SKUs Table -->
                  <div *ngIf="tempDetails?.product_skus?.length">
                    <h4 class="text-info">Available SKUs:</h4>
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>SKU</th>
                          <th>Price (YER)</th>
                          <th>Quantity</th>
                          <th>Features</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let sku of tempDetails?.product_skus">
                          <td>{{ sku.sku }}</td>
                          <td>{{ sku.price }}</td>
                          <td>{{ sku.quantity }}</td>
                          <td>
                            <ul class="list-unstyled mb-0">
                              <li *ngFor="let feature of sku.feature_values">
                                <span
                                  *ngIf="
                                    feature.feature === 'Color';
                                    else normalFeature
                                  "
                                >
                                  <strong>{{ feature.feature }}:</strong>
                                  <span
                                    class="color-box"
                                    [style.background]="feature.value_en"
                                    [title]="feature.value_en"
                                  ></span>
                                </span>
                                <ng-template #normalFeature>
                                  <strong>{{ feature.feature }}:</strong>
                                  {{ feature.value_en }}
                                </ng-template>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <br />

                  <!-- Additional Features -->
                  <div *ngIf="tempDetails?.product_additional_features?.length">
                    <h4 class="text-info">Additional Features:</h4>
                    <ul class="list-group">
                      <li
                        class="list-group-item"
                        *ngFor="
                          let feature of tempDetails?.product_additional_features
                        "
                      >
                        <strong class="text-info">
                          {{ feature.name_en }}:</strong
                        >
                        {{ feature.value_en }}
                      </li>
                    </ul>
                  </div>
                  <br />

                  <!-- Button  -->
                  <div class="d-flex justify-content-center">
                    <!-- Accept Button -->
                    <button
                      *ngIf="
                        tempDetails.status === 'new_create' ||
                        tempDetails.status === 'rejected'
                      "
                      class="btn btn-success w-25 mx-1"
                      (click)="acceptAction(tempDetails.product_id)"
                    >
                      Accept
                    </button>

                    <!-- Reject Button -->
                    <button
                      *ngIf="tempDetails.status === 'new_create'"
                      class="btn btn-danger w-25 mx-1"
                      (click)="
                        RejectAction(
                          tempDetails.product_id,
                          tempDetails.name_en
                        )
                      "
                    >
                      Reject
                    </button>
                    <button
                      disabled
                      *ngIf="tempDetails.status === 'accepted'"
                      class="btn btn-success w-25 mx-1"
                    >
                      Accepted
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>

<!-- ///////////////////////////////loader//////////////////// -->
<div *ngIf="loader" id="loader-wrapper">
  <div id="loader"></div>
  <span *ngIf="loaders" class="loader"></span>
  <div class="loader-section section-left"></div>
  <div class="loader-section section-right"></div>
</div>
