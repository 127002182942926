<div class="content-wrapper container-xxl p-0">
  <div class="view-options">
    <!-- Refresh -->
    <button
      type="button"
      class="refresh-btn btn btn-outline-success me-5"
      (click)="refreshData()"
      title="Refresh Data"
    >
      <i data-feather="refresh-cw" class="font-medium-3"></i>
    </button>
  </div>
  <div class="content-body">
    <!-- app e-commerce details start -->
    <section class="app-ecommerce-details">
      <div class="card">
        <!-- Product Details starts -->
        <div class="card-body">
          <div class="row my-2">
            <!-- product Images  -->
            <div class="image-grid col-md-5">
              <ng-container
                *ngFor="let image of productData?.images; let i = index"
              >
                <div class="image-item">
                  <img class="img-fluid" [src]="image" alt="product image" />
                </div>
              </ng-container>
            </div>

            <!-- Description Product  -->
            <div class="col-12 col-md-7">
              <div class="">
                <h4>{{ productData?.name_en }}</h4>
                <h4>{{ productData?.name_ar }}</h4>
                <span class="card-text item-company">
                  Category
                  <a href="javascript:void(0)" class="company-name">
                    {{ productData?.category?.name }}
                  </a>
                </span>
              </div>
              <!-- Tags  -->
              <div>
                <span class="card-text item-company mt-1">
                  Tags
                  <ng-container *ngFor="let item of productData?.tags">
                    <a href="javascript:void(0)" class="text-info mx-2">{{
                      productData?.tags
                    }}</a>
                  </ng-container>
                </span>
              </div>

              <!-- price and Rate  -->
              <div class="ecommerce-details-price d-flex flex-wrap mt-1">
                <h4 class="item-price mr-1">
                  YER {{ productData?.product_price }}
                </h4>
                <div class="rating rating-sm pl-1 border-left mb-1">
                  <ngb-rating
                    [rate]="productData?.rate"
                    [readonly]="true"
                    [max]="5"
                    class="outline-none"
                  >
                    <ng-template let-fill="fill" let-index="index">
                      <span
                        class="fa fa-star-o mr-25"
                        [class.fa-star]="fill === 100"
                      >
                      </span>
                    </ng-template>
                  </ngb-rating>
                </div>
              </div>

              <!-- Quantity  -->
              <div>
                <p class="card-text">
                  Quantity :
                  <span class="text-info">
                    {{ getTotalQuantity() }}
                  </span>
                </p>
              </div>

              <!-- Total Reviews  -->
              <div class="Total Reviews">
                <p class="card-text">
                  Total Reviews :
                  <span class="text-info">
                    {{ productData?.store.total_reviews }}
                  </span>
                </p>
              </div>

              <!-- Condition  has_discount -->
              <div class="discount" *ngIf="productData?.has_discount == true">
                <ul class="product-features list-unstyled">
                  <li>
                    <span class="text-info">Discount</span>
                  </li>
                  <li>
                    <i data-feather="smile"></i>
                    <span>
                      Discount Percentage:
                      <span class="text-success">
                        {{ productData?.discount_percentage }}%
                      </span>
                    </span>
                  </li>
                  <li>
                    <i data-feather="smile"></i>
                    <div>
                      Price After Discount: YER
                      <span class="text-success">
                        {{ productData?.discounted_price }}
                      </span>
                    </div>
                  </li>
                </ul>
              </div>

              <!-- Description  -->
              <!-- <div class=" border-0"  style="width: 30rem">
                <div class="">
                  <h5 class=" text-info">Description:-</h5>
                  <p class="">

                    <strong class="text-success">English:- </strong>{{ productData?.description_en }}
                  </p>

                  <p class=" text-end" dir="rtl">
                     <strong class="text-success">Arabic:- </strong>{{ productData?.description_ar }}
                  </p>
                </div>
              </div> -->

              <!-- Condition  quantity -->
              <!-- <div class="div">
                <p *ngIf="product.product_quantity >= 1" class="card-text">
                Available - <span class="text-success">In stock</span>
                </p>
                <p *ngIf="product.product_quantity < 1" class="card-text">
                Not Available - <span class="text-warning">out of stock</span>
              </p>
              </div> -->
              <hr />
              <!-- Color -->
              <!-- <div class="product-color-options">
                <h6>Colors</h6>
                <ul class="list-unstyled mb-0">
                  <li class="d-inline-block selected">
                    <div class="color-option b-primary">
                      <div class="filloption bg-primary"></div>
                    </div>
                  </li>
                  <li class="d-inline-block">
                    <div class="color-option b-success">
                      <div class="filloption bg-success"></div>
                    </div>
                  </li>
                  <li class="d-inline-block">
                    <div class="color-option b-danger">
                      <div class="filloption bg-danger"></div>
                    </div>
                  </li>
                  <li class="d-inline-block">
                    <div class="color-option b-warning">
                      <div class="filloption bg-warning"></div>
                    </div>
                  </li>
                  <li class="d-inline-block">
                    <div class="color-option b-info">
                      <div class="filloption bg-info"></div>
                    </div>
                  </li>
                </ul>
              </div> -->

              <!-- <div class="product-Size-options">
                <h6>size</h6>
                <ul class="list-unstyled mb-0">
                  <li class="d-inline-block selected">
                    <div class="color-option b-primary">
                      M
                    </div>
                  </li> 
                  <span> & </span>
                  <li class="d-inline-block">
                    <div class="color-option b-success">
                      S
                    </div>
                  </li>
                  <span> & </span>
                  <li class="d-inline-block">
                    <div class="color-option b-success">
                      L
                    </div>
                  </li>
                  <span> & </span>
                  <li class="d-inline-block">
                    <div class="color-option b-success">
                      XL
                    </div>
                  </li>
                </ul>
              </div> -->
              <!-- Features  -->
              <!-- <div>
                <h2>Feature </h2>
                <ng-container >
                  <div class="product-color-options">
                    <h6></h6>
                    <ul class="list-unstyled mb-0">
                      <ng-container *ngFor="let value of feature.feature_values">
                        <li class="d-inline-block selected mx-2 ">
                          <div [style.background-color]="value.value" class="color-option b-primary px-2">
                            <div class=""></div>{{  }}
                          </div>
                        </li>
                      </ng-container>
                    </ul>
                  </div>
                </ng-container>
              </div>  -->

              <!-- Buttons   -->
              <div class="d-flex">
                <div class="d-flex flex-column flex-sm-row pt-1 mx-2">
                  <a
                    class="btn btn-outline-danger round"
                    rippleEffect
                    (click)="
                      removeProduct(
                        productData?.product_id,
                        productData?.name_en
                      )
                    "
                  >
                    <span class="">Delete Product</span>
                  </a>
                </div>
                <div class="d-flex flex-column flex-sm-row pt-1 mx-2">
                  <a
                    class="btn btn-outline-info round"
                    rippleEffect
                    (click)="
                      modelEditProduct(
                        modalEditProduct,
                        productData?.product_id,
                        productData?.name_en,
                        productData?.name_ar,
                        productData?.product_price,
                        productData?.has_discount,
                        productData?.discounted_price,
                        productData?.description_en,
                        productData?.description_ar
                      )
                    "
                  >
                    <span class="">Edit Product</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Product Details  -->
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>ID</th>
              <th>SKU</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Color</th>
              <th>Size</th>

              <!-- Dynamically display additional feature names as table headers -->
              <th
                *ngFor="let feature of productData?.product_additional_features"
              >
                {{ feature.name_en }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let sku of productData?.product_skus">
              <td>{{ sku.id }}</td>
              <td>{{ sku.sku }}</td>
              <td>YER {{ sku.price }}</td>
              <td>{{ sku.quantity }}</td>
              <td>
                <span *ngFor="let feature of sku.feature_values">
                  <span
                    *ngIf="feature.feature === 'Color'"
                    [ngStyle]="{ 'background-color': feature.value }"
                  >
                    {{ feature.value_en || feature.value }}
                  </span>
                </span>
              </td>
              <td>
                <span *ngFor="let feature of sku.feature_values">
                  <span *ngIf="feature.feature === 'Size'">
                    {{ feature.value_en || feature.value }}
                  </span>
                </span>
              </td>

              <!-- Dynamically display additional feature values as table data cells -->
              <td
                *ngFor="
                  let additionalFeature of productData?.product_additional_features
                "
              >
                {{ additionalFeature.value_en }}
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Item features starts -->
        <div class="item-features">
          <div class="row text-center">
            <div class="col-12 col-md-4 mb-4 mb-md-0">
              <div class="w-75 mx-auto">
                <i data-feather="award"></i>
                <h4 class="mt-2 mb-1">100% Original</h4>
                <div class="">
                  <p class="card-text item-company">
                    {{ productData?.description_en }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-4 mb-4 mb-md-0">
              <div class="w-75 mx-auto">
                <i data-feather="clock"></i>
                <h4 class="mt-2 mb-1">10 Day Replacement</h4>
                <p
                  class="card-text"
                  *ngFor="let items of productData?.product_additional_features"
                >
                  <!-- {{items.value_en}} - {{items.value_ar}} -->
                </p>
              </div>
            </div>

            <div class="col-12 col-md-4 mb-4 mb-md-0">
              <div class="w-75 mx-auto">
                <i data-feather="shield"></i>
                <h4 class="mt-2 mb-1">1 Year Warranty</h4>
                <p class="card-text">
                  {{ productData?.description_ar }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Related Products starts -->

        <!-- Related Products ends -->
      </div>
    </section>
    <!-- app e-commerce details end -->
  </div>
</div>

<!-- Edit Product Model  -->
<ng-template #modalEditProduct let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalCenterTitle">Edit Product</h5>
    <button
      id="clo"
      type="button"
      class="close"
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <form
      *ngIf="updateProductForm"
      [formGroup]="updateProductForm"
      novalidate
      autocomplete="off"
      id="fo"
    >
      <div class="row">
        <!-- Select Option -->
        <div class="form-group col-md-12">
          <label>Select Option:</label>
          <select
            formControlName="selectedOption"
            class="form-control"
            (change)="onOptionChange($event)"
          >
            <option value="" disabled>Select an option</option>
            <option value="Data">Data</option>
            <option value="feature" (click)="getSubFeatures()">Feature</option>
            <option value="addition-feature">addition Feature</option>
            <option value="image">Image</option>
          </select>
        </div>

        <!-- Data Forms -->
        <ng-container *ngIf="selectedOption === 'Data'">
          <!-- Product Name En Form -->
          <div class="form-group col-md-6">
            <label>Product Name (English):</label>
            <input
              formControlName="name_en"
              type="text"
              placeholder="Name (English)"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  updateProductFormSubmitted &&
                  updateProductForm.get('name_en')?.errors
              }"
            />
            <div
              *ngIf="
                updateProductForm.get('name_en')?.invalid &&
                updateProductFormSubmitted
              "
              class="invalid-feedback"
            >
              <div *ngIf="updateProductForm.get('name_en')?.errors?.required">
                Product Name (English) is required.
              </div>
            </div>
          </div>

          <!-- Product Name Ar Form -->
          <div class="form-group col-md-6">
            <label>Product Name (Arabic):</label>
            <input
              formControlName="name_ar"
              type="text"
              placeholder="Name (Arabic)"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  updateProductFormSubmitted &&
                  updateProductForm.get('name_ar')?.errors
              }"
            />
            <div
              *ngIf="
                updateProductForm.get('name_ar')?.invalid &&
                updateProductFormSubmitted
              "
              class="invalid-feedback"
            >
              <div *ngIf="updateProductForm.get('name_ar')?.errors?.required">
                Product Name (Arabic) is required.
              </div>
            </div>
          </div>

          <!-- Product Description En Form -->
          <div class="form-group col-md-6">
            <label>Product Description (English):</label>
            <textarea
              formControlName="description_en"
              placeholder="Description (English)"
              class="form-control"
              style="resize: none"
              [ngClass]="{
                'is-invalid':
                  updateProductFormSubmitted &&
                  updateProductForm.get('description_en')?.errors
              }"
            ></textarea>
            <div
              *ngIf="
                updateProductForm.get('description_en')?.invalid &&
                updateProductFormSubmitted
              "
              class="invalid-feedback"
            >
              <div
                *ngIf="
                  updateProductForm.get('description_en')?.errors?.required
                "
              >
                Product Description (English) is required.
              </div>
            </div>
          </div>

          <!-- Product Description Ar Form -->
          <div class="form-group col-md-6">
            <label>Product Description (Arabic):</label>
            <textarea
              formControlName="description_ar"
              placeholder="Description (Arabic)"
              class="form-control"
              style="resize: none"
              [ngClass]="{
                'is-invalid':
                  updateProductFormSubmitted &&
                  updateProductForm.get('description_ar')?.errors
              }"
            ></textarea>
            <div
              *ngIf="
                updateProductForm.get('description_ar')?.invalid &&
                updateProductFormSubmitted
              "
              class="invalid-feedback"
            >
              <div
                *ngIf="
                  updateProductForm.get('description_ar')?.errors?.required
                "
              >
                Product Description (Arabic) is required.
              </div>
            </div>
          </div>

          <!-- Price Form -->
          <div class="form-group col-md-6">
            <label>Price:</label>
            <input
              formControlName="product_price"
              type="number"
              placeholder="Price"
              class="form-control"
            />
          </div>

          <!-- Discount Form -->
          <div class="form-group col-md-6">
            <label>Discount:</label>
            <select
              formControlName="discount"
              class="form-control"
              (change)="onDiscountChange($event)"
            >
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
          </div>

          <!-- Discounted Input -->
          <div class="form-group col-md-6" *ngIf="has_discount == 1">
            <label>Discounted Price:</label>
            <input
              formControlName="discounted_price"
              type="number"
              placeholder="Enter discounted price"
              class="form-control"
            />
          </div>
        </ng-container>

        <!-- Image  -->
        <ng-container *ngIf="selectedOption === 'image'">
          <!-- Image Upload -->
          <div class="image-grid col-md-12">
            <ng-container
              *ngFor="let image of productData?.images; let i = index"
            >
              <div class="image-item position-relative">
                <!-- Trash Icon -->
                <button
                  class="btn btn-outline-danger btn-sm position-absolute top-0 end-0 m-2"
                  (click)="removeImage(i)"
                  *ngIf="selectedOption === 'image'"
                  [disabled]="isLoading"
                >
                  <i data-feather="trash" class="mb-1"></i>
                </button>

                <!-- Image -->
                <img class="img-fluid" [src]="image" alt="product image" />
              </div>
            </ng-container>
          </div>

          <!-- File Upload -->
          <div class="mt-2 col-12">
            <label
              for="imageUpload"
              class="btn col-12 text-center font-medium-5 text-uppercase file-drop-zone"
            >
              {{
                fileNames?.length
                  ? fileNames.join(", ")
                  : "No file uploaded yet."
              }}
            </label>
            <input
              (change)="onFileUpload($event)"
              type="file"
              id="imageUpload"
              accept="image/*"
              multiple
              style="display: none"
              [ngClass]="{
                'is-invalid':
                  createProductFormSubmitted &&
                  createProductForm.get('images')?.errors
              }"
            />
            <!-- <div
                *ngIf="
                  createProductForm.get('images') &&
                  createProductFormSubmitted &&
                  createProductForm.get('images')?.errors
                "
                class="invalid-feedback"
              >
                <div *ngIf="createProductForm.get('images')?.errors?.required">
                  Image is required
                </div>
              </div> -->
          </div>
        </ng-container>
      </div>
    </form>

    <!-- Feature  -->
    <ng-container *ngIf="selectedOption === 'feature'">
      <!-- Feature Form -->
      <div class="modal-body" tabindex="1" ngbAutofocus>
        <div *ngFor="let feature of features; let i = index">
          <div class="row">
            <!-- First Feature Selection -->
            <div class="form-group col-md-6">
              <label for="firstFeature{{ i }}"
                >Select First Feature {{ i + 1 }}</label
              >
              <select
                class="form-control"
                [(ngModel)]="feature.firstFeature"
                name="firstFeature{{ i }}"
                id="firstFeature{{ i }}"
              >
                <option value="" disabled>Select Feature</option>
                <ng-container *ngFor="let Feature of SubFeatures">
                  <ng-container *ngIf="!Feature.is_color">
                    <option
                      *ngFor="let last of Feature.values"
                      [value]="last.id"
                    >
                      {{ last.value }}
                    </option>
                  </ng-container>
                </ng-container>
              </select>
            </div>

            <!-- Second Feature Selection -->
            <div class="form-group col-md-6">
              <label for="secondFeature{{ i }}"
                >Select Second Feature {{ i + 1 }}</label
              >
              <select
                class="form-control"
                [(ngModel)]="feature.secondFeature"
                name="secondFeature{{ i }}"
                id="secondFeature{{ i }}"
              >
                <option value="" disabled>Select Feature</option>
                <ng-container *ngFor="let Feature of SubFeatures">
                  <ng-container *ngIf="Feature.is_color">
                    <option
                      *ngFor="let last of Feature.values"
                      [value]="last.id"
                      [ngStyle]="{ 'background-color': last.value }"
                    >
                      {{ last.value }}
                    </option>
                  </ng-container>
                </ng-container>
              </select>
            </div>

            <!-- Price Input -->
            <div class="form-group col-md-6">
              <label for="price{{ i }}">Price for Feature {{ i + 1 }}</label>
              <input
                class="form-control"
                type="number"
                [(ngModel)]="feature.price"
                name="price{{ i }}"
                placeholder="Price"
                id="price{{ i }}"
              />
            </div>

            <!-- Quantity Input -->
            <div class="form-group col-md-6">
              <label for="quantity{{ i }}"
                >Quantity for Feature {{ i + 1 }}</label
              >
              <input
                class="form-control"
                type="number"
                [(ngModel)]="feature.quantity"
                name="quantity{{ i }}"
                placeholder="Quantity"
                id="quantity{{ i }}"
              />
            </div>

            <!-- Delete Button -->
            <div class="col-md-12">
              <button
                *ngIf="i !== 0"
                class="btn btn-outline-danger text-nowrap px-1"
                (click)="deleteFeature(i)"
              >
                <i data-feather="x" class="mr-25"></i>
                <span>Delete</span>
              </button>
            </div>
          </div>
          <hr />
        </div>
      </div>

      <!-- Add Another Feature Button -->
      <div class="col-12">
        <button
          class="btn btn-icon btn-primary"
          type="button"
          (click)="addFeature()"
        >
          <i data-feather="plus" class="mb-1"></i>
          <span>Add another feature</span>
        </button>
      </div>
    </ng-container>

    <!-- Additional Features -->
    <ng-container *ngIf="selectedOption === 'addition-feature'">
      <div class="modal-body" tabindex="1" ngbAutofocus>
        <div *ngFor="let additional of additional_features; let i = index">
          <div class="row">
            <!-- Additional Features Name En  Input -->
            <div class="form-group col-md-6 w-100">
              <label for="Name En{{ i }}"
                >Additional Features Name En {{ i + 1 }}
              </label>
              <input
                class="form-control"
                type="text"
                [(ngModel)]="additional.name_en"
                name="name_en{{ i }}"
                placeholder="name_en"
                id="price{{ i }}"
                [cpOutputFormat]="'hex'"
                [cpPosition]="auto"
                [value]="additional.name_en"
              />
            </div>

            <!-- Additional Features Name Ar Input -->
            <div class="form-group col-md-6 w-100">
              <label for="Name En{{ i }}"
                >Additional Features Name Ar{{ i + 1 }}
              </label>
              <input
                class="form-control"
                type="text"
                [(ngModel)]="additional.name_ar"
                name="name_ar{{ i }}"
                placeholder="name_ar"
                id="price{{ i }}"
                [cpOutputFormat]="'hex'"
                [cpPosition]="auto"
                [value]="additional.name_ar"
              />
            </div>

            <!-- Additional Features Value En Input -->
            <div class="form-group col-md-6 w-100">
              <label for="Name En{{ i }}"
                >Additional Features Value En{{ i + 1 }}
              </label>
              <input
                class="form-control"
                type="text"
                [(ngModel)]="additional.value_en"
                name="value_en{{ i }}"
                placeholder="value_en"
                id="price{{ i }}"
                [cpOutputFormat]="'hex'"
                [cpPosition]="auto"
                [value]="additional.value_en"
              />
            </div>

            <!-- Additional Features Value Ar Input -->
            <div class="form-group col-md-6 w-100">
              <label for="Name En{{ i }}"
                >Additional Features Value Ar{{ i + 1 }}
              </label>
              <input
                class="form-control"
                type="text"
                [(ngModel)]="additional.value_ar"
                name="value_ar{{ i }}"
                placeholder="value_ar"
                id="price{{ i }}"
                [cpOutputFormat]="'hex'"
                [cpPosition]="auto"
                [value]="additional.value_ar"
              />
            </div>

            <!-- Delete Button -->
            <div class="col-md-12">
              <div class="form-group">
                <button
                  *ngIf="i != 0"
                  class="btn btn-outline-danger text-nowrap px-1"
                  (click)="deleteAdditionalFeature(i)"
                  rippleEffect
                >
                  <i data-feather="x" class="mr-25"></i>
                  <span>Delete</span>
                </button>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>

      <div class="col-12">
        <button
          class="btn btn-icon btn-primary"
          type="button"
          (click)="addAdditionalFeature()"
          rippleEffect
        >
          <i data-feather="plus" class="mb-1"></i>
          <span>Add Another Additional Feature</span>
        </button>
      </div>
    </ng-container>

    <!-- Button Submit  -->
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="modal.dismiss('Cancel')"
        (click)="cancelRequest()"
      >
        Cancel
      </button>
      <!-- Button for Save Data -->
      <button
        *ngIf="
          selectedOption === 'Data' || selectedOption === 'addition-feature'
        "
        type="button"
        class="btn btn-outline-success round ml-1"
        (click)="updateData()"
        rippleEffect
        [disabled]="isLoading"
      >
        <ng-container *ngIf="!isLoading"> Save Data </ng-container>
        <ng-container *ngIf="isLoading">
          <i class="fa fa-spinner fa-spin"></i>
          Saving...
        </ng-container>
      </button>

      <!-- Button for Save Features -->
      <button
        *ngIf="selectedOption === 'feature'"
        type="button"
        class="btn btn-outline-info round ml-1"
        (click)="updateFeature()"
        rippleEffect
        [disabled]="isLoading"
      >
        <ng-container *ngIf="!isLoading"> Save Features </ng-container>
        <ng-container *ngIf="isLoading">
          <i class="fa fa-spinner fa-spin"></i>
          Saving...
        </ng-container>
      </button>

      <!-- Button for Update Image -->
      <button
        *ngIf="selectedOption === 'image'"
        type="button"
        class="btn btn-outline-warning round ml-1"
        (click)="updateImage()"
        rippleEffect
        [disabled]="isLoading"
      >
        <ng-container *ngIf="!isLoading"> Update Image </ng-container>
        <ng-container *ngIf="isLoading">
          <i class="fa fa-spinner fa-spin"></i>
          Updating...
        </ng-container>
      </button>
    </div>
  </div>
</ng-template>
<!-- ///////////////////////////////loader//////////////////// -->
<div *ngIf="isLoading" id="loader-wrapper">
  <div id="loader"></div>
  <span *ngIf="loader" class="loader"></span>
  <div class="loader-section section-left"></div>
  <div class="loader-section section-right"></div>
</div>
