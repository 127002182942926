import { Component, OnInit } from "@angular/core";
import { AllReviewsService } from "../all-reviews.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ColumnMode } from "@swimlane/ngx-datatable";
import Swal from "sweetalert2";

@Component({
  selector: "app-all-reviews",
  templateUrl: "./all-reviews.component.html",
  styleUrls: ["./all-reviews.component.scss"],
})
export class AllReviewsComponent implements OnInit {
  public isLoading = false;
  public rows: any[] = [];
  public tempData: any;
  public selectedOption: number = 10;
  public loadAddCat = true;
  public searchValue = "";
  public ColumnMode = ColumnMode;

  public selectedStatus = "Pending"; // Default active status

  public reviewStatus = [
    { name: "Pending", value: "Pending" },
    { name: "Accepted", value: "Accepted" },
  ];

  public reviews: any;

  constructor(
    private _reviewService: AllReviewsService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.getPendingReviews();
    this.onStatusChange(this.selectedStatus);
    this.getAcceptedReviews();
  }

  onStatusChange(status: string): void {
    this.selectedStatus = status; // Update the selected status
    console.log(this.selectedStatus);
    this.isLoading = true;
    switch (status) {
      case "Pending":
        this.getPendingReviews();
        break;
      case "Accepted":
        this.getAcceptedReviews();
        break;
      default:
        this.isLoading = false;
        console.log("Unknown status:", status);
    }
  }

  getPendingReviews() {
    this.isLoading = true;
    this._reviewService.getAllReviews().subscribe({
      next: (response: any) => {
        this.isLoading = false;

        console.log("Reviews fetched successfully:", response);
        this.reviews = response.reviews;
        console.log(this.reviews);
      },
      error: (error: any) => {
        this.isLoading = false;

        console.error("Error fetching reviews:", error);
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Failed to fetch reviews. Please try again.",
          confirmButtonText: "OK",
        });
      },
    });
  }

  getAcceptedReviews() {
    this.isLoading = true;
    this._reviewService.getAcceptedReviews().subscribe({
      next: (response: any) => {
        this.isLoading = false;
        this.reviews = response.reviews;
        console.log(this.reviews);
      },
      error: (error: any) => {
        this.isLoading = false;
        console.error("Error fetching reviews:", error);
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Failed to fetch reviews. Please try again.",
          confirmButtonText: "OK",
        });
      },
    });
  }

  changeStatusReview(reviewId: number, statusId: number) {
    Swal.fire({
      title: "Are you sure?",
      text:
        statusId === 1
          ? "Do you want to accept this review?"
          : "Do you want to reject this review?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: statusId === 1 ? "Yes, Accept it!" : "Yes, Reject it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Prepare the request body
        const body = { status: statusId };

        this._reviewService.changeStatus(reviewId, body).subscribe({
          next: (res) => {
            console.log("Response:", res);

            // Show success message
            Swal.fire({
              icon: "success",
              title: "Success!",
              text:
                statusId === 1
                  ? "Review has been accepted successfully."
                  : "Review has been rejected.",
              confirmButtonColor: "#3085d6",
            }).then(() => {
              // Refresh the review list
              this.getPendingReviews();
            });
          },
          error: (err) => {
            console.error("Error updating review status:", err);

            // Show error message
            Swal.fire({
              icon: "error",
              title: "Error!",
              text: "Failed to update review status. Please try again.",
              confirmButtonColor: "#d33",
            });
          },
        });
      }
    });
  }

  deleteReview(reviewId: number) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this._reviewService.deleteReview(reviewId).subscribe({
          next: (res) => {
            console.log("Review deleted successfully:", res);

            // Show success message
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "Review has been deleted successfully.",
              confirmButtonColor: "#3085d6",
            }).then(() => {
              // Refresh the review list
              this.getAcceptedReviews();
            });
          },
          error: (err) => {
            console.error("Error deleting review:", err);

            // Show error message
            Swal.fire({
              icon: "error",
              title: "Error!",
              text: "Failed to delete review. Please try again.",
              confirmButtonColor: "#d33",
            });
          },
        });
      }
    });
  }

  // Optional helper method to toggle expanded state
  toggleExpand(review) {
    review.expanded = !review.expanded;
  }
}
