<div class="content-wrapper container-xxl p-0">
  <!-- Header with Refresh Button -->
  <div class="d-flex justify-content-between align-items-center mb-2">
    <h2 class="fw-bold">Product Details</h2>
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="refreshData()"
      title="Refresh Data"
    >
      <i data-feather="refresh-cw" class="m-1"></i>
      Refresh
    </button>
  </div>

  <div class="content-body">
    <section class="app-ecommerce-details main-card">
      <div class="card shadow p-2">
        <div class="card-bodyy">
          <div class="row">
            <div class="col-12 col-md-6 d-flex align-items-center justify-content-center">
              <div class="product-carousel w-100">
                <swiper class="swiper-coverflow swiper-container">
                  <!-- Main product images -->
                  <ng-container *ngFor="let image of productData?.images">
                    <img
                      class="img-fluid rounded"
                      [src]="image"
                      alt="Product Image"
                    />
                  </ng-container>
            
                  <!-- Color feature colored_images (avoid duplicates with main images) -->
                  <ng-container *ngFor="let sku of productData?.product_skus">
                    <ng-container *ngFor="let featureValue of sku.feature_values">
                      <ng-container *ngIf="featureValue.feature === 'Color'">
                        <ng-container *ngFor="let img of featureValue.colored_images">
                          <!-- Check if this image is not in the main images array -->
                          <img
                            *ngIf="!productData.images.includes(img.image)"
                            class="img-fluid rounded"
                            [src]="img.image"
                            alt="Colored Product Image"
                          />
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </swiper>
              </div>
            </div>

            <!-- Product Information - Right Column -->
            <div class="col-12 col-md-6 mt-5">
              <div class="product-info">
                <!-- Product Name -->
                <div class="mb-3">
                  <h3 class="fw-bold mb-1">{{ productData?.name_en }}</h3>
                  <h4 class="text-muted">{{ productData?.name_ar }}</h4>
                </div>

                <!-- Category and Tags -->
                <div class="mb-3">
                  <div class="d-flex align-items-center mb-2">
                    <span class="text-muted mx-2">Category:</span>
                    <span
                      class="badge bg-light-primary text-primary mt-1 mx-1"
                      >{{ productData?.category?.name }}</span
                    >
                  </div>

                  <!-- Tags -->
                  <div class="d-flex align-items-center flex-wrap">
                    <span class="text-muted mx-2">Tags:</span>

                    <div class="d-flex align-items-center">
                      <ngb-rating
                        [rate]="productData?.rate"
                        [readonly]="true"
                        [max]="5"
                        class="outline-none text-warning"
                      >
                        <ng-template let-fill="fill">
                          <i
                            class="fa"
                            [class.fa-star]="fill === 100"
                            [class.fa-star-o]="fill === 0"
                          ></i>
                        </ng-template>
                      </ngb-rating>
                      <span class="ms-1 text-success"
                        >({{ productData?.store.total_reviews }} reviews)</span
                      >
                    </div>
                  </div>
                </div>

                <!-- Price, Rating and Stock -->
                <div class="mb-3">
                  <div class="d-flex align-items-center mb-2">
                    <span class="text-muted mx-2">Price:</span>
                    <h4 class="mb-0 me-3 text-info">
                      YER {{ productData?.product_price }}
                    </h4>
                  </div>

                  <div class="d-flex align-items-center mb-1">
                    <span class="text-muted mx-2">Quantity:</span>
                    <ng-container *ngIf="getTotalQuantity() === 0">
                      <span class="badge bg-light-danger text-danger mt-1">
                        <i data-feather="alert-circle" class="me-1"></i>
                        Out of stock
                      </span>
                    </ng-container>
                    <ng-container *ngIf="getTotalQuantity() === 1">
                      <span class="badge bg-light-warning text-warning mt-1">
                        <i data-feather="alert-triangle" class="me-1"></i>
                        {{ getTotalQuantity() }} in stock - Low stock
                      </span>
                    </ng-container>
                    <ng-container *ngIf="getTotalQuantity() > 1">
                      <span class="badge bg-light-success text-success mt-1">
                        <i data-feather="check-circle" class="me-1"></i>
                        {{ getTotalQuantity() }} in stock
                      </span>
                    </ng-container>
                  </div>
                </div>

                <!-- Discount Section -->
                <div class="mb-3" *ngIf="productData?.has_discount">
                  <div class="card bg-light-warning border-0">
                    <div class="card-bodyy Special-Discount p-2">
                      <h5 class="mb-1 text-warning">
                        <i data-feather="tag" class="me-1"></i>
                        Special Discount
                      </h5>
                      <div
                        class="d-flex justify-content-between align-items-center mb-1"
                      >
                        <span>Discount:</span>
                        <span class="fw-bold text-success"
                          >{{ productData?.discount_percentage }}% OFF</span
                        >
                      </div>
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <span>Price after discount:</span>
                        <span class="fw-bold text-success"
                          >YER {{ productData?.discounted_price }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Action Buttons -->
                <div class="d-flex mt-4">
                  <button
                    class="btn btn-outline-success mx-2 d-flex align-items-center"
                    rippleEffect
                    (click)="
                      modelEditProduct(
                        modalEditProduct,
                        productData?.product_id,
                        productData?.name_en,
                        productData?.name_ar,
                        productData?.product_price,
                        productData?.has_discount,
                        productData?.discounted_price,
                        productData?.description_en,
                        productData?.description_ar,
                        productData?.refundable
                      )
                    "
                  >
                    <i data-feather="edit" class="mx-1"></i>
                    Edit Product
                  </button>
                  <button
                    class="btn btn-outline-danger d-flex align-items-center"
                    rippleEffect
                    (click)="
                      removeProduct(
                        productData?.product_id,
                        productData?.name_en
                      )
                    "
                  >
                    <i data-feather="trash-2" class="me-1"></i>
                    Delete Product
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Product Variants/SKUs Table -->
        <div class="card-bodyy p-2">
          <h4 class="mb-2">Product Variants</h4>
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead class="table-light">
                <tr>
                  <th>ID</th>
                  <th>SKU</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Color</th>
                  <th>Size</th>
                  <!-- Dynamically display additional feature names as table headers -->
                  <th
                    *ngFor="
                      let feature of productData?.product_additional_features
                    "
                  >
                    {{ feature.name_en }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let sku of productData?.product_skus">
                  <td>
                    <span class="fw-bold">{{ sku.id }}</span>
                  </td>
                  <td>{{ sku.sku }}</td>
                  <td>
                    <span class="text-primary">YER {{ sku.price }}</span>
                  </td>
                  <td>{{ sku.quantity }}</td>
                  <td>
                    <span *ngFor="let feature of sku.feature_values">
                      <span
                        *ngIf="feature.feature === 'Color'"
                        class="d-flex align-items-center"
                      >
                        <span
                          class="color-swatch me-1 rounded-circle"
                          style="width: 20px; height: 20px"
                          [ngStyle]="{ 'background-color': feature.value }"
                        ></span>
                        {{ feature.value_en || feature.value }}
                      </span>
                    </span>
                  </td>
                  <td>
                    <span *ngFor="let feature of sku.feature_values">
                      <span
                        *ngIf="feature.feature === 'Size'"
                        class="badge bg-light-secondary"
                      >
                        {{ feature.value_en || feature.value }}
                      </span>
                    </span>
                  </td>
                  <!-- Dynamically display additional feature values as table data cells -->
                  <td
                    *ngFor="
                      let additionalFeature of productData?.product_additional_features
                    "
                  >
                    {{ additionalFeature.value_en }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Product Description Section -->
        <div class="row p-3">
          <div class="col-md-6">
            <h4 class="mb-3 fw-bold">Product Description</h4>
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead class="table-light">
                  <tr>
                    <th style="width: 15%">Language</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="fw-bold">
                      <div class="d-flex align-items-center">
                        <i data-feather="globe" class="mx-2 text-primary"></i>
                        English
                      </div>
                    </td>
                    <td>
                      <p class="mb-2">
                        {{
                          productData?.description_en ||
                            "No description available in English."
                        }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fw-bold">
                      <div class="d-flex align-items-center">
                        <i data-feather="globe" class="mx-2 text-primary"></i>
                        Arabic
                      </div>
                    </td>
                    <td>
                      <p class="mb-2 text-end" dir="rtl">
                        {{
                          productData?.description_ar ||
                            "لا يوجد وصف متاح بالعربية."
                        }}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- Additional Product Details Section -->
          <div class="col-md-6">
            <div>
              <h4 class="mb-3 fw-bold">Return Policy</h4>
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead class="table-light">
                    <tr>
                      <th style="width: 30%">Policy</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="productData?.refundable">
                      <td class="fw-bold text-secondary">Refundable</td>
                      <td>14 Day Replacement</td>
                    </tr>
                    <tr *ngIf="!productData?.refundable">
                      <td class="fw-bold text-secondary">Refundable</td>
                      <td>Not Applicable</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

<!-- Edit Product Model  -->
<ng-template #modalEditProduct let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalCenterTitle">Edit Product</h5>
    <button
      id="clo"
      type="button"
      class="close"
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <form
      *ngIf="updateProductForm"
      [formGroup]="updateProductForm"
      novalidate
      autocomplete="off"
      id="fo"
    >
      <div class="row">
        <!-- Select Option -->
        <div class="form-group col-md-12">
          <label>Select Option:</label>
          <select
            title="Select Option"
            formControlName="selectedOption"
            class="form-control"
            (change)="onOptionChange($event)"
          >
            <option value="" disabled>Select an option</option>
            <option value="Data">Data</option>
            <option value="feature" (click)="getSubFeatures()">Feature</option>
            <option value="addition-feature">addition Feature</option>
            <option value="image">Image</option>
            <option value="colored_images">Colored Images</option>
          </select>
        </div>

        <!-- Data Forms -->
        <ng-container *ngIf="selectedOption === 'Data'">
          <!-- Product Name En Form -->
          <div class="form-group col-md-6">
            <label>Product Name (English):</label>
            <input
              formControlName="name_en"
              type="text"
              placeholder="Name (English)"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  updateProductFormSubmitted &&
                  updateProductForm.get('name_en')?.errors
              }"
            />
            <div
              *ngIf="
                updateProductForm.get('name_en')?.invalid &&
                updateProductFormSubmitted
              "
              class="invalid-feedback"
            >
              <div *ngIf="updateProductForm.get('name_en')?.errors?.required">
                Product Name (English) is required.
              </div>
            </div>
          </div>

          <!-- Product Name Ar Form -->
          <div class="form-group col-md-6">
            <label>Product Name (Arabic):</label>
            <input
              formControlName="name_ar"
              type="text"
              placeholder="Name (Arabic)"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  updateProductFormSubmitted &&
                  updateProductForm.get('name_ar')?.errors
              }"
            />
            <div
              *ngIf="
                updateProductForm.get('name_ar')?.invalid &&
                updateProductFormSubmitted
              "
              class="invalid-feedback"
            >
              <div *ngIf="updateProductForm.get('name_ar')?.errors?.required">
                Product Name (Arabic) is required.
              </div>
            </div>
          </div>

          <!-- Product Description En Form -->
          <div class="form-group col-md-6">
            <label>Product Description (English):</label>
            <textarea
              formControlName="description_en"
              placeholder="Description (English)"
              class="form-control"
              style="resize: none"
              [ngClass]="{
                'is-invalid':
                  updateProductFormSubmitted &&
                  updateProductForm.get('description_en')?.errors
              }"
            ></textarea>
            <div
              *ngIf="
                updateProductForm.get('description_en')?.invalid &&
                updateProductFormSubmitted
              "
              class="invalid-feedback"
            >
              <div
                *ngIf="
                  updateProductForm.get('description_en')?.errors?.required
                "
              >
                Product Description (English) is required.
              </div>
            </div>
          </div>

          <!-- Product Description Ar Form -->
          <div class="form-group col-md-6">
            <label>Product Description (Arabic):</label>
            <textarea
              formControlName="description_ar"
              placeholder="Description (Arabic)"
              class="form-control"
              style="resize: none"
              [ngClass]="{
                'is-invalid':
                  updateProductFormSubmitted &&
                  updateProductForm.get('description_ar')?.errors
              }"
            ></textarea>
            <div
              *ngIf="
                updateProductForm.get('description_ar')?.invalid &&
                updateProductFormSubmitted
              "
              class="invalid-feedback"
            >
              <div
                *ngIf="
                  updateProductForm.get('description_ar')?.errors?.required
                "
              >
                Product Description (Arabic) is required.
              </div>
            </div>
          </div>

          <!-- Price Form -->
          <div class="form-group col-md-6">
            <label>Price:</label>
            <input
              formControlName="product_price"
              type="number"
              placeholder="Price"
              class="form-control"
            />
          </div>

          <!-- Discount Form -->
          <div class="form-group col-md-6">
            <label>Discount:</label>
            <select
              title=" Select Discount"
              formControlName="discount"
              class="form-control"
              (change)="onDiscountChange($event)"
            >
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
          </div>

          <!-- Discounted Input -->
          <div class="form-group col-md-6" *ngIf="has_discount == 1">
            <label>Discounted Price:</label>
            <input
              formControlName="discounted_price"
              type="number"
              placeholder="Enter discounted price"
              class="form-control"
            />
          </div>

          <!-- Refundable -->
          <div class="form-group col-md-12">
            <label class="d-block mb-2">Refundability</label>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                [formControl]="refundableControl"
                [value]="1"
                (change)="toggleRefundable(1)"
                id="refundableYes"
              />
              <label class="form-check-label" for="refundableYes">
                Refundable
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                [formControl]="refundableControl"
                [value]="0"
                (change)="toggleRefundable(0)"
                id="refundableNo"
              />
              <label class="form-check-label" for="refundableNo">
                Non-Refundable
              </label>
            </div>

            <p *ngIf="refundableControl.value === 1" class="text-success mt-2">
              Product is Refundable
            </p>
            <p *ngIf="refundableControl.value === 0" class="text-danger mt-2">
              Product is Non-Refundable
            </p>
          </div>
        </ng-container>

        <!-- Image  -->
        <ng-container *ngIf="selectedOption === 'image'">
          <!-- Image Upload -->
          <div class="image-grid col-md-12">
            <ng-container
              *ngFor="let image of productData?.images; let i = index"
            >
              <div class="image-item position-relative">
                <!-- Trash Icon -->
                <button
                  title="Remove Image"
                  class="btn btn-outline-danger btn-sm position-absolute top-0 end-0 m-2"
                  (click)="removeImage(i)"
                  *ngIf="selectedOption === 'image'"
                  [disabled]="isLoading"
                >
                  <i data-feather="trash" class="mb-1"></i>
                </button>

                <!-- Image -->
                <img class="img-fluid" [src]="image" alt="product image" />
              </div>
            </ng-container>
          </div>

          <!-- File Upload -->
          <div class="mt-2 col-12">
            <label
              for="imageUpload"
              class="btn col-12 text-center font-medium-5 text-uppercase file-drop-zone"
            >
              {{
                fileNames?.length
                  ? fileNames.join(", ")
                  : "No file uploaded yet."
              }}
            </label>
            <input
              (change)="onFileUpload($event)"
              type="file"
              id="imageUpload"
              accept="image/*"
              multiple
              style="display: none"
              [ngClass]="{
                'is-invalid':
                  createProductFormSubmitted &&
                  createProductForm.get('images')?.errors
              }"
            />
            <!-- <div
                *ngIf="
                  createProductForm.get('images') &&
                  createProductFormSubmitted &&
                  createProductForm.get('images')?.errors
                "
                class="invalid-feedback"
              >
                <div *ngIf="createProductForm.get('images')?.errors?.required">
                  Image is required
                </div>
              </div> -->
          </div>
        </ng-container>

        <!-- colored_images  -->
        <ng-container *ngIf="selectedOption === 'colored_images'">
          <!-- Loop through each SKU, starting from the second one -->
          <div *ngFor="let sku of productData.product_skus.slice(1); let skuIndex = index">
            <div class="sku-section">
              <div *ngFor="let feature of sku.feature_values; let featureIndex = index">
                <ng-container *ngIf="feature.feature === 'Color'">
                  <!-- Color Box and Name -->
                  <div class="color-header">
                    <div
                      [style.backgroundColor]="feature.value_en"
                      class="color-box"
                    ></div>
                    <span class="color-name">{{ feature.value_en }}</span>
                  </div>
        
                  <!-- Colored Images Grid -->
                  <div class="colored-images-grid">
                    <ng-container *ngFor="let image of feature.colored_images; let imageIndex = index">
                      <!-- Display only the first 3 images -->
                      <ng-container *ngIf="imageIndex < 3">
                        <div class="colored-image-item">
                          <!-- Trash Icon to Remove Image -->
                          <button
                            title="Remove Image"
                            class="btn btn-outline-danger btn-sm remove-button"
                            (click)="removeColoredImage(skuIndex + 1, featureIndex, imageIndex)"
                            [disabled]="isLoading[skuIndex]?.[featureIndex]"
                          >
                            <i data-feather="trash" class="mb-1"></i>
                          </button>
        
                          <!-- Display Colored Image -->
                          <img
                            class="img-fluid colored-image"
                            [src]="image.image"
                            alt="colored image"
                          />
                        </div>
                      </ng-container>
                    </ng-container>
        
                    <!-- Show a "+X more" label if there are more than 3 images -->
                    <div
                      *ngIf="feature.colored_images.length > 3"
                      class="more-images-label"
                    >
                      +{{ feature.colored_images.length - 3 }} more
                    </div>
                  </div>
        
                  <!-- Dedicated Upload Button for Each Color -->
                  <div class="upload-section">
                    <label
                      [for]="'coloredImageUpload_' + skuIndex + '_' + featureIndex"
                      class="upload-button btn-outline-info"
                    >
                      Upload Images for {{ feature.value_en }}
                    </label>
                    <input
                      (change)="onColoredFileUpload($event, skuIndex + 1, featureIndex)"
                      type="file"
                      [id]="'coloredImageUpload_' + skuIndex + '_' + featureIndex"
                      accept="image/*"
                      multiple
                      style="display: none"
                    />
                  </div>
        
                  <!-- Update Colored Images Button -->
                  <button
                    type="button"
                    class="btn btn-outline-warning round mt-2"
                    (click)="updateColoredImages(skuIndex + 1, featureIndex)"
                    rippleEffect
                    [disabled]="isLoading[skuIndex]?.[featureIndex]"
                  >
                    <ng-container *ngIf="!isLoading[skuIndex]?.[featureIndex]">
                      Update Colored Images
                    </ng-container>
                    <ng-container *ngIf="isLoading[skuIndex]?.[featureIndex]">
                      <i class="fa fa-spinner fa-spin"></i>
                      Updating...
                    </ng-container>
                  </button>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </form>

    <!-- Feature  -->
    <ng-container *ngIf="selectedOption === 'feature'">
      <!-- Feature Form -->
      <div class="modal-body" tabindex="1" ngbAutofocus>
        <div *ngFor="let feature of features; let i = index">
          <div class="row">
            <!-- First Feature Selection -->
            <div class="form-group col-md-6">
              <label for="firstFeature{{ i }}"
                >Select First Feature {{ i + 1 }}</label
              >
              <select
                class="form-control"
                [(ngModel)]="feature.firstFeature"
                name="firstFeature{{ i }}"
                id="firstFeature{{ i }}"
              >
                <option value="" disabled>Select Feature</option>
                <ng-container *ngFor="let Feature of SubFeatures">
                  <ng-container *ngIf="!Feature.is_color">
                    <option
                      *ngFor="let last of Feature.values"
                      [value]="last.id"
                    >
                      {{ last.value }}
                    </option>
                  </ng-container>
                </ng-container>
              </select>
            </div>

            <!-- Second Feature Selection -->
            <div class="form-group col-md-6">
              <label for="secondFeature{{ i }}"
                >Select Second Feature {{ i + 1 }}</label
              >
              <select
                class="form-control"
                [(ngModel)]="feature.secondFeature"
                name="secondFeature{{ i }}"
                id="secondFeature{{ i }}"
              >
                <option value="" disabled>Select Feature</option>
                <ng-container *ngFor="let Feature of SubFeatures">
                  <ng-container *ngIf="Feature.is_color">
                    <option
                      *ngFor="let last of Feature.values"
                      [value]="last.id"
                      [ngStyle]="{ 'background-color': last.value }"
                    >
                      {{ last.value }}
                    </option>
                  </ng-container>
                </ng-container>
              </select>
            </div>

            <!-- Price Input -->
            <div class="form-group col-md-6">
              <label for="price{{ i }}">Price for Feature {{ i + 1 }}</label>
              <input
                class="form-control"
                type="number"
                [(ngModel)]="feature.price"
                name="price{{ i }}"
                placeholder="Price"
                id="price{{ i }}"
              />
            </div>

            <!-- Quantity Input -->
            <div class="form-group col-md-6">
              <label for="quantity{{ i }}"
                >Quantity for Feature {{ i + 1 }}</label
              >
              <input
                class="form-control"
                type="number"
                [(ngModel)]="feature.quantity"
                name="quantity{{ i }}"
                placeholder="Quantity"
                id="quantity{{ i }}"
              />
            </div>

            <!-- Delete Button -->
            <div class="col-md-12">
              <button
                *ngIf="i !== 0"
                class="btn btn-outline-danger text-nowrap px-1"
                (click)="deleteFeature(i)"
              >
                <i data-feather="x" class="mr-25"></i>
                <span>Delete</span>
              </button>
            </div>
          </div>
          <hr />
        </div>
      </div>

      <!-- Add Another Feature Button -->
      <div class="col-12">
        <button
          class="btn btn-icon btn-primary"
          type="button"
          (click)="addFeature()"
        >
          <i data-feather="plus" class="mb-1"></i>
          <span>Add another feature</span>
        </button>
      </div>
    </ng-container>

    <!-- Additional Features -->
    <ng-container *ngIf="selectedOption === 'addition-feature'">
      <div class="modal-body" tabindex="1" ngbAutofocus>
        <div *ngFor="let additional of additional_features; let i = index">
          <div class="row">
            <!-- Additional Features Name En  Input -->
            <div class="form-group col-md-6 w-100">
              <label for="Name En{{ i }}"
                >Additional Features Name En {{ i + 1 }}
              </label>
              <input
                class="form-control"
                type="text"
                [(ngModel)]="additional.name_en"
                name="name_en{{ i }}"
                placeholder="name_en"
                id="price{{ i }}"
                [cpOutputFormat]="'hex'"
                [cpPosition]="auto"
                [value]="additional.name_en"
              />
            </div>

            <!-- Additional Features Name Ar Input -->
            <div class="form-group col-md-6 w-100">
              <label for="Name En{{ i }}"
                >Additional Features Name Ar{{ i + 1 }}
              </label>
              <input
                class="form-control"
                type="text"
                [(ngModel)]="additional.name_ar"
                name="name_ar{{ i }}"
                placeholder="name_ar"
                id="price{{ i }}"
                [cpOutputFormat]="'hex'"
                [cpPosition]="auto"
                [value]="additional.name_ar"
              />
            </div>

            <!-- Additional Features Value En Input -->
            <div class="form-group col-md-6 w-100">
              <label for="Name En{{ i }}"
                >Additional Features Value En{{ i + 1 }}
              </label>
              <input
                class="form-control"
                type="text"
                [(ngModel)]="additional.value_en"
                name="value_en{{ i }}"
                placeholder="value_en"
                id="price{{ i }}"
                [cpOutputFormat]="'hex'"
                [cpPosition]="auto"
                [value]="additional.value_en"
              />
            </div>

            <!-- Additional Features Value Ar Input -->
            <div class="form-group col-md-6 w-100">
              <label for="Name En{{ i }}"
                >Additional Features Value Ar{{ i + 1 }}
              </label>
              <input
                class="form-control"
                type="text"
                [(ngModel)]="additional.value_ar"
                name="value_ar{{ i }}"
                placeholder="value_ar"
                id="price{{ i }}"
                [cpOutputFormat]="'hex'"
                [cpPosition]="auto"
                [value]="additional.value_ar"
              />
            </div>

            <!-- Delete Button -->
            <div class="col-md-12">
              <div class="form-group">
                <button
                  *ngIf="i != 0"
                  class="btn btn-outline-danger text-nowrap px-1"
                  (click)="deleteAdditionalFeature(i)"
                  rippleEffect
                >
                  <i data-feather="x" class="mr-25"></i>
                  <span>Delete</span>
                </button>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>

      <div class="col-12">
        <button
          class="btn btn-icon btn-primary"
          type="button"
          (click)="addAdditionalFeature()"
          rippleEffect
        >
          <i data-feather="plus" class="mb-1"></i>
          <span>Add Another Additional Feature</span>
        </button>
      </div>
    </ng-container>

    <!-- Button Submit  -->
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="modal.dismiss('Cancel')"
        (click)="cancelRequest()"
      >
        Cancel
      </button>

      <!-- Button for Save Data -->
      <button
        *ngIf="
          selectedOption === 'Data' || selectedOption === 'addition-feature'
        "
        type="button"
        class="btn btn-outline-success round ml-1"
        (click)="updateData()"
        rippleEffect
        [disabled]="isLoading"
      >
        <ng-container *ngIf="!isLoading"> Save Data </ng-container>
        <ng-container *ngIf="isLoading">
          <i class="fa fa-spinner fa-spin"></i>
          Saving...
        </ng-container>
      </button>

      <!-- Button for Save Features -->
      <button
        *ngIf="selectedOption === 'feature'"
        type="button"
        class="btn btn-outline-info round ml-1"
        (click)="updateFeature()"
        rippleEffect
        [disabled]="isLoading"
      >
        <ng-container *ngIf="!isLoading"> Save Features </ng-container>
        <ng-container *ngIf="isLoading">
          <i class="fa fa-spinner fa-spin"></i>
          Saving...
        </ng-container>
      </button>

      <!-- Button for Update Image -->
      <button
        *ngIf="selectedOption === 'image'"
        type="button"
        class="btn btn-outline-warning round ml-1"
        (click)="updateImage()"
        rippleEffect
        [disabled]="isLoading"
      >
        <ng-container *ngIf="!isLoading"> Update Image </ng-container>
        <ng-container *ngIf="isLoading">
          <i class="fa fa-spinner fa-spin"></i>
          Updating...
        </ng-container>
      </button>

      <!-- Button for Update Colored Images -->
      <button
        *ngIf="selectedOption === 'colored_images'"
        type="button"
        class="btn btn-outline-warning round ml-1"
        rippleEffect
        [disabled]="isLoading"
        (click)="modal.close('Accept click')"
      >
          Done       
      </button>
    </div>
  </div>
</ng-template>

<!-- Add Value Images -->
<ng-template #modalUpdateValueImages let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalCenterTitle">
      Feature Values Images
    </h5>
    <button
      id="clo"
      type="button"
      class="close"
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" tabindex="0" ngbAutofocus>
    <form
      *ngFor="let color of colorsFeatureValues; let i = index"
      id="fo"
      [formGroup]="updateValueImagesForm"
      (ngSubmit)="UpdateValueImagesMethod(i)"
    >
      <div class="form-group mt-2">
        <div class="d-flex align-items-center">
          <!-- Color Display -->
          <div class="d-flex align-items-center mr-2">
            <div
              [style.backgroundColor]="color?.color_name"
              style="
                width: 20px;
                height: 20px;
                border-radius: 4px;
                border: 1px solid #ddd;
              "
            ></div>
            <span class="ml-1">{{ color?.color_name }}</span>
          </div>

          <!-- File Input -->
          <div class="custom-file flex-grow-1">
            <input
              type="file"
              class="custom-file-input"
              [id]="'imageUpload_' + i"
              (change)="onFilechange($event, i)"
              accept="image/*"
              multiple
              #imageInput
              [disabled]="uploadCompleted[i]"
              aria-label="Choose file"
            />
            <label
              [for]="'imageUpload_' + i"
              class="custom-file-label"
              id="fileLabel"
            >
              {{ fileNames[i] || "Choose file" }}
            </label>
          </div>
        </div>

        <!-- Validation Message -->
        <div
          *ngIf="
            updateValueImagesFormSubmitted &&
            updateValueImagesForm.get('image')?.errors
          "
          class="invalid-feedback d-block"
        >
          <div *ngIf="updateValueImagesForm.get('image')?.errors?.['required']">
            Image is required
          </div>
        </div>

        <!-- Upload Button -->
        <div class="mt-3 text-center">
          <button
            class="btn"
            type="button"
            (click)="UpdateValueImagesMethod(i)"
            [ngClass]="
              loadingStates[i]
                ? 'btn-outline-warning text-warning'
                : 'btn-outline-success'
            "
            [disabled]="
              loadingStates[i] ||
              uploadCompleted[i] ||
              !selectedFiles[i]?.length
            "
          >
            {{
              loadingStates[i]
                ? "Uploading..."
                : uploadCompleted[i]
                ? "Uploaded"
                : "Upload"
            }}
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-outline-success round ml-1"
      type="button"
      rippleEffect
      (click)="modal.close('Accept click')"
      [disabled]="!isUploadComplete"
    >
      Done
    </button>
  </div>
</ng-template>

<!-- ///////////////////////////////loader//////////////////// -->
<div *ngIf="isLoading" id="loader-wrapper">
  <div id="loader"></div>
  <span *ngIf="isLoading" class="loader"></span>
  <div class="loader-section section-left"></div>
  <div class="loader-section section-right"></div>
</div>
