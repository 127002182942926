import { Product } from './../../../main/sample/modules/product';
import { ActivatedRoute } from '@angular/router';
import { ProductsService } from './../products.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import Swal from "sweetalert2";
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ecommerce-application' }
})
export class ProductDetailComponent implements OnInit {

  public swiperCoverflow: SwiperConfigInterface = {
    effect: 'coverflow',
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    coverflowEffect: {
      rotate: 60,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true
    },
    pagination: {
      el: '.swiper-pagination'
    }
  };


    private id:any;
    public product:Product;
    public productData:Product;

    constructor(private _productServices: ProductsService, private route: ActivatedRoute) {
    this.id = this.route.snapshot.paramMap.get('id');

   }

  ngOnInit(): void {

    // this.getProductById(this.id);
    this.getAllProduscts();
  }


  getProductById(id: any){
    this._productServices.GetProductById(id).subscribe({
      next: (res: any) => {
        this.product = res;
        console.log(this.product);
      },
      error: (er: any) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "NO PRODUCT found!",
          showConfirmButton: true,
        });
        console.log(er);
      },
    });
  }


  getAllProduscts(){
    this._productServices.GetAllProducts().subscribe({
      next: (res: any) => {
        this.productData = res
        console.log(this.productData);
      },
      error: (er: any) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "NO PRODUCT found!",
          showConfirmButton: true,
        });
        console.log(er);
      },
    })
  }

  


}
