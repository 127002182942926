<div class="content-wrapper container-xxl p-0 mt-3">
  <div class="content-body">
    <!-- User List -->
    <section class="users-list-wrapper">
      <!-- users filter start -->
      <div class="card">
        <h5 class="card-header font-weight-bold  display-4">
          Home Banners
        </h5>
        <div class="card-body"></div>
      </div>
      <!-- users filter end -->

      <div class="card">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="d-flex justify-content-between align-items-center m-1">
              <label class="d-flex align-items-center">
                Show
                <select class="form-control mx-25" [(ngModel)]="selectedOption">
                  <option value="9999999999999999999999">All</option>
                  <option value="2">2</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="250">250</option>
                  <option value="500">500</option>
                  <option value="500">1000</option>
                  <option value="500">2500</option>
                  <option value="500">5000</option>
                  <option value="500">10000</option>
                </select>
                entries</label
              >
            </div>
          </div>

          <div
            class="col-md-6 col-12 d-flex justify-content-start justify-content-md-end"
          >
            <div
              class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0"
            >
              <!-- <label class="d-flex align-items-center ml-1 ml-md-0">
                Search:
                <input
                  [(ngModel)]="searchValue"
                  name="searchValue"
                  type="search"
                  class="form-control ml-25"
                  (keyup)="filterUpdate($event)"
                  (search)="filterUpdate($event)"
                />
              </label> -->
              <button
                (click)="ModelAddNewBanners(modalAddNewBanners)"
                class="btn btn-primary ml-3"
                rippleEffect
              >
                Add New Banner
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="container mt-4">
        <div class="row">
          <div class="col-md-6 my-2" *ngFor="let banner of rows">
            <div class="card shadow">
              <img
                [src]="banner.image"
                class="card-img-top"
                alt="banner Image"
                (error)="banner.image = 'assets/images/default.jpg'"
              />
              <div class="card-body text-center d-flex justify-content-around">
                <button
                  class="btn btn-danger btn-sm d-flex align-items-center justify-content-center flex-grow-1 mx-1"
                  (click)="deleteBanner(banner.id)"
                >
                  <i data-feather="trash-2" class="me-1"></i> Delete
                </button>
                <button
                  class="btn btn-warning btn-sm d-flex align-items-center justify-content-center flex-grow-1 mx-1"
                  (click)="ModelChangeIndex(modelChangeIndex, banner.id)"
                >
                  <i data-feather="edit" class="me-1"></i> Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

<!-- Modal Add New Banners -->
<ng-template #modalAddNewBanners let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalCenterTitle">New Banner</h5>
    <button
      id="clo"
      type="button"
      class="close"
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <form
      id="fo"
      [formGroup]="AddNewBannerForm"
      (ngSubmit)="addNewBannerMethod()"
    >
      <!-- Image Upload Section -->
      <div class="form-group mt-2 col-12">
        <label
          for="imageUpload"
          class="btn col-12 text-center font-medium-5 text-uppercase file-drop-zone"
        >
          {{
            fileNames?.length ? fileNames.join(", ") : "No file uploaded yet."
          }}
        </label>
        <input
          (change)="onFileUpload($event)"
          aria-label="Upload a banner image"
          class="form-control"
          type="file"
          id="imageUpload"
          accept="image/*"
          multiple
          style="display: none"
          formControlName="image"
          [ngClass]="{
            'is-invalid':
              AddNewBannerFormSubmitted && AddNewBannerForm.get('image')?.errors
          }"
        />
        <div
          *ngIf="
            AddNewBannerForm.get('image') &&
            AddNewBannerFormSubmitted &&
            AddNewBannerForm.get('image')?.errors
          "
          class="invalid-feedback"
        >
          <div *ngIf="AddNewBannerForm.get('image')?.errors?.required">
            Image is required
          </div>
        </div>
      </div>

      <!-- Text Area Section -->
      <div class="form-group mt-3 col-12">
        <label for="title">Banner Text</label>
        <textarea
          id="title"
          class="form-control"
          rows="4"
          placeholder="Enter banner text here..."
          formControlName="title"
          [ngClass]="{
            'is-invalid':
              AddNewBannerFormSubmitted && AddNewBannerForm.get('title')?.errors
          }"
        ></textarea>
        <div
          *ngIf="
            AddNewBannerForm.get('title') &&
            AddNewBannerFormSubmitted &&
            AddNewBannerForm.get('title')?.errors
          "
          class="invalid-feedback"
        >
          <div *ngIf="AddNewBannerForm.get('title')?.errors?.required">
            Banner text is required
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-success round ml-1"
      (click)="addNewBannerMethod()"
      rippleEffect
      [disabled]="isLoading"
    >
      <span *ngIf="!isLoading">Add</span>
      <span *ngIf="isLoading">
        <i class="fa fa-spinner fa-spin"></i> Adding...
      </span>
    </button>

    <button
      type="button"
      class="btn btn-outline-danger round ml-1"
      (click)="modal.close('Accept click')"
      rippleEffect
    >
      Close
    </button>
  </div>
</ng-template>

<!-- Model Change Index -->
<ng-template #modelChangeIndex let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Change Index</h5>
    <button
      type="button"
      class="close"
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <form [formGroup]="changeIndexForm" (ngSubmit)="changeIndexMethod()">
      <!-- Number Input Section -->
      <div class="form-group mt-3 col-12">
        <label for="indexInput">Enter Index</label>
        <input
          id="indexInput"
          type="number"
          class="form-control"
          placeholder="Enter index..."
          formControlName="new_index"
          [min]="1"
          [ngClass]="{
            'is-invalid':
              changeIndexFormSubmitted && changeIndexForm.get('index')?.errors
          }"
        />
        <div
          *ngIf="
            changeIndexForm.get('index') &&
            changeIndexFormSubmitted &&
            changeIndexForm.get('index')?.errors
          "
          class="invalid-feedback"
        >
          <div *ngIf="changeIndexForm.get('index')?.errors?.required">
            Index is required
          </div>
          <div *ngIf="changeIndexForm.get('index')?.errors?.min">
            Index must be at least 1
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-success round ml-1"
      (click)="EditBanner()"
      rippleEffect
    >
      Add
    </button>
    <button
      type="button"
      class="btn btn-outline-danger round ml-1"
      (click)="modal.close('Accept click')"
      rippleEffect
    >
      Close
    </button>
  </div>
</ng-template>
