<div  *ngIf="!loader" class="content-wrapper container-xxl overflow-hidden p-0 mt-3">
  <div class="content-detached ">
    <div class="content-body">

       <!-- E-commerce Head Section Starts -->
       <section id="ecommerce-searchbar" class="ecommerce-searchbar my-3">
        <div class="card p-4 shadow-sm">
          <h5 class="card-title mb-3">Search Products</h5>
          <div class="row g-3">

            <!-- Search by Product Name -->
            <div class="col-md-4">
              <div class="input-group input-group-merge">
                <input
                  type="text"
                  class="form-control search-product py-3"
                  placeholder="Search by Product Name"
                  aria-label="Search by Product Name"
                  [(ngModel)]="searchText"
                />
                <div class="input-group-append">
                  <span class="input-group-text"><i data-feather="search" class="text-muted"></i></span>
                </div>
              </div>
            </div>
      
            <!-- Search by Category -->
            <div class="col-md-4">
              <div class="input-group input-group-merge">
                <input
                  type="text"
                  class="form-control search-product py-3"
                  placeholder="Search by Category"
                  aria-label="Search by Category"
                  [(ngModel)]="searchCategory"
                />
                <div class="input-group-append">
                  <span class="input-group-text"><i data-feather="list" class="text-muted"></i></span>
                </div>
              </div>
            </div>
      
            <!-- Search by Price Range -->
            <div class="col-md-4">
              <div class="input-group input-group-merge">
                <input
                  type="number"
                  class="form-control search-product py-3"
                  placeholder="Search by Maximum Price"
                  aria-label="Search by Price"
                  [(ngModel)]="searchPrice"
                />
                <div class="input-group-append">
                  <span class="input-group-text"><i data-feather="dollar-sign" class="text-muted"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      

        <!-- Product Display Section -->
        <section id="product-display" class="my-3">
          <div class="row">
            <div
              class="col-md-4 col-sm-6"
              *ngFor="let product of tempProducts "
            >
              <div class="card">
                <img
                  [src]="product.product_image"
                  class="card-img-top"
                  alt="{{ product.product_name }}"
                />
                <div class="card-body">
                  <p class="card-title lead text-info">
                    {{ getFirstFiveWords(product.product_name) }}
                  </p>
                  <p class="card-text text-warning">
                    <span class="text-white">Price :</span> {{ product.product_price }} YER
                  </p>
                  <p class="card-text" [ngClass]="{
                    'text-success': product.status === 'accepted',
                    'text-warning': product.status === 'new_create',
                    'text-danger': product.status === 'rejected'
                  }">
                    <span class="text-white">Status:</span> {{ product.status }}
                  </p>
        
                  <div class="d-flex justify-content-center">
                    <!-- Accept Button -->
                    <button
                      *ngIf="product.status === 'new_create' || product.status === 'rejected'"
                      class="btn btn-success w-50 mx-1"
                      (click)="acceptAction(product.product_id)"
                    >
                      Accept
                    </button>
                  
                    <!-- Reject Button -->
                    <button
                      *ngIf="product.status === 'new_create'"
                      class="btn btn-danger w-50 mx-1"
                      (click)="RejectAction(product.product_id, product.product_name)"
                    >
                      Reject
                    </button>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </section>
        

  
    </div>
  </div>
</div>

     <!-- ///////////////////////////////loader//////////////////// -->
<div *ngIf="loader" id="loader-wrapper">
  <div id="loader"></div>
  <span *ngIf="loaders" class="loader"></span>
  <div class="loader-section section-left"></div>
  <div class="loader-section section-right"></div>
</div>
