import { Component, OnInit } from "@angular/core";
import { HomeCategoriesService } from "../home-categories.service";
import { ColumnMode } from "@swimlane/ngx-datatable";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";

@Component({
  selector: "app-category-product-list",
  templateUrl: "./category-product-list.component.html",
  styleUrls: ["./category-product-list.component.scss"],
})
export class CategoryProductListComponent implements OnInit {
  public rows: any;
  public tempData: any;
  public selectedOption: number = 10;
  public searchValue = "";
  public ColumnMode = ColumnMode;
  public CreateNewCategoryForm: UntypedFormGroup;
  public CreateNewCategoryFormSubmitted = false;
  public modalReference: any;
  public isLoading = false;

  constructor(
    private _HomeCategoriesServ: HomeCategoriesService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder
  ) {
    this.CreateNewCategoryForm = this.formBuilder.group({
      category: ["", Validators.required], // Add this control
    });
  }

  ngOnInit(): void {
    // this.getAllCategory();
    this.AllCategoriesAdded();
  }

  // Get all Catogray
  // getAllCategory() {
  //   this._HomeCategoriesServ.getAllCategory().subscribe(
  //     (res: any) => {
  //       this.tempData = res;
  //       console.log(this.tempData);
  //     },
  //     (er: any) => {
  //       console.log(er);
  //     }
  //   );
  // }

  // Get all Catograies Added
  AllCategoriesAdded() {
    this._HomeCategoriesServ.getAllCategoriesAdded().subscribe(
      (res: any) => {
        this.rows = res;
        this.tempData = res;
        console.log(this.rows);
      },
      (er: any) => {
        console.log(er);
      }
    );
  }

  // Model Add Categories
  addCategoryModel(modalAddCategory: any) {
    setTimeout(() => {
      this.isLoading = false;
      // Reset Form only if modal is about to open
      this.CreateNewCategoryFormSubmitted = false;
      this.CreateNewCategoryForm.reset();

      // Open modal
      this.modalReference = this.modalService.open(modalAddCategory, {
        centered: true,
        backdrop: "static",
        keyboard: false,
      });
    }, 100);


    this._HomeCategoriesServ.getAllCategory().subscribe(
      (res: any) => {
        this.tempData = res;
        console.log(this.tempData);
      },
      (er: any) => {
        console.log(er);
      }
    );
  }

  // Add Home  Categories
  CreateNewCategoryMethod() {
    console.log("Button Clicked!"); // Debugging
    this.CreateNewCategoryFormSubmitted = true;
  
    if (this.CreateNewCategoryForm.invalid) {
      return;
    }
  
    this.isLoading = true;
    const formData = new FormData();
    formData.append("category_id", this.CreateNewCategoryForm.value.category);
  
    this._HomeCategoriesServ.addCategories(formData).subscribe({
      next: (response) => {
        console.log("Category created successfully", response);
        this.isLoading = false;
        this.modalReference.dismiss();
        this.CreateNewCategoryForm.reset({
          category: ''
        });
  
        // SweetAlert Success Message
        Swal.fire({
          title: "Success!",
          text: "Category created successfully.",
          icon: "success",
          confirmButtonText: "OK",
          timer: 3000,
        }).then(() => {
          this.AllCategoriesAdded();
        });
      },
      error: (error) => {
        console.error("Error creating category", error);
        this.isLoading = false;
  
        // Reset the form even on failure
        this.CreateNewCategoryForm.reset({
          category: ''
        });
  
        // SweetAlert Error Message
        Swal.fire({
          title: "Error!",
          text: "Failed to create category. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
      },
    });
  }
  

  deleteCategory(categoryId: number) {
    // Show confirmation dialog before deleting
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Proceed with deletion
        this._HomeCategoriesServ.deleteCategory(categoryId).subscribe({
          next: (response) => {
            console.log("Category deleted successfully", response);
  
            // Show success message
            Swal.fire({
              title: "Deleted!",
              text: "Category has been deleted.",
              icon: "success",
              confirmButtonText: "OK",
              timer: 3000,
            });
  
            // Refresh category list after deletion
            this.AllCategoriesAdded();
          },
          error: (error) => {
            console.error("Error deleting category", error);
  
            // Show error message
            Swal.fire({
              title: "Error!",
              text: "Failed to delete category. Please try again.",
              icon: "error",
              confirmButtonText: "OK",
            });
          },
        });
      }
    });
  }
  
  filterUpdate(e: any) {}
}
