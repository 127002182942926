import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShipmentservService } from '../shipmentserv.service';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';



@Component({
  selector: 'app-shipment-list',
  templateUrl: './shipment-list.component.html',
  styleUrls: ['./shipment-list.component.scss']
})
export class ShipmentListComponent implements OnInit {
  public isLoading =false ;
  public selectedStatus = 'Pending'; // Default active status
  public selectedOption = 10;
  public searchValue = "";
  public ColumnMode = ColumnMode;
  public rows = []; // Data source for the table
  public tempData = []; // Data source for the table

  @ViewChild(DatatableComponent) table: DatatableComponent;
    @ViewChild("tableRowDetails") tableRowDetails: any;


    // Shipment Status
    public shipmentStatus: any = [
      { name: "Pending", value: "Pending" },
      { name: "Preparing", value: "Preparing" },
      { name: "Previous", value: "Previous" },
      { name: "On The Way", value: "On The Way"},
    ];

    public IsVerified: any = [
      { name: "All", value: "" },
      { name: "True", value: "true" },
      { name: "False", value: "false" },
    ];
    public IsDeleted: any = [
      { name: "All", value: "" },
      { name: "True", value: "true" },
      { name: "False", value: "false" },
    ];
    public IsSuspended: any = [
      { name: "All", value: "" },
      { name: "True", value: "true" },
      { name: "false", value: "false" },
    ];
  
  
    public selectedSuspend = [];
    public selectedVerified = [];
    public selectedDeleted = [];

  constructor(private _shipmentsServices :ShipmentservService) { }

  ngOnInit(): void {
    this.onStatusChange(this.selectedStatus); // Load default status data
    this.getAllPendingShip()
  }

// Change status 
  onStatusChange(status: string): void {
    this.selectedStatus = status; // Update the selected status
    this.isLoading = true;

    switch (status) {
      case "Pending":
        this.getAllPendingShip();   ///pending
        break;
      case "Preparing":
        this.getAllPreparedShip();
        break;
      case "Previous":
        this.getAllPreviousShip();
        break;
      case "On The Way":
        this.getAllOnWayShip();
        break;
      default:
        this.isLoading = false;
        console.log("Unknown status:", status);
    }
  }

//Pending Shipments
  getAllPendingShip(){
    this._shipmentsServices.allPendingShipment().subscribe(
      (res: any) => {
        this.isLoading = false;
        this.rows = res.data;
        this.tempData = res;  
        console.log(this.rows);
        
      },
      (er: any) => {
        console.log(er);
      }
    );
  }

  // Preparing Shipments 
  getAllPreparedShip(){
    this._shipmentsServices.allPreparingShipment().subscribe(
      (res: any) => {
        this.isLoading = false;
        this.rows = res.data;
        this.tempData = res;  
      },
      (er: any) => {
        console.log(er);
      }
    );
  }

  // Pervious Shipments 
  getAllPreviousShip(){
    this._shipmentsServices.allPerviousShipment().subscribe(
      (res: any) => {
        this.isLoading = false;
        this.rows = res.data;
        this.tempData = res;  
      },
      (er: any) => {
        console.log(er);
      }
    );
  }

  // On The Way Shipments 
  getAllOnWayShip(){
    this._shipmentsServices.allOnTheWayShipment().subscribe(
      (res: any) => {
        this.isLoading = false;
        this.rows = res.data;
        this.tempData = res;  
      },
      (er: any) => {
        console.log(er);
      }
    );
  }





  filterUpdate(event: any) {
    // Reset ng-select filters
    this.selectedVerified = this.IsVerified[0];
    this.selectedSuspend = this.IsSuspended[0];
    this.selectedDeleted = this.IsDeleted[0];

    // Get the search input value and convert it to lowercase
    const val = event.target.value.toLowerCase();

    // Ensure tempData exists before filtering
    if (!this.tempData) {
        return;
    }

    // Filter based on search value
    this.rows = this.tempData.filter((d: any) => {
        return (
            (d.store_name && d.store_name.toLowerCase().includes(val)) ||
            (d.client_name && d.client_name.toLowerCase().includes(val)) ||
            (d.client_phone && d.client_phone.toLowerCase().includes(val)) ||
            (d.store_phone && d.store_phone.toLowerCase().includes(val)) ||
            val === ''
        );
    });

    // Reset pagination to the first page after filtering
    if (this.table) {
        this.table.offset = 0;
    }
}

}
