import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ProductsService } from '../products.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ecommerce-item',
  templateUrl: './ecommerce-item.component.html',
  styleUrls: ['./ecommerce-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ecommerce-application' }
})
export class EcommerceItemComponent implements OnInit {

  // Input Decorator
  @Input() product;
  @Input() isWishlistOpen = false;

  // Public
  public isInCart = false;
  public products: any[] = []; // To hold the refreshed product list

  ngOnInit(): void {
    this.loadProducts(); // Load products on initialization
  }


  loadProducts(): void {
    this._ProductsService.GetAllProducts().subscribe((data: any) => {
      this.products = data;
    });
  }
  /**
   * Constructor
   *
   * @param {ProductsService} _ProductsService
   */
  constructor(private _ProductsService: ProductsService) {}

  // Public Methods
  // -----------------------------------------------------------------------------------------------------
  
  /**
   * Remove Product
   *
   * @param productId
   */
  removeProduct(productId: number): void {
    console.log('Product removed successfully' , productId);

    this._ProductsService.DeleteProductById(productId).subscribe(
      () => {
        console.log('Product removed successfully' , productId);
        Swal.fire('Success', 'Product removed successfully!', 'success');
        this.loadProducts();
      },
      (error) => {
        console.error('Error removing product:', error);
      }
    );
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------
  // ngOnInit(): void {}
}
