import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationServiceService {
  private lastUrl: string = '/view-all-products';
 
  constructor(private router: Router) { }
   
  setLastUrl(url: string) {
    this.lastUrl = url;
  }

  getLastUrl(): string {
    return this.lastUrl;
  }

}
