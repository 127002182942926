// import { ThirdSubCategory } from './../../../main/sample/modules/sub-details-interface';
import { SubDetailsServiceService } from '../sub-details-service.service';
import { SubcategoryserviceService } from 'app/components/subcategory/subcategoryservice.service';

import { AdminSelectCategory } from './../../../main/sample/modules/admin-select-category';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { repeaterAnimation } from 'app/components/subcategory/subcategory-list/form-repeater.animation';
import Swal from 'sweetalert2';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {UntypedFormGroup,UntypedFormBuilder,Validators,} from "@angular/forms";
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { SharedService } from 'app/components/Shared Servece/shared.service';


@Component({
  selector: 'app-sub-details-list',
  templateUrl: './sub-details-list.component.html',
  styleUrls: ['./sub-details-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [repeaterAnimation]
})

export class SubDetailsListComponent implements OnInit {

  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild("tableRowDetails") tableRowDetails: any;
  private tempData: any;
  public sidebarToggleRef = false;
  public category: AdminSelectCategory;
  public rows: any;
  public selectedOption = 10;
  public ColumnMode = ColumnMode;
  public temp: any;
  public searchValue = "";
  public modalReference:any;
  public modalReference2:any;  
  public modalReference3:any;  
  public modalReference4:any;  
  public subcategory_id:number=0;
  public UpdatedThirdSubCategory_id:any;
  public UpdatedSubCategory_id:any;
  public UpdatedCategory_title:any;
  public ReactiveOrderStatusForm: UntypedFormGroup;
  public ReactiveOrderStatusFormSubmitted = false;
  public loading = false;
  public loadAddSub = true;


// For Feature 
public items = [{ itemName_en: '', itemName_ar: '' }];
public item = { itemName_en: '', itemName_ar: '' };

  // Upload Photo Variable
  public file:File;
  public fileName=''



  // Received Category Id 
  public SubCategoryId: number | null = null;
  public secondSubData ;

  constructor(
    // private _Subcategoryserv: SubcategoryserviceService,
    private _SubDetailsServe : SubDetailsServiceService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private sharedService: SharedService
  ) {


    this.ReactiveOrderStatusForm = this.formBuilder.group({
      parent_id: ["", [Validators.required]],
      name_en: ['', [Validators.required,Validators.minLength(2),Validators.maxLength(50), Validators.pattern(/^[A-Za-z\s]+$/)]],
      name_ar: ['', [Validators.required,Validators.minLength(2),Validators.maxLength(50),Validators.pattern(/^[\u0600-\u06FF\s]+$/)]],
      image: ["", []],
    });
   }


  ngOnInit(): void {
    this.getThirdSubCategory();  

    //  // Received SubCategory Id
    //  this.sharedService.currentId$.subscribe((id) => {
    //   this.SubCategoryId = id;
    //   if (this.SubCategoryId) {
    //     this.getSecondSubCategory(this.SubCategoryId)
    //   }
    //   console.log(this.SubCategoryId);
    //   this.getSecondSubCategory(this.SubCategoryId)
    // });
}

  // getSecondSubCategory(SubCategoryId){
  //     this._SubDetailsServe.getSubCategory(SubCategoryId).subscribe(
  //       (res) => {
  //         console.log('second Subcategory Data:', res);
  //         this.secondSubData =res
  //         console.log(this.secondSubData);
          
  //       },
  //       (error) => {
  //         console.error('Error fetching subcategory:', error);
  //       }
  //     );
  // }



get ReactiveOSForm() {
  return this.ReactiveOrderStatusForm.controls;
}

  // Feature 
  deleteItem(id) {
    for (let i = 0; i < this.items.length; i++) {
      if (this.items.indexOf(this.items[i]) === id) {
        this.items.splice(i, 1);
        break;
      }
    }
  }


  addItem() {
    this.items.push({
      itemName_en: '',
      itemName_ar: ''
    });
  }


  




// Get Second And Third Sub 
  getThirdSubCategory() {
    this._SubDetailsServe.getThirdSubCategory().subscribe(
      (res: any) => {
        this.rows = res;
        // this.tempData = res;
        // console.log(this.rows);

      },
      (er: any) => {
        console.log(er);
      }
    );
  }


  // Upload Photo 
  onFilechange(event: any) {
    this.file = event.target.files[0];
    this.fileName = this.file.name;
    console.log(this.fileName);
    
  }

  filterUpdate(event) {
    // Reset ng-select on search


    const val = event.target.value.toLowerCase();

    // Filter Our Data
    const temp = this.tempData.filter(function (d) {
      return (
        d.name.toLowerCase().indexOf(val) !== -1 ||
        !val
      );
    });

    // Update The Rows
    this.rows = temp;
    // Whenever The Filter Changes, Always Go Back To The First Page
    this.table.offset = 0;
  }

  filterRows(verifiedFilter, suspendFilter, deletedFilter): any[] {
    // Reset search on select change
    this.searchValue = "";

    verifiedFilter = verifiedFilter.toLowerCase();
    suspendFilter = suspendFilter.toLowerCase();
    deletedFilter = deletedFilter.toLowerCase();

    return this.tempData.filter((row) => {
      const isPartialNameMatch =
        row.verified.toString().toLowerCase().indexOf(verifiedFilter) !== -1 ||
        !verifiedFilter;
      const isPartialGenderMatch =
        `${row.banned}`.toLowerCase().indexOf(suspendFilter) !== -1 ||
        !suspendFilter;
      const isPartialStatusMatch =
        row.isDeleted.toString().toLowerCase().indexOf(deletedFilter) !== -1 ||
        !deletedFilter;
      return isPartialNameMatch && isPartialGenderMatch && isPartialStatusMatch;
    });
  }


//////////////////////////////////
  modalOpenVC(modalVC) {
    
    this.loadAddSub=false;
    
    this._SubDetailsServe.getAllCategory().subscribe(
      (res: any) => {
        this.category = res;
        console.log(this.category);        
        this.ReactiveOrderStatusFormSubmitted = false;
        this.ReactiveOrderStatusForm.reset();
        this.modalReference = this.modalService.open(modalVC, {
          backdrop: false,
          centered: true,
        });
        this.loadAddSub=true;
        
      },
      (er: any) => {
        this.loadAddSub=true;
        Swal.fire({
          position: "center",
          icon: "error",
          title: "An Error Occurred While Get Category ",
          showConfirmButton: false,
          timer: 1500,
        });
      
        
      }
    );







  }



  // ReactiveOSFormOnSubmit() {


  //   this.ReactiveOrderStatusFormSubmitted = true;

  //   if (this.ReactiveOrderStatusForm.invalid) {
  //     return;
  //   }

  //   if (this.file) {
  //     var formData = new FormData();
  //     formData.append("image",this.file);
  //     formData.append("name_en",this.ReactiveOSForm.name_en.value);
  //     formData.append("name_ar",this.ReactiveOSForm.name_ar.value);
  //     formData.append("parent_id",this.ReactiveOSForm.parent_id.value);
  //   }
    

  //   this._Subcategoryserv.addSubCategory(formData).subscribe(
  //       (re: any) => {
  //         if(re.message == "category saved successfully"){
  //           Swal.fire({
  //             position: "center",
  //             icon: "success",
  //             title: "Sub Category Has been Added Successfully ",
  //             showConfirmButton: false,
  //             timer: 1500,
  //           });
  //         }
  //         this.getAllSubCategory();
  //         this.modalReference2.close();
  //       },

  //       (er: any) => {
  //         Swal.fire({
  //           position: "center",
  //           icon: "error",
  //           title: "An Error Occurred While Add Sub Category !",
  //           showConfirmButton: false,
  //           timer: 1500,
  //         });
  //       }
  //     );
  // }

  
/////////////////////////////////////////





  
  
  // Get all SubCategoryy
  getAllSubCategory() {
    this._SubDetailsServe.getAllSubCategory().subscribe(
      (res: any) => {
        this.rows = res;
        this.tempData = res;

      },
      (er: any) => {
        console.log(er);
      }
    );
  }

  
  // <!-- Update Third SubCategory Model-->
   modalOpenUpdateSubC(modalSubC , parent_id , UpdatedCategory_name_ar ,UpdatedCategory_name_en ) {
 
    this.UpdatedThirdSubCategory_id=parent_id;      
    console.log(this.UpdatedThirdSubCategory_id);
    

    this._SubDetailsServe.getThirdSubCategory().subscribe(
      (res: any) => {
        this.category = res;
        
        this.ReactiveOrderStatusFormSubmitted = false;
        this.ReactiveOrderStatusForm.reset();
        this.modalReference3 = this.modalService.open(modalSubC, {
          backdrop: false,
          centered: true,
        });
        
      },
      (er: any) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "An Error Occurred While Get Category ",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    );

  }
   
  // <!-- Update Sub Category -->
  ReactiveUpdateSubCatFormOnSubmit() {
    this.ReactiveOrderStatusFormSubmitted = true;
  
    if (this.ReactiveOSForm.invalid) {
      return;
    }

var formData = new FormData();
formData.append("name_en", this.ReactiveOSForm.name_en.value);
formData.append("name_ar", this.ReactiveOSForm.name_ar.value);
formData.append("parent_id", this.ReactiveOSForm.parent_id.value);
formData.append("_method", "PUT");


    this._SubDetailsServe
      .updateThirdSubCategory(formData , this.UpdatedThirdSubCategory_id).subscribe(
        (re: any) => {
          this.getThirdSubCategory();
          this.modalReference3.close();
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Sub Category Has been Updated Successfully ",
            showConfirmButton: false,
            timer: 1500,
          });
        },

        (er: any) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "An Error Occurred While Updated Sub Category !",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      );
  }

  // Add Feature Model 
  modalAddFeature(modalCreateFeature: any, id: number) {
    this.items = [{ itemName_en: '', itemName_ar: '' }];
    this.subcategory_id = id;
  
    this.modalReference4 = this.modalService.open(modalCreateFeature, {
      backdrop: false,
      centered: true,
    });
    console.log("Modal opened with subcategory ID:", id);
  }
  
  

 // Add Feature Method
  AddFeatureMethod() {
    this.loading = true;
  // this.Category_Id= id    

    const features = this.items.map(item => {
      return {
        name_en: item.itemName_en,
        name_ar: item.itemName_ar
      };
    });
    const data = {
      features: features,
      category_id: this.subcategory_id
    };
    
    console.log(features);
    console.log(this.subcategory_id);
    
  
    this._SubDetailsServe.addSubFeature(data)
      .subscribe(
        (re: any) => {
          console.log(re.message)
          console.log(re)
          this.loading = false;

          Swal.fire({
            position: "center",
            icon: "success",
            title: "Features Have Been Added Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          this.getAllSubCategory();
          this.modalReference4.close(); 
        },
        (er: any) => {
          this.loading = false;
          Swal.fire({
            position: "center",
            icon: "error",
            title: "An Error Occurred While Adding Features!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      );
  }

}
