import { Injectable ,  } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';



@Injectable({providedIn: 'root'})

export class ShipmentservService  implements Resolve<any> {
  public rows: any;
  public onUserListChanged: BehaviorSubject<any>;
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */

  constructor(private _httpClient: HttpClient) { 
     // Set the defaults
     this.onUserListChanged = new BehaviorSubject({});
  }


  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.allPendingShipment()]).then(() => {
        resolve();
      }, reject);
    });
  }


  // pending_shipments
  allPendingShipment(){
    return this._httpClient.get(`${environment.apiUrl}admin/pending_shipments`)
  }

  // preparing_shipments
  allPreparingShipment(){
    return this._httpClient.get(`${environment.apiUrl}admin/preparing_shipments`)
  }
  
  // privious_shipments
  allPerviousShipment(){
    return this._httpClient.get(`${environment.apiUrl}admin/privious_shipments`)
  }
  
  // on_way_shipments
  allOnTheWayShipment(){
    return this._httpClient.get(`${environment.apiUrl}admin/view_on_way_shipments`)
  }

  // Shipments Details 
  ShipmentDetails(id:any){
    return this._httpClient.get(`${environment.apiUrl}admin/view_shipment_details/${id}`)
  }

  // change Status 
  ChangeStatus(data:any,id:number){
    return this._httpClient.post(`${environment.apiUrl}admin/change-shipment-statue/${id}`,data);
  } 









}
